import React from "react";
import classnames from "classnames";
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  Navbar,
  Nav,
  Container
} from "reactstrap";
import i18n from "../../assets/lib/i18n";
import {StrDef,ResarailDisplay} from '../../assets/lib/utils';
import Icon from '../Icons/Icon.jsx';
import { BrowserView, isMobile } from "react-device-detect";

const MAX_SEARCH_RESULT = isMobile?40:50;

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      color: "navbar-transparent",
      searchResult:[],
      role:[],
      currentRole:sessionStorage.getItem('currentRole'),
      region:sessionStorage.getItem('region'),
      searchContext:'ocode',
    };

    var tmp = sessionStorage.getItem('role');
    if( StrDef(tmp) ) {
      tmp = JSON.parse(tmp);
      this.state.role = tmp;
    }

    this.ChangePwd = this.ChangePwd.bind(this);
    this.Logout = this.Logout.bind(this);
    this.About = this.About.bind(this);

    this.Settings = this.Settings.bind(this);
    this.Account = this.Account.bind(this);
    this.SearchKeyDown = this.SearchKeyDown.bind(this);
    this.SearchClear = this.SearchClear.bind(this);
    this.GoBack = this.GoBack.bind(this);
    this.Event = this.Event.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateColor);
    window.addEventListener("logout", this.Logout);
    window.addEventListener('event', this.Event, false);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateColor);
    window.removeEventListener("logout", this.Logout);
    window.removeEventListener('event', this.Event, false);
  }

  componentDidUpdate(e) {
    if (
      window.outerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
  }
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.collapseOpen) {
      this.setState({
        color: "bg-white",
        collapseOpen: true,
      });
    } else {
      this.setState({
        color: "navbar-transparent",
        collapseOpen: false,
      });
    }
  };
  // this function opens and closes the sidebar on small devices
  toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
  };
  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  toggleCollapse = () => {
    let newState = {
      collapseOpen: !this.state.collapseOpen
    };
    if (!this.state.collapseOpen) {
      newState["color"] = "bg-white";
    } else {
      newState["color"] = "navbar-transparent";
    }
    this.setState(newState);
  };

  Logout(e) {
    if( StrDef(e) && StrDef(e.preventDefault) ) e.preventDefault();
    sessionStorage.clear();
    this.props.history.push('/home');
  }

  About(e) {
    if( StrDef(e) && StrDef(e.preventDefault) ) e.preventDefault();
    this.props.history.push('/followme/about');
  }

  Settings(e) {
    if( StrDef(e) && StrDef(e.preventDefault) ) e.preventDefault();
    this.props.history.push('/'+this.gender+'/settings');
  }

  Account(e) {
    if( StrDef(e) && StrDef(e.preventDefault) ) e.preventDefault();

    var user = sessionStorage.getItem('user');
    if( StrDef(user) ) user = JSON.parse(user);

    this.props.history.push('/'+this.gender+'/account/'+user.userId);
  }

  SearchKeyDown(k) {
    if( k.target.value == ''
        || (k.target.value.length==1 && k.keyCode) ) {
      this.setState({searchResult:[]});
    }
    if( k.keyCode == 13 ) {
      var search = k.target.value;
      if( search.length > 2 ) {
        this.setState({searching:true});
        if( this.state.searchContext=='pdv' ) { // on recherche un point de vente
          if( this.state.currentRole == 'REF' ) {
            search += ' | '+this.state.region;
          }
          window.API.pos_get(this.SearchResult.bind(this,search),search);
        }
        else if( this.state.searchContext=='fichee' ) { // on recherche une fiche E
          if( this.state.currentRole == 'REF' ) {
            search += ' | '+this.state.region;
          }
          window.API.fichee_get(this.SearchResult.bind(this,search),search);
        }
        else {                                  // on recherche un ocode
          window.API.ocode_get(this.SearchResult.bind(this,search),search);
        }
      }
      if( search == '' ) {
        this.setState({searchResult:[]});
      }
    }
  }

  SearchResult(search,data) {

    if( StrDef(data.error) ) {
      var word = search.split(' ');
      var tooShort = false;
      for(let i=0;i<word.length;i++) {
        if( word[i].length < 3 ) {
          tooShort = true;
          break;
        }
      }
      if( tooShort ) {
        window.notify(i18n.t('navbar.searchTooShort'),'danger');
      }
      this.setState({searchResult:[],searchNoResult:true},()=>{
        setTimeout(()=>{
          this.setState({searching:false,searchNoResult:false});
        },2000);
      });
      return;
    }

    if( this.state.searchContext!='ocode' ) {
      var tmp = data.slice(0,MAX_SEARCH_RESULT), tab=[];
      for(let i=0;i<tmp.length;i++) {
        if( StrDef(tmp[i].status) && parseInt(tmp[i].status) == 9 ) {
          // c'est un truc supprimé
        }
        else {
          tab.push(tmp[i]);
        }
      }
      if( tab.length == 0 ) {
        this.setState({searchResult:[],searchNoResult:true},()=>{
          setTimeout(()=>{
            this.setState({searching:false,searchNoResult:false});
          },2000);
        });
      }
      this.setState({searchResult:tab,searching:false});
    }
    else {
      var obj = null, found = [], res = [], ocode = '';
      for(let i=0;i<data.length&&i<MAX_SEARCH_RESULT;i++) {
        ocode = data[i];
        obj = data[i].ocode_data;
        found = [];
        for(var k in obj) {
          if( obj[k].toLowerCase().indexOf(search.toLowerCase())!= -1 ) {
            found.push(k);
          }
        }
        ocode.found = found.slice(0,2);
        res.push(ocode);
      }
      this.searchText = search;
      if( res.length == 0 ) {
        this.setState({searchResult:res,searchNoResult:true},()=>{
          setTimeout(()=>{
            this.setState({searching:false,searchNoResult:false});
          },2000);
        });
      }
      else {
        this.setState({searchResult:res,searching:false});
      }
    }
  }

  SearchClear() {
    document.querySelector('#searchInput').value='';
    this.setState({searchResult:[]});
  }

  SearchClick(ocodeId) {
    var event = new CustomEvent('saveState', {detail:'sidebarDetail'});
    window.dispatchEvent(event);
    var event = new CustomEvent('saveState', {detail:'stock'});
    window.dispatchEvent(event);

    this.SearchClear();
    if( this.state.searchContext=='pdv' ) {
      this.props.history.push('/followme/pdv/'+ocodeId);
    }
    else if( this.state.searchContext=='fichee' ) {
      this.props.history.push('/followme/fichee/'+ocodeId);
    }
    else {
      this.props.history.push('/followme/ocode/'+ocodeId);
    }

  }

  GetStatus(data) {
    var className = i18n.t('statusColor.'+data.ocode_data.status);

    var res = (
      <div style={{display:'flex',flexDirection:'row'}}>
        { StrDef(className) &&
          <div className={className} style={{width:20,height:20,borderRadius:10,marginLeft:'10px',marginRight:'10px'}}></div>
        }
        {i18n.t('status.'+data.ocode_data.status)}
      </div>
    );

    return res;
  }

  ChangeProfile(profile) {
    sessionStorage.setItem('currentRole',profile);
    if( profile == 'REF' ) {
      this.props.history.push('/followme/stock/');
      document.location.reload();
    }
    else {
      document.location.reload();
    }

    // this.props.history.push('/followme/stock/');
    // var event = new CustomEvent('changeProfile', { 'detail': profile });
    // window.dispatchEvent(event);
  }

  GoBack() {
    this.props.history.goBack();
  }

  Event(data) {
    data = data.detail;
    switch (data.type) {
      case 'mount':
        if( data.screen == 'pdv' ) this.setState({searchContext:'pdv'});
        if( data.screen == 'fichee' ) this.setState({searchContext:'fichee'});
        break;
      case 'unmount':
        if( data.screen == 'pdv' ) this.setState({searchContext:'ocode'});
        if( data.screen == 'fichee' ) this.setState({searchContext:'ocode'});
        break;
      default:

    };
  }

  ChangePwd(e) {
    if( StrDef(e) && StrDef(e.preventDefault) ) e.preventDefault();
    this.props.history.push('/followme/settings/pwd');
  }

  render() {
    var username = 'Jean Laurent Vacheron';
    if( StrDef(this.entityUser) && StrDef(this.entityUser.company) ) username = this.entityUser.company;

    var searchPlaceholder = "Rechercher";
    if( this.state.searchContext=='pdv' ) searchPlaceholder = "Rechercher un point de vente";
    if( this.state.searchContext=='fichee' ) searchPlaceholder = "Rechercher une fiche E";

    return (
      <>
        <Navbar
          className={classnames("navbar-absolute fixed-top", this.state.color)}
          expand="lg"
        >
          <Container fluid>
            <div className="navbar-wrapper">
              <div
                className={classnames("navbar-toggle", {
                  toggled: this.state.sidebarOpen
                })}
              >
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={this.toggleSidebar}
                >
                  <span className="navbar-toggler-bar bar1" />
                  <span className="navbar-toggler-bar bar2" />
                  <span className="navbar-toggler-bar bar3" />
                </button>
              </div>
              { true &&
              <div className="navbar-minimize">

                <div style={{display:'flex',width:isMobile?'calc(100vw - 60px)':'100%',flexDirection:'row',justifyContent:'space-between'}}>
                  <div style={{display:'flex'}}>
                    <Icon name='back' size={32} onClick={this.GoBack}/>
                  </div>

                  <div style={{display:'flex',alignItems:'flex-end'}}>
                    <div onClick={()=>{this._searchInput.focus()}}>
                      <Icon name='search' color={'#666'} size={33}/>
                    </div>
                    <Input
                      ref={(c)=>this._searchInput=c}
                      id="searchInput"
                      defaultValue=""
                      placeholder={searchPlaceholder}
                      type="text"
                      style={{backgroundColor:'transparent',width:'30vw',border:'none'}}
                      onKeyDown={this.SearchKeyDown}
                      />
                  </div>
                </div>
                { this.state.searchResult.length > 0 &&
                  <>
                    <div className='searchResultBackdrop' onClick={this.SearchClear}></div>
                    <div className='searchResultBackdrop2' onClick={this.SearchClear}></div>
                  </>
                }
                { this.state.searching &&
                  <div className='searchResult'>
                    <div className='row' style={{cursor:'default',padding:'20px',paddingLeft:'40px'}}>
                      {i18n.t('navbar.searching')}
                    </div>
                  </div>
                }
                { this.state.searchNoResult &&
                  <div className='searchResult'>
                    <div className='row' style={{cursor:'default',padding:'20px',paddingLeft:'40px'}}>
                      {i18n.t('navbar.searchNoResult')}
                    </div>
                  </div>
                }
                { this.state.searchResult.length > 0 &&
                  <div className='searchResult'>
                    { this.state.searchResult.map((data,i)=>{
                        if( StrDef(data.ocode_id) ) {     // on cherche un ocode
                          return(
                            <div key={'sr'+i} className='row' onClick={this.SearchClick.bind(this,data.ocode_id)}>
                              <div style={{display:'flex',flex:1,justifyContent:'center',alignItems:'center'}}>
                                <img src={i18n.t('image.'+data.ocode_data.gender)} className='itemSearchIcon'/>
                              </div>
                              <div style={{display:'flex',flex:2,flexDirection:'column'}}>
                                <p>Type : <b style={{textTransform:'capitalize'}}>{i18n.t('gender.'+data.ocode_data.gender)}</b></p>
                                {data.found.map((d,i)=>{
                                    console.log('found = ',  d, data.ocode_data[d] );
                                    var field = data.ocode_data[d].toUpperCase();
                                    var pos = field.indexOf(this.searchText.toUpperCase()) + this.searchText.length;
                                    var endOfField = data.ocode_data[d].substring(pos+1,data.ocode_data[d].length);
                                    var serachOfField = data.ocode_data[d].substring(pos-this.searchText.length,pos);
                                    var beginOfField = data.ocode_data[d].substring(0,pos-this.searchText.length);
                                    return(
                                      <p key={'f'+i}>{i18n.t('field.'+d)} : {beginOfField.length>6?'...':beginOfField}<b>{serachOfField}</b>{endOfField.length>13?'...':endOfField}</p>
                                    );
                                  })
                                }
                                <div style={{display:'flex',flexDirection:'row'}}>Statut :{this.GetStatus(data)}</div>
                              </div>
                            </div>
                          );
                        }
                        if( StrDef(data.pos_id) ) {       // on cherche un PDV
                          return(
                            <div key={'sr'+i} className='row' onClick={this.SearchClick.bind(this,data.pos_id)}>
                              <div style={{display:'flex',flex:1,justifyContent:'center',alignItems:'center'}}>
                                <Icon name='pos' size={33}/>
                              </div>
                              <div style={{display:'flex',flex:3,flexDirection:'column'}}>
                                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',paddingRight:15}}>
                                  <div style={{display:'flex'}}>
                                    RESARAIL : {ResarailDisplay(data.pos_data.resarail.toUpperCase())}
                                  </div>
                                  <div style={{display:'flex'}}>
                                    {i18n.t('item.'+data.pos_data.deployment)}
                                  </div>
                                </div>
                                <p>Région : {window.lblREG(data.pos_data.region)}</p>
                                <div className="resarailName">
                                  Libellé : {data.pos_data.name}
                                </div>
                              </div>
                            </div>
                          );
                        }
                        if( StrDef(data.fiche_e_id) ) {       // on cherche un PDV
                          return(
                            <div key={'sr'+i} className='row' onClick={this.SearchClick.bind(this,data.fiche_e_id)}>
                              <div style={{display:'flex',flex:1,justifyContent:'center',alignItems:'center'}}>
                                <Icon name='pos' size={33}/>
                              </div>
                              <div style={{display:'flex',flex:3,flexDirection:'column'}}>
                                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',paddingRight:15}}>
                                  <div style={{display:'flex'}}>
                                    RESARAIL : {window.synchro.RESARAIL.r(data.fiche_e_data.resarail)}
                                  </div>
                                  <div style={{display:'flex'}}>
                                    {i18n.t('item.'+data.fiche_e_data.deployment)}
                                  </div>
                                </div>
                                <p>Région : {window.lblREG(data.fiche_e_data.region)}</p>
                                <div className="resarailName">
                                  Code Recette : {data.fiche_e_data.code_recette}
                                </div>
                              </div>
                            </div>
                          );
                        }
                      })
                    }
                  </div>
                }
              </div>
              }

            </div>

            <BrowserView>
              <button
                aria-controls="navigation-index"
                aria-expanded={this.state.collapseOpen}
                aria-label="Toggle navigation"
                className="navbar-toggler"
                // data-target="#navigation"
                data-toggle="collapse"
                type="button"
                onClick={this.toggleCollapse}
              >
                <span className="navbar-toggler-bar navbar-kebab" />
                <span className="navbar-toggler-bar navbar-kebab" />
                <span className="navbar-toggler-bar navbar-kebab" />
              </button>



              <Collapse
                className="justify-content-end"
                navbar
                isOpen={this.state.collapseOpen}
              >
                <Nav navbar>
                  { (this.state.collapseOpen) &&
                    <>
                      <Button className="btn-link" color="default" onClick={this.About}>{i18n.t('navbar.version')}</Button>
                      <Button className="btn-link" color="default" onClick={this.ChangePwd}>{i18n.t('navbar.changePwd')}</Button>
                      <Button className="btn-link" color="default" onClick={this.Logout}>{i18n.t('app.logout')}</Button>
                    </>
                  }
                  { !this.state.collapseOpen &&
                  <UncontrolledDropdown className="btn-rotate" nav>
                    <DropdownToggle
                      aria-haspopup={true}
                      color="default"
                      data-toggle="dropdown"
                      id="navbarDropdownMenuLink"
                      nav
                    >
                      <Icon name="avatar" size={33}/>
                    </DropdownToggle>
                    <DropdownMenu aria-labelledby="navbarDropdownMenuLink" right>
                      <DropdownItem onClick={this.About}>{i18n.t('navbar.version')}</DropdownItem>
                      <DropdownItem onClick={this.ChangePwd}>{i18n.t('navbar.changePwd')}</DropdownItem>
                      <DropdownItem onClick={this.Logout}>{i18n.t('app.logout')}</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  }
                </Nav>
              </Collapse>
            </BrowserView>

          </Container>
        </Navbar>
      </>
    );
  }
}

export default AdminNavbar;
