import {sharedAPI} from './api';
import XLSX from 'xlsx';
import Fuse from 'fuse.js';
import moment from 'moment';
const cc = require('five-bells-condition');
var _ = require('underscore');

moment.locale('fr', {
    months : 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_'),
    monthsShort : 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
    monthsParseExact : true,
    weekdays : 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
    weekdaysShort : 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
    weekdaysMin : 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
    weekdaysParseExact : true,
    longDateFormat : {
        LT : 'HH:mm',
        LTS : 'HH:mm:ss',
        L : 'DD/MM/YYYY',
        LL : 'D MMMM YYYY',
        LLL : 'D MMMM YYYY HH:mm',
        LLLL : 'dddd D MMMM YYYY HH:mm'
    },
    calendar : {
        sameDay : '[Aujourd’hui à] LT',
        nextDay : '[Demain à] LT',
        nextWeek : 'dddd [à] LT',
        lastDay : '[Hier à] LT',
        lastWeek : 'dddd [dernier à] LT',
        sameElse : 'L'
    },
    relativeTime : {
        future : 'dans %s',
        past : 'il y a %s',
        s : 'quelques secondes',
        m : 'une minute',
        mm : '%d minutes',
        h : 'une heure',
        hh : '%d heures',
        d : 'un jour',
        dd : '%d jours',
        M : 'un mois',
        MM : '%d mois',
        y : 'un an',
        yy : '%d ans'
    },
    dayOfMonthOrdinalParse : /\d{1,2}(er|e)/,
    ordinal : function (number) {
        return number + (number === 1 ? 'er' : 'e');
    },
    meridiemParse : /PD|MD/,
    isPM : function (input) {
        return input.charAt(0) === 'M';
    },
    meridiem : function (hours, minutes, isLower) {
        return hours < 12 ? 'PD' : 'MD';
    },
    week : {
        dow : 1, // Monday is the first day of the week.
        doy : 4  // Used to determine first week of the year.
    }
});

export function ObjectHash(o) {
  var key = _.keys(o);
  key.sort();
  var res = '';
  for(var i=0;i<key.length;i++) {
    res += o[key[i]];
  }
  return res;
}

export function Capitalize( str ) {
  var pieces = str.toLowerCase().split(" ");
  for ( var i = 0; i < pieces.length; i++ )
  {
      var j = pieces[i].charAt(0).toUpperCase();
      pieces[i] = j + pieces[i].substr(1);
  }
  pieces = pieces.join(" ");

  pieces = pieces.split("-");
  for ( var i = 0; i < pieces.length; i++ )
  {
      var j = pieces[i].charAt(0).toUpperCase();
      pieces[i] = j + pieces[i].substr(1);
  }
  pieces = pieces.join("-");

  pieces = pieces.split("'");
  for ( var i = 0; i < pieces.length; i++ )
  {
      var j = pieces[i].charAt(0).toUpperCase();
      pieces[i] = j + pieces[i].substr(1);
  }
  return pieces.join("'");
}

export function SortBy(tab,prop) {
  for(var i=0;i<tab.length;i++) {
    tab[i][prop] += '*******'+i;
  }
  var indexed = _.indexBy( tab, prop );
  var tmp = _.keys(indexed);
  tmp.sort(Intl.Collator().compare);  // sert a trier les string en prenant en compte les accents
  var res = [], cell=null;
  for(var t=0;t<tmp.length;t++) {
    cell = indexed[tmp[t]];
    cell[prop] = cell[prop].split('*******')[0];
    res.push(cell);
  }
  return res;
}

export function Clone(o) {
  return JSON.parse(JSON.stringify(o));
}

export function GetJSON(url,callback) {
  var request = new XMLHttpRequest();
  request.open('GET', url, true);
  request.onload = function() {
    if (request.status >= 200 && request.status < 400) {
      var data = JSON.parse(request.responseText);
      callback(data);
    } else {
      callback({error:'unknown'});
    }
  };
  request.onerror = function() {
    callback({error:'network error'});
  };
  request.send();
}

export function GetMD(url,callback) {
  var request = new XMLHttpRequest();
  request.open('GET', url, true);
  request.onload = function() {
    if (request.status >= 200 && request.status < 400) {
      callback(request.responseText);
    } else {
      callback({error:'unknown'});
    }
  };
  request.onerror = function() {
    callback({error:'network error'});
  };
  request.send();
}


export function StrDef(s) {
  return ( typeof(s) != 'undefined' && s !== null && s !== '' );
}

export function Phone(s) {
  var phoneNumber = s;

  if( StrDef(phoneNumber) ) {
    var formatted = phoneNumber.replace(/(\d{1})(\d{1,3})?(\d{1,3})?(\d{1,3})?/, function(_, p1, p2, p3, p4){
      let output = ""
      if (p1) output = `(${p1})`;
      if (p2) output += ` ${p2}`;
      if (p3) output += ` ${p3}`;
      if (p4) output += ` ${p4}`;
      return output;
    });
  }
  return formatted;
}

export function exportXLSX(arrayOfArrays,titleSheet,titleFile) {
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet(arrayOfArrays);
  XLSX.utils.book_append_sheet(wb, ws, titleSheet);
  XLSX.writeFile(wb, titleFile);
}

export function removeAccents(str) {
  let accents = 'ÀÁÂÃÄÅàáâãäåßÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
  let accentsOut = "AAAAAAaaaaaaBOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz";
  str = str.split('');
  str.forEach((letter, index) => {
    let i = accents.indexOf(letter);
    if (i !== -1) {
      str[index] = accentsOut[i];
    }
  })
  return str.join('');
}

export function ValidateEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function generatePassword() {
 var length = 5,
     charset = "0123456789",
     retVal = "";
 for (var i = 0, n = charset.length; i < length; ++i) {
     retVal += charset.charAt(Math.floor(Math.random() * n));
 }
 return retVal;
}

export function preimageSha256Condition(password) {
 var preimage = generatePassword();
 if( typeof(password) != 'undefined' ) preimage = password;
 var result = {};
 try {
   var condition = new cc.PreimageSha256()
   condition.setPreimage(new Buffer(preimage));
   result = {
     uri: condition.getConditionUri(),
     name: 'password',
     detail: {
       type: 'preimage-sha-256'
     }
   };
 }
 catch (err) {
     console.log(err);
 }
 return result
}

// ======================================================== KeyValueStore ======
export class KVStore {
  constructor(props) {
    console.log( 'KVStore constructor !!!!!', props );

    this.group = props.group;
    this.tab = [];                // c'est le tableau qui contient les values

    this.synchro();
  }

  synchro(callback) {
    var self = this;
    var lastLocal = moment().subtract(100,'years').format('YYYY-MM-DD HH:mm:ss');
    // on load depuis le localstorage
    var data = localStorage.getItem(this.group);
    if( data !== null ) {
      this.tab = JSON.parse(data);
      for(var i=0;i<this.tab.length;i++) {
        if( this.tab[i].modify_date > lastLocal ) lastLocal = this.tab[i].modify_date;
      }
    }
    else {
      this.tab = [];
    }
    // on va voir si on est synchro
    var backend = sharedAPI();
    var lastRemote = moment().format('YYYY-MM-DD HH:mm:ss').replace(' ','%20');

    var fetchRemote = ()=>{
      backend.kv_group_get(this.group,lastRemote,(d)=>{
        if( d.length > 0 ) {
          for(var i=0;i<d.length;i++) {
            self.tab.push(d[i]);
            lastRemote = d[i].modify_date;
          }
          if( lastRemote > lastLocal && d.length==20 ) {
            fetchRemote();
          }
          else {
            localStorage.setItem(this.group,JSON.stringify(this.tab));
          }
        }
      })
    };
    fetchRemote();
  }

  setItem(obj) {
    // on met a jour le cache
    var pos = _.findIndex(this.tab,{id:obj.id});
    this.tab[pos] = JSON.parse(JSON.stringify(obj));
    // on met a jour l'api
    var backend = sharedAPI();
    backend.kv_group_put(this.group,obj,(r)=>{});
  }

  findFuzzItem(keys,search,threshold) {       // threshold : 0 == perfect match, 0.6 : default for fuzzySearch
    var th = 0.6;
    if( typeof(threshold) != 'undefined' ) th = threshold;
    var fuse = new Fuse(this.tab,{keys:keys,threshold:th});
    return fuse.search(search).slice(0,10);
  }
}

//========================================================= Currency ===========
export function price(n) {
  const formatter = new Intl.NumberFormat(navigator.language, {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
  })

  return formatter.format(n);
}

export function getLocale(length) {
  var locale = '';
  if (window.navigator.languages) {
      locale = window.navigator.languages[0];
  } else {
      locale = window.navigator.userLanguage || window.navigator.language;
  }
  var tiret = locale.indexOf('-');
  if( tiret != -1 ) {
    var tmp = locale.split('-');
    locale = tmp[0].toLowerCase()+'-'+tmp[1].toUpperCase();
  }
  else {
    locale = locale.toLowerCase()+'-'+locale.toUpperCase();
  }
  if( length === 2 ) locale = locale.substr(0,2).toUpperCase();
  return locale;
}

// ======================================================== PERMISSION =========
export function HasPermission(scope,need) {
  var tmp = sessionStorage.getItem(scope);
  try {
    if( StrDef(tmp) ) tmp = JSON.parse(tmp);
  }
  catch(e) {
    if( StrDef(tmp) ) tmp = [tmp];
  }
  var res = _.intersection(tmp,need);
  if( res.length > 0 ) return true;
  else return false;
}

// ======================================================== MOMENT =========
export function DateDisplay(date) {
  if( !(/^(0?[1-9]|[12][0-9]|3[01])[\-](0?[1-9]|1[012])[\-]\d{4}$/.test(date)) ) return date;
  return moment(date).format('DD MMMM YYYY');
}

export function DateDisplayS(date) {
  // si ce n'est oas au format YYYY-MM-DD on le passe pas a moment
  if( !(/^(0?[1-9]|[12][0-9]|3[01])[\-](0?[1-9]|1[012])[\-]\d{4}$/.test(date)) ) return date;

  if( StrDef(date) && date != 'undefined' ) {
    try {
      return moment(date).format('DD/MM/YY');
    }
    catch(e) {
      return '';
    }
  }
  else
    return '';
}


export function ResarailDisplay(rr,def) {
  if( rr.length == 5 && rr.startsWith('FR') ) return rr;
  if( StrDef(def) ) return def;
  return '';
}
