import React from "react";
import { NavLink } from "react-router-dom";
import {  Nav,
          Collapse,
          Badge,
          Modal,
          Row, Col,
          Button,
         } from "reactstrap";

import logo from 'assets/img/logo.png';
import Icon from '../Icons/Icon.jsx';
import i18n from 'assets/lib/i18n';
import {StrDef,Clone,SortBy,ResarailDisplay} from 'assets/lib/utils';
import Select from "react-select";


var ps;

class SidebarDetail extends React.Component {
  constructor(props) {
    super(props);

    this.Refresh = this.Refresh.bind(this);
    this.ToggleModalRegion = this.ToggleModalRegion.bind(this);
    this.CancelModalRegion = this.CancelModalRegion.bind(this);
    this.ToggleModalTag = this.ToggleModalTag.bind(this);
    this.CancelModalTag = this.CancelModalTag.bind(this);
    this.FilterCancel = this.FilterCancel.bind(this);
    this.OnResarailChange = this.OnResarailChange.bind(this);
    this.ResarailSearch = this.ResarailSearch.bind(this);

    this.state = {
      equipment:[],
      displayEquipment:false,
      status:[],
      displayStatus:false,
      region:[],
      displayRegion:false,
      deployment:[],
      displayDeployment:false,
      displayFilter:false,
      resarailOption:[],
      resarail:'',
      displayResarailSelect:false,
      location:[],
      displayLocation:false,
      tag:[],
      displayTag:false,
      mode:this.props.mode || 'stock',
    };

    this.state.deployment = [ {key:"S-DEPLOY-AGENT",label:i18n.t('item.S-DEPLOY-AGENT'),checked:false},
                              {key:"S-DEPLOY-PARTNER",label:i18n.t('item.S-DEPLOY-PARTNER'),checked:false},
                              {key:"S-DEPLOY-UNKNOWN",label:i18n.t('item.S-DEPLOY-UNKNOWN'),checked:false} ];

    window.addEventListener('load', this.Refresh, false);
    window.addEventListener('saveState', this.SaveState.bind(this), false);
    window.addEventListener('changeRegion', this.ChangeRegion.bind(this), false);
    window.addEventListener('changeEquipment', this.ChangeEquipment.bind(this), false);
    window.addEventListener('changeResarail', this.ChangeResarail.bind(this), false);
    window.addEventListener('changeDeployment', this.ChangeDeployment.bind(this), false);
  }

  componentWillMount() {
    if( StrDef(window.STATE) && StrDef(window.STATE['sidebarDetail']) ) {
      this.setState(window.STATE['sidebarDetail']);
      window.STATE['sidebarDetail'] = undefined;
    }
    else {
      if( StrDef(window.synchro.EQUIPMENT) ) this.Refresh({detail:'equipment'});
      if( StrDef(window.synchro.REGION) ) this.Refresh({detail:'region'});
      if( StrDef(window.synchro.STATUS) ) this.Refresh({detail:'status'});
      if( StrDef(window.synchro.LOCATION) ) this.Refresh({detail:'location'});
      if( StrDef(window.synchro.TAG) ) this.Refresh({detail:'tag'});
    }
  }

  componentDidMount() {
    this._mounted = true;
    var role = sessionStorage.getItem('currentRole');
    this.setState({currentRole:role});
  }

  componentWillUnmount() {
    this._mounted = false;
    window.removeEventListener('load', this.Refresh, false);
    window.removeEventListener('saveState', this.SaveState.bind(this), false);
    window.removeEventListener('changeRegion', this.ChangeRegion.bind(this), false);
    window.removeEventListener('changeEquipment', this.ChangeEquipment.bind(this), false);
    window.removeEventListener('changeResarail', this.ChangeResarail.bind(this), false);
    window.removeEventListener('changeDeployment', this.ChangeDeployment.bind(this), false);
  }

  SaveState(data) {
    if( data.detail == 'sidebarDetail' ) {
      // on sauve le state pour le recuperer par la suite
      if( !StrDef(window.STATE) ) window.STATE = {};
      window.STATE['sidebarDetail'] = Clone(this.state);
    }
  }

  ChangeRegion(reg) {
    console.log( 'SidebarDetail ChangeRegion ' );
    // ceci arrive quand on recoit l'event change region mais qu'on a pas eu l'event load (les regions)
    if( this.state.region.length == 0 ) {
      setTimeout(this.ChangeRegion.bind(this,reg),100);
      return;
    }
    var region = Clone(this.state.region);
    for(var i=0;i<region.length;i++) {
      if( region[i].key == reg.detail ) {
        region[i].checked = true;
      }
      else {
        region[i].checked = false;
      }
    }
    if( this._mounted ) {
      this.setState({region:region});
    }
    else {
      console.log( 'not _mounted !!!!!' );
    }
  }

  ChangeEquipment(equip) {
    console.log( 'SidebarDetail ChangeEquipment ' );
    // ceci arrive quand on recoit l'event change region mais qu'on a pas eu l'event load (les regions)
    if( this.state.equipment.length == 0 ) {
      setTimeout(this.ChangeEquipment.bind(this,equip),100);
      return;
    }
    var equipment = Clone(this.state.equipment);
    for(var i=0;i<equipment.length;i++) {
      if( equipment[i].key == equip.detail ) {
        equipment[i].checked = true;
      }
      else {
        equipment[i].checked = false;
      }
    }
    if( this._mounted ) {
      this.setState({equipment:equipment});
    }
    else {
      // ce timeout est la car l'event peut arriver avant que ce component soit mounted
      //setTimeout(()=>this.ChangeEquipment(equip),100);
    }
  }

  ChangeResarail(rr) {
    if( this._mounted ) {
      this.setState({resarail:rr.detail.replace('S-RR-','')});
    }
    else {
      // ce timeout est la car l'event peut arriver avant que ce component soit mounted
      setTimeout(()=>this.ChangeResarail(rr),100);
    }
  }

  ChangeDeployment(deploy) {
    var deployment = Clone(this.state.deployment);
    for(var i=0;i<deployment.length;i++) {
      if( deployment[i].key == deploy.detail ) {
        deployment[i].checked = true;
      }
      else {
        deployment[i].checked = false;
      }
    }
    if( this._mounted ) {
      this.setState({deployment:deployment});
    }
    else {
      // ce timeout est la car l'event peut arriver avant que ce component soit mounted
      setTimeout(()=>this.ChangeDeployment(deploy),100);
    }
  }

  Refresh(data) {
    if( data.detail == 'equipment' ) {
      var tab = [];
      for(var i=0;i<window.synchro.EQUIPMENT.length;i++) {
        tab.push({key:window.synchro.EQUIPMENT[i].key,label:window.synchro.EQUIPMENT[i].label,checked:false});
      }
      this.setState({equipment:tab});
    }
    if( data.detail == 'region' ) {
      var tab = [];
      for(var i=0;i<window.synchro.REGION.length;i++) {
        tab.push({key:window.synchro.REGION[i].key,label:window.lblREG(window.synchro.REGION[i].key),checked:false});
      }
      this.setState({region:tab});
    }
    if( data.detail == 'status' ) {
      var tab = [], label='';
      for(var i=0;i<window.synchro.STATUS.length;i++) {
        label = i18n.t('status.'+window.synchro.STATUS[i].key);
        tab.push({key:window.synchro.STATUS[i].key,label:label,checked:false});
      }
      this.setState({status:tab});
    }
    if( data.detail == 'location' ) {
      var tab = [], label='';
      tab.push({key:'UNKNOWN',label:i18n.t('sidebarDetail.unknown'),checked:false});
      for(var l in window.synchro.LOCATION) {
        tab.push({key:l,label:window.synchro.LOCATION[l].name,checked:false});
      }
      this.setState({location:tab});
    }
    if( data.detail == 'tag' ) {
      var tab = [];
      for(var i in window.synchro.TAG) {
        tab.push({key:i,label:window.synchro.TAG[i],checked:false});
      }
      tab = SortBy(tab,'label');
      this.setState({tag:tab});
    }
  }

  DisplayDetail(what) {
    var obj = Clone(this.state);
    obj[what] = !obj[what];
    this.setState(obj);
  }

  FilterEquipmentClick(index) {
    var tab = Clone(this.state.equipment);
    tab[index].checked = !tab[index].checked;

    this.setState({equipment:tab});
    // on envoie l'info
    var event = new CustomEvent('equipmentTag', { 'detail': tab });
    window.dispatchEvent(event);
  }

  FilterStatusClick(index) {
    var tab = Clone(this.state.status);
    tab[index].checked = !tab[index].checked;

    this.setState({status:tab});
    // on envoie l'info
    var event = new CustomEvent('statusTag', { 'detail': tab });
    window.dispatchEvent(event);
  }

  ToggleModalRegion() {
    if( this.state.displayRegion == false ) { // on met de cote le tab region
      this.regionOld = Clone(this.state.region);
    }
    this.setState({displayRegion:!this.state.displayRegion});
  }

  CancelModalRegion() {
    this.ToggleModalRegion();
    // on remet le state comme avant
    this.setState({region:this.regionOld});
  }

  FilterRegionClick(index) {
    var tab = Clone(this.state.region);
    if( index > -1 ) {
      tab[index].checked = !tab[index].checked;
    }
    else if( index == -3 ) {
      this.ToggleModalRegion();
      // on envoie l'info
      var event = new CustomEvent('regionTag', { 'detail': tab });
      window.dispatchEvent(event);
    }
    else {
      for(var i=0;i<tab.length;i++) {
        tab[i].checked = index==-1?true:false;
      }
    }
    this.setState({region:tab,resarail:'',resarailOption:[]});
  }

  ToggleModalTag() {
    if( this.state.displayTag == false ) { // on met de cote le tab region
      this.tagOld = Clone(this.state.tag);
    }
    this.setState({displayTag:!this.state.displayTag});
  }

  CancelModalTag() {
    this.ToggleModalTag();
    // on remet le state comme avant
    this.setState({tag:this.tagOld});
  }

  FilterTagClick(index) {
    var tab = Clone(this.state.tag);
    if( index > -1 ) {
      tab[index].checked = !tab[index].checked;
    }
    else if( index == -3 ) {
      this.ToggleModalTag();
      // on envoie l'info
      var event = new CustomEvent('tagTag', { 'detail': tab });
      window.dispatchEvent(event);
    }
    else {
      for(var i=0;i<tab.length;i++) {
        tab[i].checked = index==-1?true:false;
      }
    }
    this.setState({tag:tab});
  }

  FilterDeploymentClick(index) {
    var tab = Clone(this.state.deployment);
    tab[index].checked = !tab[index].checked;

    this.setState({deployment:tab});
    // on envoie l'info
    var event = new CustomEvent('deploymentTag', { 'detail': tab });
    window.dispatchEvent(event);
  }

  FilterLocationClick(index) {
    var tab = Clone(this.state.location);
    tab[index].checked = !tab[index].checked;

    this.setState({location:tab});
    // on envoie l'info
    var event = new CustomEvent('locationTag', { 'detail': tab });
    window.dispatchEvent(event);
  }

  FilterCancel() {
    var equipment = Clone(this.state.equipment);
    for(var i=0;i<equipment.length;i++) {
      equipment[i].checked = false;
    }
    var status = Clone(this.state.status);
    for(var i=0;i<status.length;i++) {
      status[i].checked = false;
    }
    var region = Clone(this.state.region);
    for(var i=0;i<region.length;i++) {
      region[i].checked = false;
    }
    var deployment = Clone(this.state.deployment);
    for(var i=0;i<deployment.length;i++) {
      deployment[i].checked = false;
    }
    var location = Clone(this.state.location);
    for(var i=0;i<location.length;i++) {
      location[i].checked = false;
    }
    var tag = Clone(this.state.tag);
    for(var i=0;i<tag.length;i++) {
      tag[i].checked = false;
    }
    this.setState({equipment:equipment,status:status,region:region,deployment:deployment,resarail:'',tag:tag,location:location,
                    displayEquipment:false,displayStatus:false,displayTag:false,displayDeployment:false,displayResarailSelect:false,displayLocation:false});
    var event = new CustomEvent('event', { 'detail': 'filterCancel' });
    window.dispatchEvent(event);
  }

  DisplayFilter() {
    if( this.props.history.location.pathname.indexOf('uncompleted') != -1 ) {
      this.props.history.push('/followme/stock');
    }
    if( this.props.history.location.pathname.indexOf('pdv') != -1 && this.props.history.location.pathname!='/followme/pdv' ) {
      this.props.history.push('/followme/pdv');
    }
    this.setState({displayFilter:!this.state.displayFilter});
  }

  DisplayUncompleted() {
    this.setState({displayFilter:false},()=>{
      // on dit a sidebarDetail de sauver son state
      var event = new CustomEvent('saveState', {detail:'sidebarDetail'});
      window.dispatchEvent(event);
    });
    this.props.history.push('/followme/stock/uncompleted');
  }

  DisplayPrevision() {
    this.setState({displayFilter:false},()=>{
      // on dit a sidebarDetail de sauver son state
      var event = new CustomEvent('saveState', {detail:'sidebarDetail'});
      window.dispatchEvent(event);
    });
    this.props.history.push('/followme/pdv/prevision');
  }

  OnResarailChange(t) {
    var self = this;
    var region = '';
    for(var i=0;i<this.state.region.length;i++) {
      if( this.state.region[i].checked ) region += this.state.region[i].key+' '
    }
    if( t.length>2 ){
      window.API.pos_get((r)=>{
        if( !StrDef(r.error) ) {
          var option = [];
          for(var i=0;i<r.length;i++) {
            option.push({ value:r[i].pos_id,
                          label:'('+ResarailDisplay(r[i].pos_data.resarail.toUpperCase(),i18n.t('pdv.prevision'))+') '+r[i].pos_data.name });
          }
          self.setState({resarailOption:option});
        }
      },t+'|S-DE-0 S-DE-2|'+region);
    }
  }

  ResarailSearch() {
    this.setState({displayResarailSelect:!this.state.displayResarailSelect});
  }

  render() {

    var equipment = '', equipmentCount = 0;
    for(var i=0;i<this.state.equipment.length;i++) {
      if( this.state.equipment[i].checked ) {
        equipmentCount++;
        if( equipmentCount < 4 ) {
          equipment += (equipment==''?'':', ')+i18n.t(this.state.equipment[i].label).toLowerCase();
        }
        else if( equipment.indexOf('...')==-1 ) {
          equipment += '...';
        }
      }
    }
    if( equipmentCount == this.state.equipment.length ) status = 'Tous les types';

    var region = '', regionCount = 0;
    for(var i=0;i<this.state.region.length;i++) {
      if( this.state.region[i].checked ) {
        regionCount++;
        if( regionCount < 5 ) {
          region += (region==''?'':', ')+i18n.t(this.state.region[i].label).toLowerCase();
        }
        else if( region.indexOf('...')==-1 ) {
          region += '...';
        }
      }
    }
    if( regionCount == this.state.region.length ) region = 'Toutes les régions';

    var status = '', statusCount = 0;
    for(var i=0;i<this.state.status.length;i++) {
      if( this.state.status[i].checked ) {
        statusCount++;
        if( statusCount < 3 ) {
          status += (status==''?'':', ')+i18n.t(this.state.status[i].label).toLowerCase();
        }
        else if( status.indexOf('...')==-1 ) {
          status += '...';
        }
      }
    }
    if( statusCount == this.state.status.length ) status = 'Tous les status';

    var deployment = '';
    if( this.state.deployment[0].checked ) deployment = i18n.t('item.'+this.state.deployment[0].key);
    if( this.state.deployment[1].checked ) deployment += (deployment==''?'':', ')+i18n.t('item.'+this.state.deployment[1].key);
    if( this.state.deployment[2].checked ) deployment += (deployment==''?'':', ')+i18n.t('item.'+this.state.deployment[2].key);
    if( this.state.deployment[0].checked && this.state.deployment[1].checked && this.state.deployment[2].checked ) deployment = 'Tous';


    var location = '', locationCount = 0;
    for(var i=0;i<this.state.location.length;i++) {
      if( this.state.location[i].checked ) {
        locationCount++;
        if( locationCount < 5 ) {
          location += (location==''?'':', ')+this.state.location[i].label;
        }
        else if( location.indexOf('...')==-1 ) {
          location += '...';
        }
      }
    }
    if( locationCount == this.state.location.length ) location = 'Tous';

    var tag = '', tagCount = 0;
    for(var i=0;i<this.state.tag.length;i++) {
      if( this.state.tag[i].checked ) {
        tagCount++;
        if( tagCount < 5 ) {
          tag += (tag==''?'':', ')+i18n.t(this.state.tag[i].label).toLowerCase();
        }
        else if( tag.indexOf('...')==-1 ) {
          tag += '...';
        }
      }
    }
    if( tagCount == this.state.tag.length ) tag = 'Toutes';

    var cancelFilter = false;
    if( (equipmentCount > 0)
        || (regionCount > 0)
        || (statusCount > 0)
        || StrDef(this.state.resarail)
        || (deployment != '')
        || (tag != '')
        || (location != '') ) {
      cancelFilter = true;
    }

    return (
      <div className='sidebarDetail'>

        <div className='block' style={{borderBottomWidth:this.state.displayFilter?0:1}}>
          <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',cursor:'pointer'}}
                onClick={this.DisplayFilter.bind(this)}>
            {i18n.t('sidebarDetail.title')}
            <div>
              { this.state.displayFilter && cancelFilter &&
                <Button color="info" size="sm" style={{padding:'3px',fontSize:'0.6rem',margin:'0px',marginRight:'5px',cursor:cancelFilter?'pointer':''}} onClick={this.FilterCancel}>Annuler</Button>
              }
              <Icon name={this.state.displayFilter?'down':'forward'} color="#000" size={16}/>
            </div>
          </div>
        </div>

        { this.state.displayFilter && this.state.equipment.length > 0 && this.props.mode=='stock' &&
          <div className='blockSub'>
            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',cursor:'pointer',marginBottom:this.state.displayEquipment?'5px':'0px'}}
                  onClick={this.DisplayDetail.bind(this,'displayEquipment')}>
              {i18n.t('sidebarDetail.filterMaterial')}
              <div>
                { equipmentCount > 0 &&
                  <>
                    <Badge color="primary" pill>{equipmentCount}</Badge>
                    &nbsp;&nbsp;
                  </>
                }
                <Icon name={this.state.displayEquipment?'down':'forward'} color="#000" size={16}/>
              </div>
            </div>
            { !this.state.displayEquipment &&
              <div onClick={this.DisplayDetail.bind(this,'displayEquipment')} style={{cursor:'pointer'}}>
                <small style={{textTransform:'capitalize'}}>{equipment}</small>
              </div>
            }
            { this.state.displayEquipment && this.state.equipment.map((data,i)=>{
                return(
                  <button key={'btnTag'+i} className={'btnTag'+(data.checked?' selected':'')} onClick={this.FilterEquipmentClick.bind(this,i)}>
                    {i18n.t(data.label)}
                    { data.checked &&
                      <Icon name="close" color="#000" size={16}/>
                    }
                  </button>
                );
              })
            }
          </div>
        }

        { this.state.displayFilter && this.state.status.length > 0 && this.props.mode=='stock' &&
          <div className='blockSub'>
            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',cursor:'pointer',marginBottom:this.state.displayStatus?'5px':'0px'}}
                  onClick={this.DisplayDetail.bind(this,'displayStatus')}>
              {i18n.t('sidebarDetail.filterStatus')}
              <div>
                { statusCount > 0 &&
                  <>
                    <Badge color="primary" pill>{statusCount}</Badge>
                    &nbsp;&nbsp;
                  </>
                }
                <Icon name={this.state.displayStatus?'down':'forward'} color="#000" size={16}/>
              </div>
            </div>
            { !this.state.displayStatus &&
              <div onClick={this.DisplayDetail.bind(this,'displayStatus')} style={{cursor:'pointer'}}>
                <small style={{textTransform:'capitalize'}}>{status}</small>
              </div>
            }
            { this.state.displayStatus && this.state.status.map((data,i)=>{
                return(
                  <button key={'btnTag'+i} className={'btnTag'+(data.checked?' selected':'')} onClick={this.FilterStatusClick.bind(this,i)}>
                    {i18n.t(data.label)}
                    { data.checked &&
                      <Icon name="close" color="#000" size={16}/>
                    }
                  </button>
                );
              })
            }
          </div>
        }

        { this.state.displayFilter && this.state.region.length > 0 && this.state.currentRole == 'ADM' &&
          <div className='blockSub'>
            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',cursor:'pointer'}}
                  onClick={this.ToggleModalRegion}>
              {i18n.t('sidebarDetail.filterRegion')}
              <div>
                { regionCount > 0 &&
                  <>
                    <Badge color="primary" pill>{regionCount}</Badge>
                    &nbsp;&nbsp;
                  </>
                }
                <Icon name={'forward'} color="#000" size={16}/>
              </div>
            </div>
            <div onClick={this.ToggleModalRegion} style={{cursor:'pointer'}}>
              <small style={{textTransform:'capitalize'}}>{region}</small>
            </div>
          </div>
        }

        { this.state.displayFilter && (this.props.mode=='stock'||this.props.mode=='fichee') &&
          <div className='blockSub'>
            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',cursor:'pointer'}}
                  onClick={this.ResarailSearch}>
              {i18n.t('sidebarDetail.filterResarail')}
              <div>
                <Icon name={this.state.displayResarailSelect?'down':'forward'} color="#000" size={16}/>
              </div>
            </div>

            { !this.state.displayResarailSelect && StrDef(this.state.resarail) &&
              <div onClick={this.ResarailSearch} style={{cursor:'pointer'}}>
                <small style={{textTransform:'uppercase'}}>{window.synchro.RESARAIL.d(this.state.resarail).pos_data.deleted=='2'?'PREVISION':window.synchro.RESARAIL.r(this.state.resarail)}</small><br/>
                <small style={{textTransform:'capitalize'}}>{window.synchro.RESARAIL.n(this.state.resarail)}</small>
              </div>
            }
            { this.state.displayResarailSelect &&
              <Select
                className="react-select primary selectBtn"
                classNamePrefix="react-select"
                isClearable={true}
                autoFocus
                value={{value:this.state.resarail,label:window.synchro.RESARAIL.n(this.state.resarail)}}
                onChange={(o)=>{
                  if( StrDef(o) ) {
                    this.setState({resarail:o.value,displayResarailSelect:false});
                    var event = new CustomEvent('resarailTag', { 'detail': o.value });
                    window.dispatchEvent(event);
                  }
                  else {
                    this.setState({resarail:''});
                    var event = new CustomEvent('resarailTag', { 'detail': '' });
                    window.dispatchEvent(event);
                  }
                }}
                onInputChange={this.OnResarailChange}
                options={this.state.resarailOption}
                placeholder="RESARAIL"
              />
            }
          </div>
        }

        { this.state.displayFilter &&
          <div className='blockSub'>
            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',cursor:'pointer',marginBottom:this.state.displayDeployment?'5px':'0px'}}
                  onClick={this.DisplayDetail.bind(this,'displayDeployment')}>
              {i18n.t('sidebarDetail.filterDeployment')}
              <div>
                <Icon name={this.state.displayDeployment?'down':'forward'} color="#000" size={16}/>
              </div>
            </div>
            { !this.state.displayDeployment &&
              <div onClick={this.DisplayDetail.bind(this,'displayDeployment')} style={{cursor:'pointer'}}>
                <small style={{textTransform:'capitalize'}}>{deployment}</small>
              </div>
            }
            { this.state.displayDeployment && this.state.deployment.map((data,i)=>{
                return(
                  <button key={'btnTag'+i} className={'btnTag'+(data.checked?' selected':'')} onClick={this.FilterDeploymentClick.bind(this,i)}>
                    {i18n.t('item.'+data.key)}
                    { data.checked &&
                      <Icon name="close" color="#000" size={16}/>
                    }
                  </button>
                );
              })
            }
          </div>
        }

        { this.state.displayFilter && this.state.tag.length > 0 && this.props.mode == 'stock' &&
          <div className='blockSub'>
            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',cursor:'pointer'}}
                  onClick={this.ToggleModalTag}>
              {i18n.t('sidebarDetail.filterTag')}
              <div>
                { tagCount > 0 &&
                  <>
                    <Badge color="primary" pill>{tagCount}</Badge>
                    &nbsp;&nbsp;
                  </>
                }
                <Icon name={'forward'} color="#000" size={16}/>
              </div>
            </div>
            <div onClick={this.ToggleModalTag} style={{cursor:'pointer'}}>
              <small style={{textTransform:'capitalize'}}>{tag}</small>
            </div>
          </div>
        }

        { this.state.displayFilter && this.props.mode=='stock' &&
          <div className='blockSub' style={{marginLeft:'0px',paddingLeft:'25px'}}>
            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',cursor:'pointer',marginBottom:this.state.displayLocation?'5px':'0px'}}
                  onClick={this.DisplayDetail.bind(this,'displayLocation')}>
              {i18n.t('sidebarDetail.filterLocation')}
              <div>
                { locationCount > 0 &&
                  <>
                    <Badge color="primary" pill>{locationCount}</Badge>
                    &nbsp;&nbsp;
                  </>
                }
                <Icon name={this.state.displayLocation?'down':'forward'} color="#000" size={16}/>
              </div>
            </div>
            { !this.state.displayLocation &&
              <div onClick={this.DisplayDetail.bind(this,'displayLocation')} style={{cursor:'pointer'}}>
                <small style={{textTransform:'capitalize'}}>{location}</small>
              </div>
            }
            { this.state.displayLocation && this.state.location.map((data,i)=>{
                return(
                  <button key={'btnTag'+i} className={'btnTag'+(data.checked?' selected':'')} onClick={this.FilterLocationClick.bind(this,i)}>
                    {data.label}
                    { data.checked &&
                      <Icon name="close" color="#000" size={16}/>
                    }
                  </button>
                );
              })
            }
          </div>
        }

        { this.state.currentRole == 'ADM' && this.props.mode=='stock' &&
          <div className='block'>
            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',cursor:'pointer',userSelect:'none'}}
                  onClick={this.DisplayUncompleted.bind(this)}>
              {i18n.t('sidebarDetail.uncompletedList')}
            </div>
          </div>
        }

        { this.props.mode=='pdv' &&
          <div className='block'>
            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',cursor:'pointer',userSelect:'none'}}
                  onClick={this.DisplayPrevision.bind(this)}>
              {i18n.t('sidebarDetail.previsionPdv')}
            </div>
          </div>
        }

        {/********************* MODAL DES REGION *****************************/}
        <Modal isOpen={this.state.displayRegion} toggle={this.ToggleModalRegion} backdrop='static'>
          <div className="modal-header justify-content-center">
            <h5 className="title">{i18n.t('sidebarDetail.ModalRegionTitle')}</h5>
            <Icon name="close" color="#000" size={32} style={{position:'absolute',right:'20px',top:'20px',cursor:'pointer'}} onClick={this.CancelModalRegion}/>
          </div>

          <div className="modal-body">
            <div className="modalRegion">
              <Row>
                <Col md="12">
                  <div style={{display:'flex',flexDirection:'column',height:'calc(100vh - 290px)',overflow:'auto'}}>
                  { this.state.region.map((data,i)=>{
                      return (
                        <button key={'region'+i} className={'btnTag'+(data.checked?' selected':'')} style={{width:'calc(100% - 5px)'}} onClick={this.FilterRegionClick.bind(this,i)}>
                          {data.label}
                          { data.checked &&
                            <Icon name="close" color="#000" size={16}/>
                          }
                        </button>
                      );
                    })
                  }
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <Button color="primary" size="sm" outline onClick={this.FilterRegionClick.bind(this,-2)}>
                    {i18n.t('region.null')}
                  </Button>
                </Col>
                <Col md="6" className="text-right">
                  <Button color="primary" size="sm" outline onClick={this.FilterRegionClick.bind(this,-1)}>
                    {i18n.t('region.all')}
                  </Button>
                </Col>
              </Row>

              <Row>
                <Col md="12" className="text-center">
                  <Button color="primary" onClick={this.FilterRegionClick.bind(this,-3)}>
                    {i18n.t('all.validate')}
                  </Button>
                </Col>
              </Row>

            </div>
          </div>
        </Modal>

        {/********************* MODAL DES TAGS *****************************/}
        <Modal isOpen={this.state.displayTag} toggle={this.ToggleModalTag} backdrop='static'>
          <div className="modal-header justify-content-center">
            <h5 className="title">{i18n.t('sidebarDetail.ModalTagTitle')}</h5>
            <Icon name="close" color="#000" size={32} style={{position:'absolute',right:'20px',top:'20px',cursor:'pointer'}} onClick={this.CancelModalTag}/>
          </div>

          <div className="modal-body">
            <div className="modalRegion">
              <Row>
                <Col md="12">
                  <div style={{display:'flex',flexDirection:'column',height:'calc(100vh - 290px)',overflow:'auto'}}>
                  { this.state.tag.map((data,i)=>{
                      return (
                        <button key={'region'+i} className={'btnTag'+(data.checked?' selected':'')} style={{width:'calc(100% - 5px)'}} onClick={this.FilterTagClick.bind(this,i)}>
                          {data.label}
                          { data.checked &&
                            <Icon name="close" color="#000" size={16}/>
                          }
                        </button>
                      );
                    })
                  }
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <Button color="primary" size="sm" outline onClick={this.FilterTagClick.bind(this,-2)}>
                    {i18n.t('region.null')}
                  </Button>
                </Col>
                <Col md="6" className="text-right">
                  <Button color="primary" size="sm" outline onClick={this.FilterTagClick.bind(this,-1)}>
                    {i18n.t('region.all')}
                  </Button>
                </Col>
              </Row>

              <Row>
                <Col md="12" className="text-center">
                  <Button color="primary" onClick={this.FilterTagClick.bind(this,-3)}>
                    {i18n.t('all.validate')}
                  </Button>
                </Col>
              </Row>

            </div>
          </div>
        </Modal>

      </div>
    );
  }
}

export default SidebarDetail;
