import React from "react";
// react plugin used to create charts
import { Line, Bar, Doughnut } from "react-chartjs-2";
import Select from "react-select";

import i18n from "../assets/lib/i18n";
import {StrDef,Phone,Clone,SortBy} from '../assets/lib/utils';
import {sharedAPI} from "../assets/lib/api";
import Icon from '../components/Icons/Icon.jsx';
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import Fuse from 'fuse.js';

// reactstrap components
import {
  Badge,
  ButtonGroup,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  Modal,
  UncontrolledTooltip
} from "reactstrap";

import XLSX from 'xlsx';

const NB_PER_PAGE = 15;
const POS_PUT_LIMIT = 100;

class Region extends React.Component {
  constructor(props) {
    super(props);

    this.RowClick = this.RowClick.bind(this);
    this.Refresh = this.Refresh.bind(this);
    this.Add = this.Add.bind(this);
    this.Save = this.Save.bind(this);
    this.ToggleModalDelete = this.ToggleModalDelete.bind(this);
    this.Import = this.Import.bind(this);
    this.XslxHandleFile = this.XslxHandleFile.bind(this);
    this.UpdateWindowDimensions = this.UpdateWindowDimensions.bind(this);

    this.state = {
      list:[],
      mode:'list',
      pos_data: { resarail:'',
                  name:'',
                  deployment:'S-DEPLOY-AGENT',
                  region:'S-REG01',
                  uic:'',
                  deleted:'0' },
      region_id:'',
      region_name:'',
      insert:false,     // mode add
      error:{},
      winW: window.innerWidth,
      winH: window.innerHeight,
      importRegion:'',
    };

    this.backend = sharedAPI();

    window.addEventListener('load', this.Refresh, false);

    if( StrDef(this.props.match.params.region) ) {
      this.state.mode = 'update';
      if( this.props.match.params.region == 'creation' ) this.state.insert = true;
      if( this.props.match.params.region == 'import' ) {
        this.state.mode = 'import';
      }
    }
  }

  componentWillMount() {
    if( window.synchro.REGIONloaded ) this.Refresh({detail:'region'});
  }

  componentDidMount() {
    if( StrDef(this.props.match.params.region) && this.props.match.params.region == 'import' ) {

    }
    else {
      if( StrDef(this.props.match.params.region) && this.props.match.params.region != 'creation' ) {
        //
      }
      else {
        // rien a faire on attend la synchro
      }
    }
    window.addEventListener('resize', this.UpdateWindowDimensions);

    // var event = new CustomEvent('event', {detail:{type:'mount', screen:'pdv'}});
    // window.dispatchEvent(event);
  }

  componentWillUnmount() {
    window.removeEventListener('load', this.Refresh, false);
    window.removeEventListener('resize', this.UpdateWindowDimensions);

    // var event = new CustomEvent('event', {detail:{type:'unmount', screen:'pdv'}});
    // window.dispatchEvent(event);
  }

  UpdateWindowDimensions() {
    this.setState({ winW: window.innerWidth, winH: window.innerHeight });
  }

  Refresh(data) {
    if( data.detail == 'region' ) {
      var tab = [], label='';
      for(var i=0;i<window.synchro.REGION.length;i++) {
        label = window.lblREG(window.synchro.REGION[i].key);
        tab.push({value:window.synchro.REGION[i].key,label:label});
      }
      tab = SortBy(tab,'label');

      var obj = {};
      obj.list = tab;

      if( StrDef(this.props.match.params.region) && this.props.match.params.region != 'creation' ) {
        obj.region_id = this.props.match.params.region;
        obj.region_name = window.lblREG(this.props.match.params.region);
      }
      this.setState(obj);
    }
  }

  Change(what,t) {
    var data = Clone(this.state);
    data[what] = t.target.value;

    this.setState(data,()=>{
      this.ControlForm();
    });
  }

  ControlForm() {
    var errorCount = 0;
    var error = {};

    if( !StrDef(this.state.region_name) ) {
      error['name']=true;
      errorCount++;
    }

    this.setState({error:error});
    return errorCount;
  }

  Load(data) {
    if( !StrDef(data.pos_data.resarail) ) data.pos_data.resarail = '';
    this.setState( data );
  }

  RowClick(index) {
    this.props.history.push('/followme/region/'+this.state.list[index].value);
  }

  Add() {
    this.props.history.push('/followme/region/creation');
  }

  LoadMore() {
    this.LoadMode = 'append';
    window.API.pos_get( this.LoadList,'S-DE-0',encodeURI(this.state.list[this.state.list.length-1].modify_date) );
  }

  Save() {

    var error = this.ControlForm();
    if( error > 0 ) {
      window.notify(i18n.t('region.errorUpdate'),'danger');
      return;
    }

    var data = {
      region_id:this.state.region_id.replace('S-REG',''),
      region_name:this.state.region_name,
    };

    if( this.state.insert ) {
      delete(data.region_id);
      window.API.region_post(data,(r)=>{
        if( StrDef(r.error) ) {
          window.notify(i18n.t('all.recordImpossible'),'danger');
        }
        else {
          window.API.Synchro('region');
          this.props.history.goBack();
        }
      });
    }
    else {
      window.API.region_put(data,(r)=>{
        console.log( 'region put :::::', r );
        if( StrDef(r.error) ) {
          window.notify(i18n.t('all.recordImpossible'),'danger');
        }
        else {
          window.API.Synchro('region');
          this.props.history.goBack();
        }
      });
    }

  }

  ToggleModalDelete() {
    if( !this.state.modalDelete ) {   // on ouvre la modal
      window.API.ocode_get((res)=>{
        window.API.pos_get((resPos)=>{
          var posExists = true;
          if( StrDef(resPos) && !StrDef(resPos.error) && resPos.length == 0 ) posExists = false;
          var ocodeExists = true;
          if( StrDef(res) && !StrDef(res.error) && res.length == 0 ) ocodeExists = false;

          this.setState({pending:false,deletePossible:!ocodeExists && !posExists});
        },this.state.region_id+'|S-DE-0 S-DE-2');
      },this.state.region_id);
    }
    this.setState({pending:true,modalDelete:!this.state.modalDelete});
  }

  CloseModalDelete(bool) {
    this.setState({deletePossible:false});
    this.ToggleModalDelete();
    if( bool ) {
      var data = {
        region_id:this.state.region_id.replace('S-REG',''),
      };
      window.API.region_del(data,(r)=>{
        if( StrDef(r.error) ) {
          window.notify(i18n.t('all.recordImpossible'),'danger');
        }
        this.props.history.goBack();
      });
    }
    else {
      this.props.history.goBack();
    }
  }

  // ------------------------------------------ IMPORT XSLX -------------------
  Import() {
    this.props.history.push('/followme/pdv/import');
  }

  RowParse(data) {
    try {
      var errorCount = 0, errorLabel=[];

      // deploiement
      var deploy = 'UNKNOWN';
      var tmp = data[this.parsedIndex['deployment']].toUpperCase();
      tmp = tmp.normalize().replace(/[\u0300-\u036f]/g, ""); // on enleve les accents au cas ou
      if( tmp.indexOf('AGENT') != -1 ) deploy = 'AGENT';
      if( tmp.indexOf('PART') != -1 ) deploy = 'PARTNER';

      // region
      var reg = data[this.parsedIndex['region']];
      var options = {
        shouldSort: true,
        tokenize: true,
        matchAllTokens: true,
        threshold: 0.6,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: [
          "label"
        ]
      };

      var list = '';
      try {
        list = JSON.parse(this.state.importRegion);
      }
      catch(e) {
        if( StrDef(window.synchro.REGION) ) this.Refresh({detail:'region'});
        return {valid:false,pos_data:null,errorLabel:['Liste des régions malformée']};
      }

      var fuse = new Fuse(list, options); // "list" is the item array
      var result = fuse.search(reg);

      if( result.length == 0 ) errorCount++;
      else reg = result[0].key;

      var name = '';
      try {
        name = decodeURIComponent(escape(data[this.parsedIndex['name']]));
      }
      catch(e){
        name = data[this.parsedIndex['name']];
      }

      var pos_data = {  resarail:data[this.parsedIndex['resarail']].toUpperCase().trim(),
                        name:name,
                        deployment:'S-DEPLOY-'+deploy,
                        region:reg,
                        uic:String(data[this.parsedIndex['uic']]),
                        deleted:'S-DE-0' };

      if( ! ( pos_data.resarail.length==5
              && pos_data.resarail.startsWith('FR')
              && /^[a-zA-Z]+$/.test(pos_data.resarail) ) ) {
        errorCount++;
        errorLabel.push('RESARAIL incorrect');
      }

      if( !StrDef(pos_data.name) ) {
        errorCount++;
        errorLabel.push('Nom manquant');
      }

      if( ! (pos_data.uic.length==8
              && pos_data.uic.startsWith('87')
              && /^[0-9]+$/.test(pos_data.uic)) ) {
        errorCount++;
        errorLabel.push('UIC incorrect');
      }

      if( ! ( pos_data.deployment=='S-DEPLOY-AGENT'
              || pos_data.deployment=='S-DEPLOY-PARTNER'
              || pos_data.deployment=='S-DEPLOY-UNKNOWN' ) ) {
        errorCount++;
        errorLabel.push('Mode de déploiement incorrect');
      }

      var found = false;
      for(var i=0;i<this.state.region.length;i++) {
        if( this.state.region[i].value == pos_data.region ) {
          found = true;
          break;
        }
      }
      if( !found ) errorLabel.push('Région introuvable');

      return {valid:found && errorCount==0,pos_data:pos_data,errorLabel:errorLabel};
    }
    catch(e) {
      console.log( e );
      return {valid:false,pos_data:null,errorLabel:[i18n.t('region.errorImport')]};
    }
  }

  XslxHandleFile(e) {
    var self = this;
    var files = e.target.files, f = files[0];
    var reader = new FileReader();

    this.importDuplicate = [];
    this.rowsBuffer = {};

    this.setState({importPending:true,importFinish:false});

    reader.onload = function(e) {
      var data = new Uint8Array(e.target.result);
      var workbook = XLSX.read(data, {type: 'array'});

      var first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
      self.rowsBuffer = {};
      self.rows = XLSX.utils.sheet_to_json(first_worksheet, {header:1});

      // detection des colones
      var first = self.rows[0], tmp='';
      self.parsedIndex = {};      // objet contenant les indices de colonnes

      var options = {
        shouldSort: true,
        tokenize: true,
        threshold: 0.6,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: ["label"]
      };

      var list = [{"key":"region","label":"REGION"},
                  {"key":"uic","label":"UIC"},
                  {"key":"name","label":"PDV"},
                  {"key":"resarail","label":"RESARAIL"},
                  {"key":"deployment","label":"DEPLOIEMENT"}];
      var fuse = null;
      var result = null;


      for(var i=0;i<first.length;i++) {
        tmp = first[i].toUpperCase();
        tmp = tmp.normalize().replace(/[\u0300-\u036f]/g, "");
        // on fait fuse en cas d'accent bizarre utf8 unicode et compagnie
        fuse = new Fuse(list, options);
        result = fuse.search(tmp);
        if( result.length > 0 ) {
          self.parsedIndex[result[0].key] = i;
        }
        if( tmp.indexOf('REGION')!= -1 ) self.parsedIndex['region'] = i;
        if( tmp.indexOf('UIC')!= -1 ) self.parsedIndex['uic'] = i;
        if( tmp.indexOf('PDV')!= -1 ) self.parsedIndex['name'] = i;
        if( tmp.indexOf('RESARAIL')!= -1 ) self.parsedIndex['resarail'] = i;
        if( tmp.indexOf('DEPLOIEMENT')!= -1 ) self.parsedIndex['deployment'] = i;
      }
      // on controle que la premiere ligne est complete
      var c = 0;
      for(var k in self.parsedIndex) c++;
      if( c != 5 ) {
        console.log( self.parsedIndex );
        window.notify(i18n.t('region.errorImport'),'danger');
        self.setState({importPending:false});
        return;
      }

      self.rows.shift();  // on retire la première ligne

      self.XlsxRecursivePut();
    };
    reader.readAsArrayBuffer(f);
  }

  async XlsxRecursivePut() {
    if( this.rows.length > 0 ) {
      var row = this.RowParse(this.rows.shift());
      if( row.valid ) {
        if( StrDef(this.rowsBuffer[row.pos_data.resarail]) ) {
          this.importDuplicate.push( <div key={'dup'+this.importDuplicate.length}>{JSON.stringify(row.pos_data)}</div> );
        }
        this.rowsBuffer[row.pos_data.resarail] = row.pos_data;
        var nb = 0;
        for(var k in this.rowsBuffer) nb++;
        if( nb == POS_PUT_LIMIT ) {
          var tmp = [];
          for(var r in this.rowsBuffer) {
            tmp.push({pos_id:r,pos_data:this.rowsBuffer[r]});
          }
          var res = await window.API.pos_put_multi(tmp);
          if( StrDef(res.error) ) {
            window.notify(i18n.t('region.errorImport'),'danger');
            return;
          }
          else {
            console.log( 'put 100 : ', res );
          }
          console.log( 'PUT 100 : ', tmp );
          this.rowsBuffer = {};
          // il faut attendre au moins 1000 car mounir ne peut faire q'un PUT par seconde
          setTimeout(()=>this.XlsxRecursivePut(),1000);
        }
        else {
          this.XlsxRecursivePut();
        }
      }
      else {
        var tmp = [], i=0;
        for(var k in row.pos_data) {
          if( i==0 ) tmp.push(<h5 key='ee'>Erreur à cet enregistrement</h5>);
          i++;
          tmp.push( <div key={'ier'+i}>{k+' : '+row.pos_data[k]}</div> );
        }
        tmp.push( <br key='br'/> );
        tmp.push( <h6 key='h6'>Cause{row.errorLabel.length>1?'s':''} :</h6> );
        for(var k in row.errorLabel) {
          i++;
          tmp.push( <p key={'ier'+i}>{row.errorLabel[k]}</p> );
        }
        this.setState({importAbort:true,importError:tmp,importPending:false});
        window.notify(i18n.t('region.errorImport'),'danger');
        return;
      }
    }
    else {
      var nb = 0;
      for(var k in this.rowsBuffer) nb++;
      if( nb > 0 ) {
        var tmp = [];
        for(var r in this.rowsBuffer) {
          tmp.push({pos_id:r,pos_data:this.rowsBuffer[r]});
        }
        var res = await window.API.pos_put_multi(tmp);
        if( StrDef(res.error) ) {
          window.notify(i18n.t('region.errorImport'),'danger');
          return;
        }
        else {
          console.log( 'put 100 : ', res );
        }
        console.log( 'PUT LAST 100 : ', tmp );
        this.setState({importPending:false,importFinish:true});
      }
      else {
        this.setState({importPending:false,importFinish:true});
      }
    }
  }

  render() {

    return (
      <>
        <div className="content">
          { this.state.mode == 'list' &&
            <>
              <Row>
                <Col md="6" xs="6">
                  <h2>{i18n.t('region.title')}</h2>
                </Col>
                <Col md="6" xs="6" className="text-right">
                  <div style={{display:'flex',height:'42px',justifyContent:'flex-end',alignItems:'center'}}>
                    <Button color="primary" style={{paddingLeft:15,paddingRight:15,margin:0,marginTop:'25px'}} onClick={this.Add}>
                      <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                        Ajouter
                        <Icon name='add' size={24} color={'#FFF'} style={{marginLeft:'10px',display:'flex'}}/>
                      </div>
                    </Button>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="12">
                  <Card>
                    <CardBody>
                      { this.state.list.length == 0 &&
                        <>
                          <b>{i18n.t('all.noResult')}</b>
                        </>
                      }
                      { this.state.list.length > 0 &&
                        <>
                          <Table responsive striped>
                            <thead className="text-primary">
                              <tr>
                                <th>Région</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody style={{maxHeight:'calc(100vh - 315px)'}}>
                              { this.state.list.map((data,i)=>{
                                  return(
                                    <tr key={'tr'+i}>
                                      <td>
                                        {data.label}
                                      </td>
                                      <td className="text-right" style={{paddingRight:'20px'}}>
                                        <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-end',alignItems:'center'}}>
                                          <Icon name='edit' size={24} style={{display:'flex',marginRight:'5px'}} onClick={this.RowClick.bind(this,i)}/>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })
                              }
                            </tbody>
                          </Table>
                        </>
                      }
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          }
          { this.state.mode == 'update' &&
            <>
              <Row>
                <Col md="6" xs="12">
                  <h2>{this.state.insert?i18n.t('region.titleAdd'):i18n.t('region.titleUpdate')}</h2>
                </Col>
                <Col md="6" className="text-right">
                  <div style={{display:'flex',flexDirection:'row-reverse'}}>
                    <Button color="primary" style={{margin:0,marginTop:'5px',marginLeft:'10px'}} onClick={this.Save}>Enregistrer</Button>
                    { !this.state.insert &&
                      <Button color="danger" className="btn-link"  style={{color:'#A0A0A0',margin:0,marginTop:'5px'}} onClick={this.ToggleModalDelete}>Supprimer</Button>
                    }
                  </div>
                </Col>
              </Row>

              <Row style={{marginTop:10}}>
                <Col md="12">
                  <Card>
                    <CardBody>
                      <label>Libellé</label>
                      <FormGroup>
                        <Input  type="text" className={StrDef(this.state.error.name)?'error':''}
                                value={this.state.region_name}
                                onChange={this.Change.bind(this,'region_name')}/>
                      </FormGroup>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Modal isOpen={this.state.modalDelete} toggle={this.ToggleModalDelete} backdrop='static'>
                <div className="modal-header justify-content-center">
                  { !this.state.pending && this.state.deletePossible &&
                    <>
                      <h5>{i18n.t('region.ModalDeleteTitle')+' :'}</h5>
                      <div style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                        {this.state.region_name}
                      </div>
                    </>
                  }
                  { !this.state.pending && !this.state.deletePossible &&
                    <>
                      <h5>{i18n.t('region.ModalDeleteImpossibleTitle')+' :'}</h5>
                      <div style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                        {this.state.region_name}
                      </div>
                      <br/>
                      <h5>{i18n.t('region.ModalDeleteImpossibleBody')}</h5>
                    </>
                  }
                  <Icon name="close" color="#000" size={32} style={{position:'absolute',right:'10px',top:'10px',cursor:'pointer'}} onClick={this.CloseModalDelete.bind(this,false)}/>
                </div>

                <div className="modal-body">
                  <div className="modalRegion">
                    <Row>
                      <Col md="12">
                        { this.state.pending &&
                          <p>{i18n.t('region.pending')}</p>
                        }
                      </Col>
                    </Row>
                    { !this.state.pending &&
                      <Row>
                        <Col md="12">
                          <div style={{display:'flex',justifyContent:this.state.deletePossible?'space-between':'center'}}>
                            <Button color="info" style={{margin:0,marginTop:'5px',marginLeft:'10px'}} onClick={this.CloseModalDelete.bind(this,false)}>{this.state.deletePossible?i18n.t('all.cancel'):i18n.t('all.ok')}</Button>
                            { this.state.deletePossible &&
                              <Button color="danger" style={{margin:0,marginTop:'5px'}} onClick={this.CloseModalDelete.bind(this,true)}>Supprimer</Button>
                            }
                          </div>
                        </Col>
                      </Row>
                    }

                  </div>
                </div>
              </Modal>
            </>
          }
          { this.state.mode == 'import' &&
            <>
              { this.state.importAbort && !this.state.importPending &&
                <>
                  {this.state.importError}
                  <Button color="primary" style={{margin:0,marginTop:'25px'}} onClick={()=>{this.setState({importAbort:false,importError:[]})}}>Continuer</Button>
                </>
              }
              { !this.state.importAbort && !this.state.importPending && !this.state.importFinish &&
                <>
                  <Row>
                    <Col md="12">
                      <h2>{i18n.t('region.titleImport')}</h2>
                    </Col>
                  </Row>

                  <Card>
                    <CardBody>
                      <p>Format de fichier attendu :</p>
                      <ul>
                        <li>Peu importe l'ordre des colonnes</li>
                        <li>La première ligne contient les entêtes. Les mots clés suivants sont recherchés pour déterminer la colonne :<br/>
                          REGION, UIC, PDV, RESARAIL, DEPLOIEMENT<br/>
                        </li>
                        <li><b>Peu importe la casse, les accents, l'ordre</b></li>
                        <li>La colonne déploiement contient : AGENT, PARTENAIRE ou UNKNOWN (peu importe la casse, les accents, l'ordre)</li>
                      </ul>
                    </CardBody>
                  </Card>

                  <Card>
                    <CardBody>
                      <p>{i18n.t('region.labelRegion')}</p>
                      <ul>
                        <li>La casse, les accents, les tirets sont ignorés</li>
                        <li>
                          Si vous voulez utiliser deux labels différents pour la même region.<br/>
                          Il faut dupliquer la clé concernée comme suit :<br/>
                          <i>{'{'}"key":"S-REG12","label":"SUD"{'}'}<br/>
                          {'{'}"key":"S-REG12","label":"PROVENCE ALPES COTE D'AZUR"{'}'}</i><br/>
                          Dans le champ texte ci-dessus :<br/>
                        </li>
                      </ul>
                      <FormGroup>
                        <Input  type="textarea" name="text" rows={13}
                                value={this.state.importRegion}
                                onChange={(e)=>{
                                  this.setState({importRegion:e.target.value});
                                }}
                                style={{maxHeight:200,lineHeight:'1.2em'}}/>
                      </FormGroup>
                    </CardBody>
                  </Card>

                  <Card>
                    <CardBody>
                      Les fichiers XLS, XLSX et CSV sont acceptés<br/>
                      Les données du fichier écraseront la base de données<br/>
                      En cas de duplication du RESARAIL c'est la dernière ligne qui est prise en compte<br/>
                      <FormGroup>
                        <Button color="primary">Choisir un fichier</Button>
                        <Input type="file" name="xlfile" id="fileXslx" onChange={this.XslxHandleFile}/>
                      </FormGroup>
                    </CardBody>
                  </Card>

                  <Row>
                    <Col md="12">

                    </Col>
                  </Row>
                </>
              }
              { this.state.importPending &&
                <p>
                  Import en cours...
                </p>
              }
              { this.state.importFinish && !this.state.importPending &&
                <>
                  <h2>Fichier importé avec succès</h2>
                  { this.importDuplicate.length > 0 &&
                    <>
                    <p>Mais avec {this.importDuplicate.length} duplicata{this.importDuplicate.length>1?'s':''} : </p>
                    {this.importDuplicate}
                    <Button color="primary" style={{margin:0,marginTop:'25px'}}
                            onClick={()=>{this.setState({importAbort:false,importFinish:false,importError:[]})}}>Continuer</Button>
                    </>
                  }
                </>
              }
            </>
          }
        </div>
      </>
    );
  }
}

export default Region;
