import React from "react";
// react plugin used to create charts
import { HorizontalBar } from "react-chartjs-2";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

import Select from "react-select";
import i18n from "../assets/lib/i18n";
import {StrDef,Phone,Clone,SortBy,Capitalize,DateDisplayS} from '../assets/lib/utils';
import {sharedAPI} from "../assets/lib/api";
import Icon from '../components/Icons/Icon.jsx';
import lottie from 'lottie-web';
var _ = require('underscore');

const LOTTIE_FILE = '/lottie/loading-point.json';


class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.LoadKPIS = this.LoadKPIS.bind(this);
    this.ChartHBarClick = this.ChartHBarClick.bind(this);
    this.ChangeRegion = this.ChangeRegion.bind(this);
    this.Loaded = this.Loaded.bind(this);

    this.state = {
      tabPill:[],
      chartBarGender:'S-ALL',
      chartBarGenderOption:[{value:'S-ALL',label:i18n.t('gender.S-ALL')}],
      region:'S-REG00',
      regionOption:[{value:'S-REG00',label:i18n.t('region.S-REG00')}],
      chartBarDeploy:'S-DEPLOY-ALL',
      pending:true,
      pendingRegion:true,
      ficheeFree:0,
    };

    this.backend = sharedAPI();

    //================================ chart Horizontal Bar
    this.state.chartHBar = {
      data: {
        labels: [],
        datasets: [
          {
            label: "Data",
            borderColor: "#fcc468",
            fill: true,
            backgroundColor: "#fcc468",
            hoverBorderColor: "#fcc468",
            borderWidth: 5,
            data: []
          }
        ]
      },
      options: {
        tooltips: {
          tooltipFillColor: "rgba(0,0,0,0.5)",
          tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
          tooltipFontSize: 14,
          tooltipFontStyle: "normal",
          tooltipFontColor: "#fff",
          tooltipTitleFontFamily:"'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
          tooltipTitleFontSize: 14,
          tooltipTitleFontStyle: "bold",
          tooltipTitleFontColor: "#fff",
          tooltipYPadding: 6,
          tooltipXPadding: 6,
          tooltipCaretSize: 8,
          tooltipCornerRadius: 6,
          tooltipXOffset: 10
        },
        legend: {
          display: false
        },
        scales: {
          yAxes: [
            {
              ticks: {
                fontColor: "#9f9f9f",
                fontStyle: "bold",
                beginAtZero: true,
                maxTicksLimit: 5,
                padding: 20
              },
              gridLines: {
                zeroLineColor: "transparent",
                display: true,
                drawBorder: false,
                color: "#9f9f9f"
              }
            }
          ],
          xAxes: [
            {
              barPercentage: 0.4,
              gridLines: {
                zeroLineColor: "white",
                display: false,
                drawBorder: false,
                color: "transparent"
              },
              ticks: {
                padding: 20,
                fontColor: "#9f9f9f",
                fontStyle: "bold"
              }
            }
          ]
        }
      }
    };
    // soucis de clone d'objet depuis le state car chartBar n'est pas json safe
    // solution : on modifie this.chartBar puis on fait setState pour raffraichir
    // == solution naz mais plus le temps : il faudrait savoir copier un objet non json safe
    this.chartHBar = {
      data: {
        labels: ["BRE","PDL","NOR","HDF","GES","IDF","VDL","BFC","ARA","NAQ","OCC","PAC","COR"],
        datasets: [
          {
            label: "Tablette : ",
            borderColor: "#51BCDA",
            fill: true,
            backgroundColor: "#00ADEF",
            hoverBorderColor: "#00ADEF",
            borderWidth: 1,
            data: [1,2]
          },
          {
            label: "Smartphone : ",
            borderColor: "red",
            fill: true,
            backgroundColor: "red",
            hoverBorderColor: "red",
            borderWidth: 1,
            data: [10,20]
          }
        ]
      },
      options: {
        tooltips: {
          tooltipFillColor: "rgba(0,0,0,0.5)",
          tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
          tooltipFontSize: 14,
          tooltipFontStyle: "normal",
          tooltipFontColor: "#fff",
          tooltipTitleFontFamily:"'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
          tooltipTitleFontSize: 14,
          tooltipTitleFontStyle: "bold",
          tooltipTitleFontColor: "#fff",
          tooltipYPadding: 6,
          tooltipXPadding: 6,
          tooltipCaretSize: 8,
          tooltipCornerRadius: 6,
          tooltipXOffset: 10
        },
        legend: {
          display: false
        },
        scales: {
          yAxes: [
            {
              stacked:true,
              ticks: {
                fontColor: "#9f9f9f",
                fontStyle: "bold",
                beginAtZero: true,
                maxTicksLimit: 5,
                padding: 20
              },
              gridLines: {
                zeroLineColor: "transparent",
                display: true,
                drawBorder: false,
                color: "#9f9f9f"
              }
            }
          ],
          xAxes: [
            {
              stacked:true,
              gridLines: {
                zeroLineColor: "white",
                display: false,
                drawBorder: false,
                color: "transparent"
              },
              ticks: {
                beginAtZero:true,
                padding: 20,
                fontColor: "#9f9f9f",
                fontStyle: "bold"
              }
            }
          ]
        },
      }
    };

    window.addEventListener('load', this.Loaded, false);
  }

  componentDidMount() {

    //window.API.Robot();

    // on regarde si on vient juste de changer de password
    if( StrDef(this.props.location) && StrDef(this.props.location.state) && StrDef(this.props.location.state.passwordChanged) ) {
      window.notify(i18n.t('login.passwordChanged'),'success');
    }

    this.loadedDetail = [];
    lottie.loadAnimation({
      container: document.querySelector('#anim'), // the dom element that will contain the animation
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: LOTTIE_FILE // the path to the animation json
    });


    this.Loading();
  }

  Loading() {
    console.log( 'Loading .....' );
    if( StrDef(window.synchro.LOCATION) && window.synchro.LOCATION.loaded
        && StrDef(window.synchro.STATUS) && window.synchro.STATUS.loaded
        && StrDef(window.synchro.EQUIPMENT) && window.synchro.EQUIPMENT.loaded
        && StrDef(window.synchro.REGION) && window.synchro.REGIONloaded
        && StrDef(window.synchro.TAG) && window.synchro.TAGloaded
        && StrDef(window.synchro.RESARAIL) && window.synchro.RESARAIL.loaded
        && StrDef(window.synchro.FICHEE) && window.synchro.FICHEE.loaded ) {
      //this.setState({pending:false});
      this.Refresh();
    }
    else {
      window.API.Synchro();
      //setTimeout(()=>this.Loading(),100);
    }
  }

  Loaded(data) {
    console.log( 'Loaded', data, this.loadedDetail );
    if( StrDef(data.detail) ) {
      if( this.loadedDetail.indexOf(data.detail) == -1 ) this.loadedDetail.push( data.detail );
      if( this.loadedDetail.length == 7 ) {
        this.Refresh();
      }
    }
  }

  Refresh() {
    if( StrDef(window.synchro.EQUIPMENT)
        && StrDef(window.synchro.REGION)
        && StrDef(window.synchro.STATUS)
        && StrDef(window.synchro.LOCATION) ) {
      // si referent on bride sur la region concernee
      if( sessionStorage.getItem('currentRole') == 'REF' ) {
        var reg = sessionStorage.getItem('region');
        this.setState({region:reg,currentRole:'REF'});
        window.API.ocode_kpis((data)=>{
          this.lastKpis = Clone(data);
          this.ChangeRegion({value:reg});
        });
      }
      else {
        this.setState({currentRole:sessionStorage.getItem('currentRole')});
        if( StrDef(window.API) ) {
          window.API.ocode_kpis(this.LoadKPIS);
        }
      }
    }
    else {
      setTimeout(()=>this.Refresh(),100);
    }
  }

  LoadKPIS(data) {
    this.lastKpis = Clone(data);
    if( StrDef(data.error) ) {
      window.notify(i18n.t('all.error'),'danger');
      return;
    }

    var chartBarGenderOption = [{value:'S-ALL',label:i18n.t('gender.S-ALL')}];
    for(var i=0;i<window.synchro.EQUIPMENT.length;i++) {
      chartBarGenderOption.push({value:window.synchro.EQUIPMENT[i].key,label:i18n.t('gender.'+window.synchro.EQUIPMENT[i].key)});
    }

    var regionOption = [{value:'S-REG00',label:i18n.t('region.S-REG00')}];
    for(var i=0;i<window.synchro.REGION.length;i++) {
      regionOption.push({value:window.synchro.REGION[i].key,label:window.lblREG(window.synchro.REGION[i].key)});
    }

    this.chartHBar.data.labels = [];
    this.chartHBar.data.labelsId = [];
    var datasetEmpty = [], regionIndex = {};
    if( this.state.region == 'S-REG00' ) {        // toutes les regions
      for(var i=0;i<window.synchro.REGION.length;i++) {
        this.chartHBar.data.labels.push(window.lblREG(window.synchro.REGION[i].key));
        this.chartHBar.data.labelsId.push(window.synchro.REGION[i].key);
        regionIndex[window.synchro.REGION[i].key] = i;
        datasetEmpty.push(0);
      }
    }
    else {                                        // toutes les villes
      this.listCity = [];
      for(var r in this.resarail) {
        this.listCity.push({id:r,
                            resarail:this.resarail[r].resarail,
                            name:this.resarail[r].name,
                            opening:this.resarail[r].opening,
                            order:this.resarail[r].order});
      }
      this.listCity = SortBy(this.listCity,'order');

      var i = 0, resa = '';
      for(var r=0;r<this.listCity.length;r++) {
        resa = '';
        if( this.listCity[r].resarail.length == 5 ) resa = '('+this.listCity[r].resarail+') ';
        this.chartHBar.data.labels.push( resa + this.listCity[r].name );
        this.chartHBar.data.labelsId.push( this.listCity[r].id );
        regionIndex[this.listCity[r].id] = i;
        datasetEmpty.push(0);
        i++;
      }
      this.chartHBar.data.labels.push('Non affecté');
      regionIndex['UNKNOWN'] = i;
      datasetEmpty.push(0);
    }


    var tabDataset = {}
    for(var i=0;i<window.synchro.STATUS.length;i++) {
      tabDataset[window.synchro.STATUS[i].key] = datasetEmpty.slice();
    }
    // on ajoute les fiches E
    tabDataset['FICHEE'] = datasetEmpty.slice();

    data = data.kpis;
    var tmp = null, tmp3 = null, chart = {}, dataset = {}, tabStockByGender = {};
    var index_qty = 4, index_deploy = 4;

    for(var i=0;i<data.length;i++) {
      if( StrDef(data[i]) && data[i].indexOf('S-STOCK')!=-1 && data[i].indexOf('$')==-1 ) {
        tmp = data[i].replace('S-STOCK','').replace('-STATUS-','-STATUT-');
        tmp = tmp.split('S-');
        for(var t=0;t<tmp.length;t++) {
          tmp[t] = 'S-'+tmp[t];
        }
        tmp.shift();
        if( tmp.length == 4 || tmp.length == 5 ) {
          index_qty = tmp.length==4?4:5;
          index_deploy = tmp.length==4?-1:4;
          tmp[2] = tmp[2].replace('S-STATUT','S-STATUS');

          // le resarail est soit en derniere ou avant-derniere position
          tmp[tmp.length-1] = tmp[tmp.length-1].replace('S-RR-','');
          tmp[tmp.length-2] = tmp[tmp.length-2].replace('S-RR-','');

          tmp3 = tmp[tmp.length-1].split('|');
          tmp[tmp.length-1] = tmp3[0];
          tmp.push(tmp3[1]);
          //          0      1      2       3          4        5
          // tmp == region gender status resarail deploiement quantité
          // pour les pill en haut
          if( this.state.currentRole == 'REF' ) {
            if( this.state.region == tmp[0] ) {
              if( StrDef(tabStockByGender[tmp[1]]) ) tabStockByGender[tmp[1]] += parseInt(tmp[index_qty]);
              else tabStockByGender[tmp[1]] = parseInt(tmp[index_qty]);
            }
          }
          else {
            if( StrDef(tabStockByGender[tmp[1]]) ) tabStockByGender[tmp[1]] += parseInt(tmp[index_qty]);
            else tabStockByGender[tmp[1]] = parseInt(tmp[index_qty]);
          }
          // pour chart bar
          if( this.state.chartBarGender == 'S-ALL' ) {
            if( this.state.region == 'S-REG00' ) {
              if( this.state.chartBarDeploy == 'S-DEPLOY-ALL' ) {
                tabDataset[tmp[2]][regionIndex[tmp[0]]] += parseInt(tmp[index_qty]);
              }
              else {
                if( index_deploy>-1 && this.state.chartBarDeploy==tmp[index_deploy] ) tabDataset[tmp[2]][regionIndex[tmp[0]]] += parseInt(tmp[index_qty]);
              }
            }
            else if( this.state.region == tmp[0] ) {
              if( this.state.chartBarDeploy == 'S-DEPLOY-ALL' ) {
                tabDataset[tmp[2]][regionIndex[tmp[3]]] += parseInt(tmp[index_qty]);
              }
              else {
                if( index_deploy>-1 && this.state.chartBarDeploy==tmp[index_deploy] ) tabDataset[tmp[2]][regionIndex[tmp[3]]] += parseInt(tmp[index_qty]);
              }
            }
          }
          else if( this.state.chartBarGender == tmp[1] ) {
            if( this.state.region == 'S-REG00' ) {
              if( this.state.chartBarDeploy == 'S-DEPLOY-ALL' ) {
                tabDataset[tmp[2]][regionIndex[tmp[0]]] += parseInt(tmp[index_qty]);
              }
              else {
                if( index_deploy>-1 && this.state.chartBarDeploy==tmp[index_deploy] ) tabDataset[tmp[2]][regionIndex[tmp[0]]] += parseInt(tmp[index_qty]);
              }
            }
            else if( this.state.region == tmp[0] ) {
              if( this.state.chartBarDeploy == 'S-DEPLOY-ALL' ) {
                tabDataset[tmp[2]][regionIndex[tmp[3]]] += parseInt(tmp[index_qty]);
              }
              else {
                if( index_deploy>-1 && this.state.chartBarDeploy==tmp[index_deploy] ) tabDataset[tmp[2]][regionIndex[tmp[3]]] += parseInt(tmp[index_qty]);
              }
            }
          }
        }
      }
    }

    this.chartHBar.data.datasets = [];

    var tabPill = [], nb = 0;
    // les pillules en haut
    for(var g in tabStockByGender) {
      tabPill.push({label:g,data:tabStockByGender[g]});
    }

    // on ajoute les fiches E
    var n = 0;
    for(var i in regionIndex) {
      n = window.synchro.FICHEE.countByRegionResarail[i];
      n = StrDef(n)?n:0;
      tabDataset['FICHEE'][regionIndex[i]] = n;
    }

    // chart bar
    // on prend dans un certain ordre
    var order = ["S-STATUS-STOCK","S-STATUS-PARAMETER","S-STATUS-COMPLETED","S-STATUS-DELIVERY","S-STATUS-RECEIVE","S-STATUS-BORROW","S-STATUS-RESTORE","FICHEE"];
    var d = '';
    for(var i=0;i<order.length;i++) {
      d = order[i];
      this.chartHBar.data.datasets.push(
        {
          label: i18n.t('status.'+d),
          borderColor: i18n.t('statusColorHex.'+d),
          fill: true,
          backgroundColor: i18n.t('statusColorHex.'+d),
          hoverBorderColor: i18n.t('statusColorHex.'+d),
          borderWidth: 1,
          data: tabDataset[d]
        }
      );
    }
    // maintenant on enleve les lignes où il n'y a pas de stock
    var tmpDataset = this.chartHBar.data.datasets;
    var sum = new Array(this.chartHBar.data.labels.length);
    sum.fill(0);
    for(var i=0;i<this.chartHBar.data.labels.length;i++) {
      for(var l=0;l<tmpDataset.length;l++) {
        console.log("tmpDataset",tmpDataset);
        sum[i] += tmpDataset[l].data[i];
      }
    }
    // mise a jour des labels
    var resLabel = [];
    var resLabelId = [];
    // on initialise resDataset avec tmpDataset mais en mettant vide dans les datas
    // car on va remplir data avec seulement les bonnes cases
    var resDataset = [];
    for(var l=0;l<tmpDataset.length;l++) {
      resDataset.push(Clone(tmpDataset[l]));
      resDataset[l].data = [];
    }
    for(var i=0;i<sum.length;i++) {
      if( sum[i] > 0 ) {
        resLabel.push(this.chartHBar.data.labels[i]);
        resLabelId.push(this.chartHBar.data.labelsId[i]);
        for(var l=0;l<tmpDataset.length;l++) {
          resDataset[l].data.push(tmpDataset[l].data[i]);
        }
      }
    }
    this.chartHBar.data.labels = resLabel.slice();
    this.chartHBar.data.labelsId = resLabelId.slice();
    this.chartHBar.data.datasets = resDataset;

    this.setState({ chartHBar:this.chartHBar,
                    tabPill:tabPill,
                    regionOption:regionOption,
                    ficheeFree:window.synchro.FICHEE.countByRegionResarail[sessionStorage.getItem('region')],
                    chartBarGenderOption:chartBarGenderOption },()=>{
                      setTimeout(()=>{
                        this.setState({
                          pending:false,
                          pendingRegion:false,
                        })
                      },1000); // ici ça plante si on ne met pas ce timeout !!!!!!!! cata, il faut regarder le chart
                    });
  }

  renderPill(option) {
    var color = option.color;
    return (
      <div style={{margin:0,marginBottom:20,cursor:'pointer',overflow:'hidden',borderRadius:'12px',display:'flex',width:'100%',height:'100px',boxShadow:'0 6px 10px -4px rgba(0, 0, 0, 0.15)'}} onClick={this.PillClick.bind(this,option.label)}>
        <div style={{display:'flex',flex:1,height:'100%',justifyContent:'center',alignItems:'center',backgroundColor:'#FFF'}}>
          <img src={i18n.t('image.'+option.label)} style={{width:'64px',height:'64px'}}/>
        </div>
        <div style={{display:'flex',flex:1,flexDirection:'column',height:'100%',backgroundColor:'rgb(0,89,123)',color:'#FFF',justifyContent:'center',alignItems:'left',paddingLeft:'20px'}}>
          <font style={{fontSize:'1.5em',fontWeight:'600'}}>{option.data}</font>
          <font style={{fontSize:'1em'}}>{i18n.t('gender.'+option.label)+(option.data>1?'s':'')}</font>
        </div>
      </div>
    );
  }

  PillClick(equipment) {
    this.props.history.push('/followme/stock/equipment/'+equipment);
  }

  ChartHBarClick(e) {
    if( StrDef(e[0]) && StrDef(e[0]._index) ) {
      var reg = '', resarail = '', deployment = '';
      if( this.state.region == 'S-REG00' ) {
        reg = this.state.chartHBar.data.labelsId[e[0]._index];
      }
      else {
        reg = this.state.region;
        if( StrDef(this.state.chartHBar.data.labelsId[e[0]._index]) ) {
          resarail = '/resarail/S-RR-'+this.state.chartHBar.data.labelsId[e[0]._index];
        }
      }
      var equipment = '';
      if( this.state.chartBarGender != 'S-ALL' ) {
        equipment = '/equipment/'+this.state.chartBarGender;
      }
      var deployment = '';
      if( this.state.chartBarDeploy != 'S-DEPLOY-ALL' ) {
        deployment = '/deploy/'+this.state.chartBarDeploy;
      }
      this.props.history.push('/followme/stock/region/'+reg+equipment+resarail+deployment);
    }
  }

  ChangeRegion(value) {

    this.setState({region:value.value,pendingRegion:true},()=>{
      this.resarail = {};
      var tmp = null, name='', day='', order='';
      for(var rr in window.synchro.RESARAIL.tab) {
        tmp = window.synchro.RESARAIL.tab[rr];
        if( tmp.pos_data.region == value.value ) {
          name = tmp.pos_data.name;
          order = tmp.pos_data.deleted+name;
          if( tmp.pos_data.deleted == '2' ) {
            order = tmp.pos_data.deleted+tmp.pos_data.opening+name;
            day = DateDisplayS(tmp.pos_data.opening);
            if( StrDef(day) ) day = 'PREVISION - '+day+' - ';
            name = day+' '+tmp.pos_data.name;
          }
          if( tmp.pos_data.deleted != '1' ) { // ON NE PREND PAS LES PDV DELETED !!!!!
            this.resarail[tmp.pos_id] = { name:name,
                                          resarail:tmp.pos_data.resarail,
                                          opening:tmp.pos_data.opening,
                                          order:order };
          }
        }
      }
      this.LoadKPIS(this.lastKpis);
    });
  }

  render() {
    var title = i18n.t('dashboard.chartHBarTitle');
    if( this.state.currentRole == 'REF' )
      title = i18n.t('dashboard.chartHBarTitleRef')+' '+window.lblREG(this.state.region);

    return (
      <>
        <div className="content">
          { this.state.pending &&
            <div style={{display:'flex',height:'calc(100vh - 120px)',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
              <div id="anim" style={{width:'130px'}}></div>
              <div style={{display:'flex'}}>Chargement en cours.</div>
            </div>
          }
          { !this.state.pending &&
            <>
              <Row>
                { this.state.tabPill.map((data,i)=>{
                    if( i>2 ) return null;
                    return(
                      <Col key={'p'+i} md="4" sm="12">
                        {this.renderPill(data)}
                      </Col>
                    );
                  })
                }
              </Row>
              <Row>
                { this.state.tabPill.map((data,i)=>{
                    if( i<3 ) return null;
                    return(
                      <Col key={'p'+i} md="4" sm="12">
                        {this.renderPill(data)}
                      </Col>
                    );
                  })
                }
              </Row>
              { this.state.currentRole=='REF' && this.state.ficheeFree > 0 &&
                <Row style={{marginTop:'10px'}}>
                  <Col md="12">
                    <Card>
                      <CardHeader>
                          <h5>{this.state.ficheeFree} fiche{this.state.ficheeFree==1?'':'s'} E disponible{this.state.ficheeFree==1?'':'s'}</h5>
                      </CardHeader>
                    </Card>
                  </Col>
                </Row>
              }
              <Row style={{marginTop:'10px'}}>
                <Col md="12">
                  <Card>
                    <CardHeader>
                        <h5>{title}</h5>
                      <Row>
                        { this.state.currentRole=='REF' &&
                          <Col md="4">
                          </Col>
                        }
                        <Col md="4">
                          <label>Mode de déploiement :</label>
                          <Select
                            className="react-select primary selectBtn"
                            classNamePrefix="react-select"
                            isSearchable={false}
                            value={{value:this.state.chartBarDeploy,label:i18n.t('item.'+this.state.chartBarDeploy)}}
                            onChange={value => {
                              this.setState({chartBarDeploy:value.value,pendingRegion:true},()=>{
                                this.LoadKPIS(this.lastKpis);
                              });
                            }}
                            options={[  {value:"S-DEPLOY-ALL",label:i18n.t('item.S-DEPLOY-ALL')},
                                        {value:"S-DEPLOY-AGENT",label:i18n.t('item.S-DEPLOY-AGENT')},
                                        {value:"S-DEPLOY-PARTNER",label:i18n.t('item.S-DEPLOY-PARTNER')},
                                        {value:"S-DEPLOY-UNKNOWN",label:i18n.t('item.S-DEPLOY-UNKNOWN')} ]}
                            placeholder="Choisir un mode de déploiement"
                          />
                        </Col>
                        <Col md="4">
                          <label>Type de matériel :</label>
                          <Select
                            className="react-select primary selectBtn"
                            classNamePrefix="react-select"
                            isSearchable={false}
                            value={{value:this.state.chartBarGender,label:i18n.t('gender.'+this.state.chartBarGender)}}
                            onChange={value => {
                              this.setState({chartBarGender:value.value,pendingRegion:true},()=>{
                                this.LoadKPIS(this.lastKpis);
                              });
                            }}
                            options={this.state.chartBarGenderOption}
                            placeholder="Choisir un type"
                          />
                        </Col>
                        { this.state.currentRole == 'ADM' &&
                          <Col md="4">
                            <label>Région :</label>
                            <Select
                              className="react-select primary selectBtn"
                              classNamePrefix="react-select"
                              isSearchable={false}
                              value={{value:this.state.region,label:window.lblREG(this.state.region)}}
                              onChange={this.ChangeRegion}
                              options={this.state.regionOption}
                              placeholder="Choisir une région"
                            />
                          </Col>
                        }
                      </Row>
                    </CardHeader>
                    <CardBody>
                      { !this.state.pendingRegion &&
                        <HorizontalBar
                          ref={(r)=>this._chartBar=r}
                          data={this.state.chartHBar.data}
                          height={Math.max(this.chartHBar.data.labels.length*14,100)}
                          options={this.state.chartHBar.options}
                          onElementsClick={this.ChartHBarClick}
                        />
                      }
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          }
        </div>
      </>
    );
  }
}

export default Dashboard;
