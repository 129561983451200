import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch, Redirect } from "react-router-dom";

import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.jsx";
import AuthRoute from "components/AuthRoute/AuthRoute.jsx";

import NotificationAlert from "../../components/Notification/Notification.jsx";

import routes from "routes.js";

var ps;

class Admin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      backgroundColor: "black",
      activeColor: "info",
      sidebarMini: false
    };

    window.notify = this.notify.bind(this);
  }

  notify(msg,type) {
    this.refs.notificationAlert.notificationAlert({ message: msg, type: type });
  }

  componentDidMount() {
    // if (navigator.platform.indexOf("Win") > -1) {
    //   document.documentElement.className += " perfect-scrollbar-on";
    //   document.documentElement.classList.remove("perfect-scrollbar-off");
    //   ps = new PerfectScrollbar(this.refs.mainPanel);
    // }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
  }
  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      return (
        <AuthRoute
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
        />
      );
    });
  };
  handleActiveClick = color => {
    this.setState({ activeColor: color });
  };
  handleBgClick = color => {
    this.setState({ backgroundColor: color });
  };
  handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
    } else {
      this.setState({ sidebarMini: true });
    }
    document.body.classList.toggle("sidebar-mini");
  };
  render() {
    var gender = sessionStorage.getItem('gender');

    return (
      <div className="wrapper">
        <NotificationAlert ref="notificationAlert"/>
        <Sidebar
          {...this.props}
          routes={routes}
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
        />
        <div className="main-panel" ref="mainPanel">
          <AdminNavbar {...this.props} handleMiniClick={this.handleMiniClick} />
          <Switch>
            {this.getRoutes(routes)}
            <Redirect to={'/dashboard'}/>
          </Switch>
        </div>
        { window.TEST &&
          <div style={{width:60,height:'100vh',position:'fixed',zIndex:99999,background:'linear-gradient(90deg, #FF6600 0%, rgba(0,0,0,0.1) 30%, rgba(0,0,0,0) 100%)'}}>
            <div style={{color:'white',fontWeight:600,width:260,padding:10,backgroundColor:'#FF6600'}}>
              VERSION DE TEST
            </div>
          </div>
        }
      </div>
    );
  }
}

export default Admin;
