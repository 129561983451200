import React from "react";
// react plugin used to create charts
import Select from "react-select";
import ReactDatetime from "react-datetime";

import i18n from "../assets/lib/i18n";
import {StrDef,Clone,exportXLSX,DateDisplayS,ResarailDisplay} from '../assets/lib/utils';
import {sharedAPI} from "../assets/lib/api";
import Icon from '../components/Icons/Icon.jsx';
import Fuse from 'fuse.js';
import lottie from 'lottie-web';

// reactstrap components
import {
  Badge,
  ButtonGroup,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  Modal,
  UncontrolledTooltip
} from "reactstrap";

import XLSX from 'xlsx';

var moment = require('moment');
var uuid = require('uuid/v4');

const LOTTIE_FILE = '/lottie/loading-point.json';
const NB_PER_PAGE = 15;
const POS_PUT_LIMIT = 100;

class FicheE extends React.Component {
  constructor(props) {
    super(props);

    this.RowClick = this.RowClick.bind(this);
    this.Refresh = this.Refresh.bind(this);
    this.Add = this.Add.bind(this);
    this.Save = this.Save.bind(this);
    this.FilterChange = this.FilterChange.bind(this);
    this.LoadList = this.LoadList.bind(this);
    this.Load = this.Load.bind(this);
    this.LoadMore = this.LoadMore.bind(this);
    this.ToggleModalDelete = this.ToggleModalDelete.bind(this);
    this.Import = this.Import.bind(this);
    this.Export = this.Export.bind(this);
    this.XslxHandleFile = this.XslxHandleFile.bind(this);
    this.UpdateWindowDimensions = this.UpdateWindowDimensions.bind(this);
    this.OnResarailChange = this.OnResarailChange.bind(this);

    var reg = sessionStorage.getItem('region');
    reg = (reg!==null&&reg!='ALL')?reg:'';
    this.queryRegionDefault = reg;

    this.state = {
      list:[],
      mode:'list',
      fiche_e_data: { code_calife:'',
                      code_comm_depo:'',
                      code_recette:'',
                      deployment:'',
                      lniata:'',
                      region:sessionStorage.getItem('region')!='ALL'?sessionStorage.getItem('region'):'',
                      resarail:'',
                      uic:'' },
      region:[],
      insert:false,     // mode add
      error:{},
      winW: window.innerWidth,
      winH: window.innerHeight,
      importRegion:'',
      queryRegion:this.queryRegionDefault,
      queryDeployment:'',
      queryResarail:'',
      resarailOption:[],
    };

    this.backend = sharedAPI();

    window.addEventListener('load', this.Refresh, false);

    if( StrDef(this.props.match) && StrDef(this.props.match.params.fichee) ) {
      switch (this.props.match.params.fichee) {
        case 'creation': this.state.mode = 'update'; this.state.insert = true; break;
        case 'import': this.state.mode = 'import'; break;
        case 'export': this.state.mode = 'export'; break;
        default: this.state.mode = 'update'; break;
      }
    }

  }

  componentWillMount() {
    if( StrDef(window.synchro.REGION) ) this.Refresh({detail:'region'});
  }

  componentDidMount() {
    if( StrDef(this.props.match.params.fichee) && this.props.match.params.fichee=='import' ) {

    }
    else if( StrDef(this.props.match.params.fichee) && this.props.match.params.fichee=='export' ) {
      this.ExportInit();
    }
    else {
      if( StrDef(this.props.match.params.fichee) && this.props.match.params.fichee != 'creation' ) {
        window.API.fichee_get_one(this.props.match.params.fichee,this.Load);
      }
      else {
        window.API.fichee_get(this.LoadList,this.QueryString());
      }
    }
    // au cas ou on supprime les listener
    window.removeEventListener('resize', this.UpdateWindowDimensions);
    window.removeEventListener('regionTag', this.FilterChange, false);
    window.removeEventListener('resarailTag', this.FilterChange, false);
    window.removeEventListener('deploymentTag', this.FilterChange, false);
    window.removeEventListener('event', this.FilterChange, false);

    window.addEventListener('resize', this.UpdateWindowDimensions);
    window.addEventListener('regionTag', this.FilterChange, false);
    window.addEventListener('resarailTag', this.FilterChange, false);
    window.addEventListener('deploymentTag', this.FilterChange, false);
    window.addEventListener('event', this.FilterChange, false);

    var event = new CustomEvent('event', {detail:{type:'mount', screen:'fichee'}});
    window.dispatchEvent(event);
  }

  componentWillUnmount() {
    window.removeEventListener('load', this.Refresh, false);
    window.removeEventListener('resize', this.UpdateWindowDimensions);
    window.removeEventListener('regionTag', this.FilterChange, false);
    window.removeEventListener('resarailTag', this.FilterChange, false);
    window.removeEventListener('deploymentTag', this.FilterChange, false);
    window.removeEventListener('event', this.FilterChange, false);

    var event = new CustomEvent('event', {detail:{type:'unmount', screen:'fichee'}});
    window.dispatchEvent(event);
  }

  componentDidUpdate( prevProps, prevState ) {
    if( StrDef(this.props.match.params.pdv) ) {
      switch (this.props.match.params.pdv) {
        case 'prevision':
          if( prevState.prevision !== true ) {
            this.setState({prevision:true},()=>{
              window.API.pos_get(this.LoadList,this.QueryString());
            });
          }
          if( prevState.mode != 'list' ) {
            this.setState({mode:'list'},()=>{
              window.API.pos_get(this.LoadList,this.QueryString());
            });
          }
          break;
        case 'prevision-creation':
          if( prevState.mode != 'update' )
            this.setState({mode:'update',insert:true});
          break;
        case 'export':
          if( prevState.mode != 'export' ) {
            this.setState({mode:'export'},()=>{
              this.ExportInit();
            });
          }
          break;
        case 'import':
          if( prevState.mode != 'import' ) {
            this.setState({mode:'import'},()=>{

            });
          }
          break;
        case 'creation':
          break;
        default:  // on est en mode update
          if( prevState.mode != 'update' ) {
            this.setState({mode:'update',insert:false});
            window.API.pos_get_one(this.props.match.params.pdv,this.Load);
          }
          break;
      }
    }
  }

  UpdateWindowDimensions() {
    this.setState({ winW: window.innerWidth, winH: window.innerHeight });
  }

  Refresh(data) {
    if( data.detail == 'region' ) {
      var tab = [], label='';
      for(var i=0;i<window.synchro.REGION.length;i++) {
        label = window.lblREG(window.synchro.REGION[i].key);
        tab.push({value:window.synchro.REGION[i].key,label:label+''});
      }
      var str = JSON.stringify(window.synchro.REGION);
      str = str.replace(/},/g,'},\n');
      str = str.replace('[','[\n');
      str = str.replace(']','\n]');

      var tabRoute = ['creation','import','export','prevision','prevision-creation'];
      var tmp = Clone(this.state.fiche_e_data);
      if( window.synchro.REGION.length > 0
          && (StrDef(this.props.match.params.pdv) && tabRoute.indexOf(this.props.match.params.pdv)!=-1 ) ) {
        // valeur par defaut de la region
        tmp.region = window.synchro.REGION[0].key;
      }
      // attention en cas de référent : on verrouille sur sa region
      this.currentRole = sessionStorage.getItem('currentRole');
      if( sessionStorage.getItem('currentRole') == 'REF' )
        tmp.region = sessionStorage.getItem('region');

      this.setState({region:tab,importRegion:str,fiche_e_data:tmp});
    }
    else {
      this.setState({fake:1});  // à changer!!!!,  on raffraichit l'affichage
    }
  }

  Change(what,t) {
    var data = Clone(this.state.fiche_e_data);

    if( what.indexOf('deploy@')!=-1 ) {
      data['deployment'] = 'S-DEPLOY-'+what.replace('deploy@','');
    }
    else if( what == 'resarail' ) {
      data[what] = t.target.value.toUpperCase();
    }
    else {
      data[what] = t.target.value;
    }

    this.setState({fiche_e_data:data},()=>{
      this.ControlForm();
    });
  }

  ControlForm(force) {
    var errorCount = 0;
    var error = {};

    if( this.state.fiche_e_data.deployment == 'S-DEPLOY-UNKNOWN' ) {
      error['deployment']=true;
      errorCount++;
    }

    if( !StrDef(this.state.fiche_e_data.resarail) ) {
      error['resarail']=true;
      errorCount++;
    }

    if( ! (this.state.fiche_e_data.uic.length==8
            && this.state.fiche_e_data.uic.startsWith('87')
            && /^[0-9]+$/.test(this.state.fiche_e_data.uic)) ) {
      error['uic']=true;
      errorCount++;
    }

    if( this.state.fiche_e_data.deployment == 'S-DEPLOY-AGENT' ) {
      if( !(this.state.fiche_e_data.code_comm_depo.length==10
            && this.state.fiche_e_data.code_comm_depo.startsWith('01')
            && /^[0-9]+$/.test(this.state.fiche_e_data.code_comm_depo.substring(2,10))) ) {
        error['code_comm_depo']=true;
        errorCount++;
      }
    }
    else {
      if( !(this.state.fiche_e_data.code_comm_depo=='801'+this.state.fiche_e_data.uic.substring(2,8)) ) {
        error['code_comm_depo']=true;
        errorCount++;
      }
    }

    if( !(this.state.fiche_e_data.code_recette.length==3 && /^[0-9]+$/.test(this.state.fiche_e_data.code_recette)) ) {
      error['code_recette']=true;
      errorCount++;
    }

    if( !(this.state.fiche_e_data.lniata.length==6
          && this.state.fiche_e_data.lniata.startsWith('8E')
          && /^[0-9a-zA-Z]+$/.test(this.state.fiche_e_data.lniata.substring(2,6)) ) ) {
      error['lniata']=true;
      errorCount++;
    }

    if( !(this.state.fiche_e_data.code_calife.length==11
          && this.state.fiche_e_data.code_calife.substring(0,8)=='DTD'+window.synchro.RESARAIL.r(this.state.fiche_e_data.resarail)
          && /^[0-9]+$/.test(this.state.fiche_e_data.code_calife.substr(8,11))) ) {
      error['code_calife']=true;
      errorCount++;
    }

    if( !StrDef(force) ) this.setState({error:error});
    return errorCount;
  }

  DayValid( current ) {
    var yesterday = moment().subtract( 1, 'day' );
    return current.isAfter( yesterday );
  };

  LoadList(data) {
    var tmp = [];
    if( this.LoadMode == 'append' ) {
      tmp = Clone(this.state.list);
      this.LoadMode = '';
    }
    for(var i=0;i<data.length&&i<NB_PER_PAGE;i++) {
      if( parseInt(data[i].status) != 9 )
        tmp.push(data[i]);
    }
    this.setState({list:tmp,hasMore:data.length>NB_PER_PAGE});
  }

  Load(data) {
    this.setState( data );
  }

  RowClick(index) {
    this.props.history.push('/followme/fichee/'+this.state.list[index].fiche_e_id);
  }

  Add() {
    this.InitFicheeData();
    if( this.state.prevision ) {
      this.props.history.push('/followme/fichee/prevision-creation');
    }
    else {
      this.props.history.push('/followme/fichee/creation');
    }
  }

  LoadMore() {
    this.LoadMode = 'append';
    window.API.fichee_get( this.LoadList,this.QueryString(),encodeURI(this.state.list[this.state.list.length-1].modify_date) );
  }

  Save() {
    var data = Clone(this.state);

    var error = this.ControlForm();
    console.log( '>>>>>>>>>>>>>', error, this.state.error );
    if( error > 0 ) {
      window.notify(i18n.t('pdv.errorUpdate'),'danger');
      return;
    }

    window.API.fichee_pust(data,(r)=>{
      if( StrDef(r.error) ) {
        window.notify(i18n.t('all.recordImpossible'),'danger');
      }
      else {
        // on initialise le state
        this.InitFicheeData();
        if( this.props.embbeded ) {
          this.props.callback(data);
        }
        else {
          this.props.history.goBack();
        }
      }
    });
  }

  EmbbededClose() {
    if( StrDef(this.props.callback) ) this.props.callback();
  }

  InitFicheeData() {
    var fiche_e_data = {  code_calife:'',
                          code_comm_depo:'',
                          code_recette:'',
                          deployment:'',
                          lniata:'',
                          region:sessionStorage.getItem('region')!='ALL'?sessionStorage.getItem('region'):'',
                          resarail:'',
                          uic:'' };
    if( window.synchro.REGION.length > 0 && StrDef(this.props.match.params.fichee) ) {
      // valeur par defaut de la region
      fiche_e_data.region = window.synchro.REGION[0].key;
    }
    // attention en cas de référent : on verrouille sur sa region
    if( sessionStorage.getItem('currentRole') == 'REF' )
      fiche_e_data.region = sessionStorage.getItem('region');
    this.setState({fiche_e_data:fiche_e_data});
  }

  ToggleModalDelete() {
    this.setState({deletePossible:true,pending:false,modalDelete:!this.state.modalDelete});
  }

  CloseModalDelete(bool) {
    this.setState({deletePossible:false});
    this.ToggleModalDelete();
    if( bool ) {
      this.setState({status:'9'},()=>{ this.Save() });
    }
  }

  QueryString(){
    var query = '';
    if( StrDef(this.state.queryRegion) ) query += (StrDef(query)?'|':'')+this.state.queryRegion;
    if( StrDef(this.state.queryResarail) ) query += (StrDef(query)?'|':'')+this.state.queryResarail;
    if( StrDef(this.state.queryDeployment) ) query += (StrDef(query)?'|':'')+this.state.queryDeployment;
    query += '&status=1'; // on ne ^prend pas les fiche E supprimees
    return query;
  };

  FilterChange(data) {
    switch (data.type) {
      case 'regionTag':
        var query = '';
        for(var i=0;i<data.detail.length;i++) {
          if( data.detail[i].checked )
            query += (query==''?'':' ')+data.detail[i].key;
        }
        this.setState({queryRegion:query,queryResarail:''},()=>{
          window.API.fichee_get(this.LoadList,this.QueryString());
        });
        break;
      case 'deploymentTag':
        var query = '';
        for(var i=0;i<data.detail.length;i++) {
          if( data.detail[i].checked )
            query += (query==''?'':' ')+data.detail[i].key;
        }
        this.setState({queryDeployment:query},()=>{
          window.API.fichee_get(this.LoadList,this.QueryString());
        });
        break;
      case 'resarailTag':
        this.setState({queryResarail:StrDef(data.detail)?'S-RR-'+data.detail:''},()=>{
          window.API.fichee_get(this.LoadList,this.QueryString());
        });
        break;
      case 'event':
        if( data.detail == 'filterCancel' ) {
          this.setState({ queryRegion:this.queryRegionDefault,
                          queryDeployment:'' },()=>{
            window.API.fichee_get(this.LoadList,this.QueryString());
          });
        }
        break;
      default:
    }
  }

  OnResarailChange(t) {
    var region = '';
    var reg = sessionStorage.getItem('region');
    if( StrDef(reg) && reg != 'ALL' ) region = reg;

    var self = this;
    if( t.length>2 ){
      window.API.pos_get((r)=>{
        if( !StrDef(r.error) ) {
          var option = [];
          for(var i=0;i<r.length;i++) {
            option.push({ value:r[i].pos_id,
                          label:'('+r[i].pos_data.resarail.toUpperCase()+') '+r[i].pos_data.name });
          }
          self.setState({resarailOption:option});
        }
      },t+'|S-DE-0|'+region);
    }
  }

  // ------------------------------------------ IMPORT XSLX -------------------
  Import() {
    this.props.history.push('/followme/fichee/import');
  }

  RowParse(data) {
    try {
      var errorCount = 0, errorLabel=[];

      var pdv = {pos_data:{}};
      var resarail = StrDef(data[this.parsedIndex['resarail']])?data[this.parsedIndex['resarail']].toUpperCase().trim():'';
      if( resarail == '' ) errorCount++;
      else {
        if( StrDef(this.PdvFromResarail[resarail]) ) { // on a le PDV on recupere les infos
          pdv = this.PdvFromResarail[resarail];
        }
        else {
          errorCount++;
        }
      }

      var fiche_e_data = {  code_calife:data[this.parsedIndex['code_calife']],
                            code_comm_depo:data[this.parsedIndex['code_comm_depo']],
                            code_recette:String(data[this.parsedIndex['code_recette']]),
                            deployment:pdv.pos_data.deployment,
                            lniata:data[this.parsedIndex['lniata']],
                            region:pdv.pos_data.region,
                            resarail:pdv.pos_id,
                            uic:pdv.pos_data.uic };

      if( ! ( fiche_e_data.deployment=='S-DEPLOY-AGENT'
              || fiche_e_data.deployment=='S-DEPLOY-PARTNER'
              || fiche_e_data.deployment=='S-DEPLOY-UNKNOWN' ) ) {
        errorCount++;
        errorLabel.push('Mode de déploiement incorrect');
      }

      var found = false;
      for(var i=0;i<this.state.region.length;i++) {
        if( this.state.region[i].value == fiche_e_data.region ) {
          found = true;
          break;
        }
      }
      if( !found ) errorLabel.push('Région introuvable');

      return {valid:found && errorCount==0,fiche_e_data:fiche_e_data,errorLabel:errorLabel};
    }
    catch(e) {
      console.log( e );
      return {valid:false,fiche_e_data:null,errorLabel:[i18n.t('pdv.errorImport')]};
    }
  }



  XslxHandleFile(e) {
    var self = this;
    var files = e.target.files, f = files[0];
    var reader = new FileReader();

    this.importDuplicate = [];
    this.rowsBuffer = {};

    this.setState({importPending:true,importFinish:false},()=>{
      setTimeout(()=>{

        lottie.loadAnimation({
          container: document.querySelector('#anim'), // the dom element that will contain the animation
          renderer: 'svg',
          loop: true,
          autoplay: true,
          path: LOTTIE_FILE // the path to the animation json
        });

        reader.onload = function(e) {
          var data = new Uint8Array(e.target.result);
          var workbook = XLSX.read(data, {type: 'array'});

          var first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
          self.rowsBuffer = {};
          self.rows = XLSX.utils.sheet_to_json(first_worksheet, {header:1});

          // detection des colones
          var first = self.rows[0], tmp='';
          self.parsedIndex = {};      // objet contenant les indices de colonnes

          var options = {
            shouldSort: true,
            tokenize: true,
            threshold: 0.6,
            location: 0,
            distance: 100,
            maxPatternLength: 32,
            minMatchCharLength: 1,
            keys: ["label"]
          };

          var list = [{"key":"resarail","label":"RESARAIL"},
                      {"key":"code_recette","label":"RECETTE"},
                      {"key":"code_calife","label":"CALIFE"},
                      {"key":"code_comm_depo","label":"COMM_DEPO"},
                      {"key":"lniata","label":"LNIATA"}];
          var fuse = null;
          var result = null;


          for(var i=0;i<first.length;i++) {
            tmp = first[i].toUpperCase();
            tmp = tmp.normalize().replace(/[\u0300-\u036f]/g, "");
            // on fait fuse en cas d'accent bizarre utf8 unicode et compagnie
            fuse = new Fuse(list, options);
            result = fuse.search(tmp);
            if( result.length > 0 ) {
              self.parsedIndex[result[0].key] = i;
            }
            if( tmp.indexOf('RESARAIL')!= -1 ) self.parsedIndex['resarail'] = i;
            if( tmp.indexOf('RECETTE')!= -1 ) self.parsedIndex['code_recette'] = i;
            if( tmp.indexOf('CALIFE')!= -1 ) self.parsedIndex['code_calife'] = i;
            if( tmp.indexOf('COMM_DEPO')!= -1 ) self.parsedIndex['code_comm_depo'] = i;
            if( tmp.indexOf('LNIATA')!= -1 ) self.parsedIndex['lniata'] = i;
          }
          // on controle que la premiere ligne est complete
          var c = 0;
          for(var k in self.parsedIndex) c++;
          if( c != 5 ) {
            window.notify(i18n.t('pdv.errorImportHeader'),'danger');
            self.setState({importPending:false});
            return;
          }

          self.rows.shift();  // on retire la première ligne
          // on controle qu'il n'y est pas de ligne vide
          var tmp = [];
          for(var i=0;i<self.rows.length;i++) {
            if( self.rows[i].length > 0 ) {
              tmp.push(Clone(self.rows[i]));
            }
          }
          self.rows = tmp;
          self.XlsxRecursivePut();
        };

        // on charge les PDV dans un objet indexé par le resarail pour que RowParse trouve rapidement le PDV
        setTimeout(()=>{
          this.PdvFromResarail = {};
          window.API.db.find({pos_id:{$exists:true}},(e,docs)=>{
            for(var i=0;i<docs.length;i++) {
              self.PdvFromResarail[docs[i].pos_data.resarail.toUpperCase()] = docs[i];
            }
            reader.readAsArrayBuffer(f);
          });
        },1000);

      },100);
    });


  }

  async XlsxRecursivePut() {
    if( this.rows.length > 0 ) {
      var r = this.rows.shift();
      var row = this.RowParse(r);
      if( row.valid ) {
        if( StrDef(this.rowsBuffer[row.fiche_e_data.resarail+row.fiche_e_data.code_recette]) ) {
          this.importDuplicate.push( <div key={'dup'+this.importDuplicate.length}>{JSON.stringify(row.fiche_e_data)}</div> );
        }

        this.rowsBuffer[row.fiche_e_data.resarail+row.fiche_e_data.code_recette] = row.fiche_e_data;
        var nb = 0;
        for(var k in this.rowsBuffer) nb++;
        if( nb == POS_PUT_LIMIT ) {
          var tmp = [];
          for(var r in this.rowsBuffer) {
            tmp.push({fiche_e_code:r,fiche_e_data:this.rowsBuffer[r]});
          }
          var res = await window.API.fichee_put_multi(tmp);
          var res = {};
          if( StrDef(res.error) ) {
            window.notify(i18n.t('pdv.errorImport'),'danger');
            return;
          }
          else {
            console.log( 'put 100 : ', res );
          }
          this.rowsBuffer = {};
          // il faut attendre au moins 1000 car mounir ne peut faire q'un PUT par seconde
          setTimeout(()=>this.XlsxRecursivePut(),1000);
        }
        else {
          this.XlsxRecursivePut();
        }
      }
      else {
        var tmp = [], i=0;
        for(var k in row.fiche_e_data) {
          if( i==0 ) tmp.push(<h5 key='ee'>Erreur à cet enregistrement</h5>);
          i++;
          tmp.push( <div key={'ier'+i}>{k+' : '+row.fiche_e_data[k]}</div> );
        }
        tmp.push( <br key='br'/> );
        tmp.push( <h6 key='h6'>Cause{row.errorLabel.length>1?'s':''} :</h6> );
        for(var k in row.errorLabel) {
          i++;
          tmp.push( <p key={'ier'+i}>{row.errorLabel[k]}</p> );
        }
        this.setState({importAbort:true,importError:tmp,importPending:false});
        window.notify(i18n.t('pdv.errorImport'),'danger');
        return;
      }
    }
    else {
      var nb = 0;
      for(var k in this.rowsBuffer) nb++;
      if( nb > 0 ) {
        var tmp = [];
        for(var r in this.rowsBuffer) {
          tmp.push({fiche_e_code:r,fiche_e_data:this.rowsBuffer[r]});
        }
        var res = await window.API.fichee_put_multi(tmp);
        if( StrDef(res.error) ) {
          window.notify(i18n.t('pdv.errorImport'),'danger');
          return;
        }
        else {
          //console.log( 'put 100 : ', res );
        }
        //console.log( 'PUT LAST 100 : ', tmp );
        this.setState({importPending:false,importFinish:true});
      }
      else {
        this.setState({importPending:false,importFinish:true});
      }
    }
  }

  // ----------------------------------------------- EXPORT --------------------
  Export() {
    this.props.history.push('/followme/fichee/export');
  }

  ExportInit() {
    if( this.exporting ) return;
    this.exporting = true;

    this.exportTab = [];
    this.exportTab.push(['REGION','RESARAIL','PDV','UIC','RECETTE','DEPLOIEMENT','CALIFE','COMM_DEPO','LNIATA']);

    if(document.querySelector('#anim')!=null) document.querySelector('#anim').innerHTML = '';
    lottie.loadAnimation({
      container: document.querySelector('#anim'), // the dom element that will contain the animation
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: LOTTIE_FILE // the path to the animation json
    });

    var query = '';
    // timer juste pour voir le lottie
    setTimeout(()=>{
      window.API.fichee_get_stream(query,null,
        (r)=>{
          this.ExportProgress(r);
        },
        (c)=>{
          this.ExportComplete(c);
        })
    },1000);

  }

  ExportProgress(data) {
    if( String(data.status) != '9' ) {
      this.exportTab.push([window.lblREG(data.fiche_e_data.region),
                            window.synchro.RESARAIL.r(data.fiche_e_data.resarail),
                            window.synchro.RESARAIL.n(data.fiche_e_data.resarail),
                            data.fiche_e_data.uic,
                            data.fiche_e_data.code_recette,
                            data.fiche_e_data.deployment=='S-DEPLOY-AGENT'?'Agent':(data.fiche_e_data.deployment=='S-DEPLOY-UNKNOWN'?'inconnu':'Partenaire'),
                            data.fiche_e_data.code_calife,
                            data.fiche_e_data.code_comm_depo,
                            data.fiche_e_data.lniata]);
    }
  }

  ExportComplete(status) {
    this.exporting = false;
		exportXLSX(this.exportTab,'FICHE_E','fiche_e.xlsx');
    this.props.history.goBack();
  }

  render() {

    var rr = window.synchro.RESARAIL.r(this.state.fiche_e_data.resarail);
    var labelResarail = (StrDef(rr)?'('+rr+') ':'')+window.synchro.RESARAIL.n(this.state.fiche_e_data.resarail);

    return (
      <>
        <div className="content">
          { this.state.mode == 'list' && this.state.winW > 991 &&             // BROWSER VERSION
            <>
              <Row>
                <Col md="6" sm="8">
                  <h2>{i18n.t('fichee.title')}</h2>
                </Col>
                <Col md="6" sm="4" className="text-right">
                  <div style={{display:'flex',height:'42px',justifyContent:'flex-end',alignItems:'center'}}>
                    <Button color="primary" style={{paddingLeft:15,paddingRight:15,margin:0,marginTop:'25px',marginRight:10}} onClick={this.Import}>
                      <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                        Importer
                        <Icon name='download' size={24} color={'#FFF'} style={{marginLeft:'10px',display:'flex',transform:'rotate(180deg)'}}/>
                      </div>
                    </Button>
                    <Button color="primary" style={{paddingLeft:15,paddingRight:15,margin:0,marginTop:'25px',marginRight:10}} onClick={this.Export}>
                      <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                        Exporter
                        <Icon name='download' size={24} color={'#FFF'} style={{marginLeft:'10px',display:'flex'}}/>
                      </div>
                    </Button>
                    <Button color="primary" style={{paddingLeft:15,paddingRight:15,margin:0,marginTop:'25px'}} onClick={this.Add}>
                      <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                        Ajouter
                        <Icon name='add' size={24} color={'#FFF'} style={{marginLeft:'10px',display:'flex'}}/>
                      </div>
                    </Button>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="12">
                  <Card>
                    <CardBody>
                      { this.state.list.length == 0 &&
                        <>
                          <b>{i18n.t('all.noResult')}</b>
                        </>
                      }
                      { this.state.list.length > 0 &&
                        <>
                          <Table responsive striped>
                            <thead className="text-primary">
                              <tr>
                                { this.currentRole == 'ADM' &&
                                  <th width="220px">Région</th>
                                }
                                { this.state.prevision &&
                                  <th>Ouverture</th>
                                }
                                <th width="120px">RESARAIL</th>
                                <th>Libellé</th>
                                <th>UIC</th>
                                <th>Recette</th>
                                <th>Déploiement</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody style={{maxHeight:'calc(100vh - 345px)'}}>
                              { this.state.list.map((data,i)=>{

                                  if( !StrDef(data.fiche_e_data) ) return;
                                  if( data.status == 9 ) return;

                                  return(
                                    <tr key={'tr'+i}>
                                      { this.currentRole == 'ADM' &&
                                      <td width="220px">
                                        {window.lblREG(data.fiche_e_data.region)}
                                      </td>
                                      }
                                      <td width="120px">
                                        {window.synchro.RESARAIL.r(data.fiche_e_data.resarail)}
                                      </td>
                                      <td>{window.synchro.RESARAIL.n(data.fiche_e_data.resarail)}</td>
                                      <td>{data.fiche_e_data.uic}</td>
                                      <td>{data.fiche_e_data.code_recette}</td>
                                      <td>{i18n.t('item.'+data.fiche_e_data.deployment)}</td>
                                      <td className="text-right" style={{paddingRight:'20px'}}>
                                        <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-end',alignItems:'center'}}>
                                          <Icon name='edit' size={24} style={{display:'flex',marginRight:'5px'}} onClick={this.RowClick.bind(this,i)}/>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })
                              }
                            </tbody>
                          </Table>
                          <div style={{textAlign:'right'}}>
                            { this.state.list.length>5 &&
                              this.state.list.length+' lignes '
                            }
                            { this.state.hasMore &&
                              <a className='aLink' onClick={this.LoadMore}>(Afficher plus)</a>
                            }
                          </div>
                        </>
                      }
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          }
          { this.state.mode == 'update' && StrDef(this.state.fiche_e_data) &&
            <>

              <Row>
                <Col md="8" xs="12">
                  <h2>{i18n.t('fichee.titleFicheE'+(this.state.insert?'Insert':''))}</h2>
                </Col>
                <Col md="4" className="text-right">
                  <div style={{display:'flex',flexDirection:'row-reverse'}}>
                    <Button color="primary" style={{margin:0,marginTop:'5px',marginLeft:'10px'}} onClick={this.Save}>Enregistrer</Button>
                    { !this.state.insert &&
                      <Button color="danger" className="btn-link"  style={{color:'#A0A0A0',margin:0,marginTop:'5px'}} onClick={this.ToggleModalDelete}>Supprimer</Button>
                    }
                  </div>
                </Col>
              </Row>

              <Row style={{marginTop:10}}>
                <Col md="6" sm="12">
                  <Card>
                    <CardBody>
                      <label>RESARAIL / Libellé</label>
                      <FormGroup>
                        <Select
                          className="react-select primary selectBtn"
                          classNamePrefix="react-select"
                          isClearable={true}
                          value={{value:this.state.fiche_e_data.resarail,
                                  label:labelResarail}}
                          onChange={value => {
                            var fiche_e_data = Clone(this.state.fiche_e_data);
                            if( StrDef(value) ) {
                              fiche_e_data.resarail = value.value;
                              fiche_e_data.uic = window.synchro.RESARAIL.d(value.value).pos_data.uic;
                              fiche_e_data.region = window.synchro.RESARAIL.d(value.value).pos_data.region;
                              fiche_e_data.deployment = window.synchro.RESARAIL.d(value.value).pos_data.deployment;
                            }
                            else {
                              fiche_e_data.resarail = '';
                              fiche_e_data.uic = '';
                              fiche_e_data.region = '';
                              fiche_e_data.deployment = '';
                            }
                            //remplissage auto de code depositaire
                            if( fiche_e_data.deployment == 'S-DEPLOY-PARTNER'
                                && (fiche_e_data.uic.length==8
                                    && fiche_e_data.uic.startsWith('87')
                                    && /^[0-9]+$/.test(fiche_e_data.uic)) ) {
                              fiche_e_data.code_comm_depo = '801'+fiche_e_data.uic.substring(2,8);
                            }

                            if( fiche_e_data.deployment=='S-DEPLOY-AGENT' && fiche_e_data.code_comm_depo=='801'+fiche_e_data.uic ) {
                              fiche_e_data.code_comm_depo = '';
                            }
                            this.setState({fiche_e_data:fiche_e_data});
                          }}
                          onInputChange={this.OnResarailChange}
                          options={this.state.resarailOption}
                          placeholder="RESARAIL"
                        />
                      </FormGroup>
                      { StrDef(this.state.fiche_e_data.uic) &&
                        <>
                          <label>UIC</label>
                          <FormGroup>{this.state.fiche_e_data.uic}</FormGroup>
                        </>
                      }
                      { (this.state.fiche_e_data.deployment=='S-DEPLOY-AGENT'||this.state.fiche_e_data.deployment=='S-DEPLOY-PARTNER') &&
                        <>
                          <label>Déploiement</label>
                          <FormGroup>{i18n.t('item.'+this.state.fiche_e_data.deployment)}</FormGroup>
                        </>
                      }
                      { StrDef(this.state.fiche_e_data.region) &&
                        <>
                          <label>Région</label>
                          <FormGroup>{window.lblREG(this.state.fiche_e_data.region)}</FormGroup>
                        </>
                      }
                    </CardBody>
                  </Card>
                </Col>

                <Col md="6" sm="12">
                  <Card>
                    <CardBody>
                      <label>Code LNIATA</label>
                      <FormGroup>
                        <Input type="text" className={StrDef(this.state.error.lniata)?'error':''} value={this.state.fiche_e_data.lniata} onChange={this.Change.bind(this,'lniata')}/>
                      </FormGroup>
                      <label>Code Recette</label>
                      <FormGroup>
                        <Input type="text" className={StrDef(this.state.error.code_recette)?'error':''} value={this.state.fiche_e_data.code_recette} onChange={this.Change.bind(this,'code_recette')}/>
                      </FormGroup>
                      <label>Code CALIFE</label>
                      <FormGroup>
                        <Input type="text" className={StrDef(this.state.error.code_calife)?'error':''} value={this.state.fiche_e_data.code_calife} onChange={this.Change.bind(this,'code_calife')}/>
                      </FormGroup>
                      <label>
                        { StrDef(this.state.fiche_e_data.deployment) &&
                          'Code '+i18n.t('item.codeCommDepo'+this.state.fiche_e_data.deployment)
                        }
                        { !StrDef(this.state.fiche_e_data.deployment) &&
                          i18n.t('item.codeCommDepoEmpty')
                        }
                      </label>
                      <FormGroup>
                        <Input type="text" className={StrDef(this.state.error.code_comm_depo)?'error':''} value={this.state.fiche_e_data.code_comm_depo} onChange={this.Change.bind(this,'code_comm_depo')}/>
                      </FormGroup>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Modal isOpen={this.state.modalDelete} toggle={this.ToggleModalDelete} backdrop='static'>
                <div className="modal-header justify-content-center">
                  { !this.state.pending && this.state.deletePossible &&
                    <>
                      <h5>{i18n.t('fichee.ModalDeleteTitle')+' :'}</h5>
                      <div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'center'}}>
                        <div style={{display:'flex',flex:1,justifyContent:'flex-end'}}>RESARAIL :</div>
                        <div style={{marginLeft:10,display:'flex',flex:1,fontWeight:'600'}}>{StrDef(this.state.fiche_e_data)?this.state.fiche_e_data.resarail:''}</div>
                      </div>
                      <div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'center'}}>
                        <div style={{display:'flex',flex:1,justifyContent:'flex-end'}}>Code Recette :</div>
                        <div style={{marginLeft:10,display:'flex',flex:1,fontWeight:'600'}}>{this.state.fiche_e_data.code_recette}</div>
                      </div>
                    </>
                  }
                  <Icon name="close" color="#000" size={32} style={{position:'absolute',right:'10px',top:'10px',cursor:'pointer'}} onClick={this.CloseModalDelete.bind(this,false)}/>
                </div>

                <div className="modal-body">
                  <div className="modalRegion">
                    <Row>
                      <Col md="12">
                        { this.state.pending &&
                          <p>{i18n.t('pdv.pending')}</p>
                        }
                      </Col>
                    </Row>
                    { !this.state.pending &&
                      <Row>
                        <Col md="12">
                          <div style={{display:'flex',justifyContent:this.state.deletePossible?'space-between':'center'}}>
                            <Button color="info" style={{margin:0,marginTop:'5px',marginLeft:'10px'}} onClick={this.CloseModalDelete.bind(this,false)}>{this.state.deletePossible?i18n.t('all.cancel'):i18n.t('all.ok')}</Button>
                            { this.state.deletePossible &&
                              <Button color="danger" style={{margin:0,marginTop:'5px'}} onClick={this.CloseModalDelete.bind(this,true)}>Supprimer</Button>
                            }
                          </div>
                        </Col>
                      </Row>
                    }

                  </div>
                </div>
              </Modal>
            </>
          }
          { this.state.mode == 'import' &&
            <>
              { this.state.importAbort && !this.state.importPending &&
                <>
                  {this.state.importError}
                  <Button color="primary" style={{margin:0,marginTop:'25px'}} onClick={()=>{this.setState({importAbort:false,importError:[]})}}>Continuer</Button>
                </>
              }
              { !this.state.importAbort && !this.state.importPending && !this.state.importFinish &&
                <>
                  <Row>
                    <Col md="12">
                      <h2>{i18n.t('fichee.titleImport')}</h2>
                    </Col>
                  </Row>

                  <Card>
                    <CardBody>
                      <p>Format de fichier attendu :</p>
                      <ul>
                        <li>Peu importe l'ordre des colonnes</li>
                        <li>La première ligne contient les entêtes. Les mots clés suivants sont recherchés pour déterminer les colonnes à utiliser :<br/>
                            RESARAIL, RECETTE, CALIFE, COMM_DEPO, LNIATA<br/>
                        </li>
                        <li><b>Peu importe la casse, les accents, l'ordre</b></li>
                        <li>La colonne COMM_DEPO contient le cas échéant, le code commerçant ou dépositaire</li>
                      </ul>
                    </CardBody>
                  </Card>

                  <Card>
                    <CardBody>
                      Les fichiers XLS, XLSX et CSV sont acceptés<br/>
                      Les données du fichier écraseront la base de données<br/>
                      En cas de duplication du RESARAIL+CODE RECETTE c'est la dernière ligne qui est prise en compte<br/>
                      <FormGroup>
                        <Button color="primary">Choisir un fichier</Button>
                        <Input type="file" name="xlfile" id="fileXslx" onChange={this.XslxHandleFile}/>
                      </FormGroup>
                    </CardBody>
                  </Card>

                  <Row>
                    <Col md="12">

                    </Col>
                  </Row>
                </>
              }
              { this.state.importPending &&
                <>
                  <Row>
                    <Col md="12">
                      <h2>{i18n.t('pdv.titleImport')}</h2>
                    </Col>
                  </Row>
                  <div style={{display:'flex',width:'100%',height:'calc(100vh - 200px)',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                    <div id="anim" style={{marginLeft:'auto',marginRight:'auto',width:100}}></div>
                    Import en cours, veuillez patienter...
                  </div>
                </>
              }
              { this.state.importFinish && !this.state.importPending &&
                <>
                  <h2>Fichier importé avec succès</h2>
                  { this.importDuplicate.length > 0 &&
                    <>
                    <p>Mais avec {this.importDuplicate.length} duplicata{this.importDuplicate.length>1?'s':''} : </p>
                    {this.importDuplicate}
                    </>
                  }
                  <Button color="primary" style={{margin:0,marginTop:'25px'}}
                          onClick={()=>{this.setState({importAbort:false,importFinish:false,importError:[]})}}>Continuer</Button>
                </>
              }
            </>
          }
          { this.state.mode == 'export' &&
            <>
              <Row>
                <Col md="12">
                  <h2>{this.state.prevision?i18n.t('pdv.titleExportPrevision'):i18n.t('pdv.titleExport')}</h2>
                </Col>
              </Row>

              <div style={{display:'flex',width:'100%',height:'calc(100vh - 200px)',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                <div id="anim" style={{marginLeft:'auto',marginRight:'auto',width:100}}></div>
                Export en cours, veuillez patienter...
              </div>
            </>
          }
        </div>
      </>
    );
  }
}

export default FicheE;
