import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch } from "react-router-dom";
import AuthNavbar from "components/Navbars/AuthNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";
import {Button,
        ButtonGroup,
        Modal,
        ModalHeader,
        ModalBody,
        ModalFooter,
        Card,
        CardHeader,
        CardBody,
        CardFooter,
        CardTitle,
        Label,
        FormGroup,
        Form,
        Input,
        Row,
        Col,
        Container,
        InputGroup,
        InputGroupAddon,
        InputGroupText,
        } from 'reactstrap';
import routes from "routes.js";
import {StrDef,Clone} from '../../assets/lib/utils';
import i18n from "../../assets/lib/i18n";
import Icon from '../../components/Icons/Icon.jsx';
import {sharedAPI} from "../../assets/lib/api";
import NotificationAlert from "../../components/Notification/Notification.jsx";
import logo from "../../assets/img/logo.png";
var moment = require('moment');

var ps;

class Pages extends React.Component {
  constructor(props) {
    super(props);

    this.Title = this.Title.bind(this);

    this.Login = this.Login.bind(this);
    this.NotifClose = this.NotifClose.bind(this);
    this.notify = this.notify.bind(this);
    this.ChangePassword = this.ChangePassword.bind(this);

    this.ComeFrom = '';

    this.state = {
      // login form
      login: "",
      password: "",
      newPassword1: "",
      newPassword2: "",
      trickLogin:"",
      trickPassword:"",
      trickRegion:"",
      trickRole:"",
    };

    if( window.location.hostname.indexOf('localhost')!=-1
        || window.location.hostname.indexOf('192.168.')!=-1 ) {
      this.state.login = '1111111R';//cccccccc
      this.state.password = '12345678';//cccccccc
    }

    window.notify = this.notify;
  }

  notify(msg,type,autoDismiss) {
    var options = { message: msg, type: type };
    if( StrDef(autoDismiss) ) options['autoDismiss'] = autoDismiss;
    return this.refs.notificationAlert.notificationAlert(options);
  }

  verifyEmail = value => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };

  componentDidMount() {
    // if (navigator.platform.indexOf("Win") > -1) {
    //   ps = new PerfectScrollbar(this.refs.fullPages);
    // }

    // on regarde si on ne vient pas de se faire "logouter", par consequent il faut rediriger vers d'ou on vient apres le login
    if( StrDef(this.props.location) && StrDef(this.props.location.state) && StrDef(this.props.location.state.from) ) {
      this.ComeFrom = this.props.location.state.from.pathname+this.props.location.state.from.search;
      this.setState({modalLogin:true});
    }
    else {
      this.ComeFrom = '';
    }
  }

  componentWillUnmount() {
    // if (navigator.platform.indexOf("Win") > -1) {
    //   ps.destroy();
    // }
  }

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/home") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  Title() {
    var title = i18n.t('signup.connect');
    if( StrDef(this.ComeFrom) ) {
      title = i18n.t('login.autoLogout');
    }
    if( this.state.formStep == -2 ) title = i18n.t('signup.forgotPassTitle');
    return title;
  }

  Change(what,t) {
    var tmp = {};
    tmp[what] = t.target.value;
    this.setState(tmp);
  }

  Login(e) {
    e.preventDefault();

    if( StrDef(this.state.login) && StrDef(this.state.password) ) {
      window.API.signin(this.state.login,this.state.password,async (r)=>{
        if( StrDef(r.error) ) {
          if( r.error.code == 'TooManyRequestsException' ) {
            window.notify(i18n.t('settings.errorChangePwdTooMany'),'danger');
          }
          else {
            window.notify(i18n.t('login.errorAuth'),'danger');
          }
        }
        else {
          sessionStorage.setItem('isAuth','1');
          var res = await window.API.user_get();
          var user = Clone(res.user_data);
          var role = [], region = [];
          if( StrDef(user.role) ) role = user.role.split(' ');
          if( StrDef(user.region) ) region = user.region;
          sessionStorage.setItem('role',JSON.stringify(role));
          // si +ieurs role on se met en REF
          sessionStorage.setItem('region',region);
          sessionStorage.setItem('currentRole',role[0]);

          // on charge les datas type emplacement etc.....
          //window.API.Synchro();

          if( !StrDef(user.pass_change) ) {
            this.notifRes = window.notify(i18n.t('login.passwordChangeNeeded'),'danger',10);
            this.setState({NeedChangePassword:true});
          }
          else {
            if( StrDef(this.ComeFrom) ) {
              this.props.history.push(this.ComeFrom);
            }
            else {
              this.props.history.push('/followme/dashboard');
            }
          }
        }
      });
    }
    else {
      window.notify(i18n.t('login.errorField'),'danger');
    }
  }

  ChangePassword() {
    if( this.state.newPassword1 != this.state.newPassword2 ) {
      window.notify(i18n.t('login.errorSameField'),'danger');
    }
    else if( !(StrDef(this.state.newPassword1) && StrDef(this.state.newPassword2)) ) {
      window.notify(i18n.t('login.errorEmptyField'),'danger');
    }
    else {
      window.API.change_password_auth(this.state.password,this.state.newPassword2,(r)=>{
        if( StrDef(r.error) ) {
          window.notify(i18n.t('all.error'),'danger');
        }
        else {
                                                                    // pour dire au prochain ecran de mettre une notif
          //this.props.history.push({pathname:'/followme/dashboard', state:{passwordChanged: true}});
          window.API.user_get((r)=>{
            if( StrDef(r.user_data) ) {
              r.user_data.pass_change = moment().format('YYYY-MM-DD HH:mm:ss');
              window.API.user_put(r.user_data);
            }
          });
          this.setState({NeedChangePassword:false,
                          password:'',
                          newPassword1:'',
                          newPassword2:'',
                          login:''});
          window.notify(i18n.t('login.passwordChanged'),'success');
        }
      });

    }
  }

  NotifClose() {
    this.refs.notificationAlert.onDismiss(this.notifRes.nNumber,this.notifRes.place);
  }

  LoginProfile(profile) {
    sessionStorage.setItem('currentRole',profile);
    this.props.history.push('/followme/'+(profile=='ADM'?'dashboard':'stock'));
  }

  CreateUser() {
    window.API.CreateUser(this.state.trickLogin,this.state.trickPassword,this.state.trickRegion,this.state.trickRole,(r)=>{
      if( StrDef(r.error) ) {
        alert( r.error )
        console.log( r );
      }
      else {
        window.notify('Compte créé !!!!!','success');
      }
    });
  }

  LogoTrick() {
    clearTimeout(this.timer);
    if( !StrDef(this.trick) ) this.trick = 1;
    else this.trick++;

    if( this.trick == 5 ) {
      this.setState({trick:true});
    }
    this.timer = setTimeout(()=>{this.trick=0},1000);
  }

  render() {
    let {
      loginEmailState,
      loginPasswordState,
      forgotPassCodeState,
    } = this.state;

    return (
      <>
        <div className='mobileBg'></div>
        <div className='mobileGradient'>
          <center>
            <img src={logo} style={{marginTop:30,width:'30vw',objectFit:'contain'}}/>
          </center>
          <div style={{position:'absolute',bottom:20,width:'100vw',textAlign:'center'}}>
            <font style={{color:'white',fontWeight:'700',fontSize:'1.1rem'}}>FOLLOW ME</font><br/>
            <font style={{color:'rgba(200,200,200,0.7)'}}>O°code secure</font>
          </div>
        </div>
        <div className="loginBg">
          <NotificationAlert ref="notificationAlert"/>
            { !this.state.chooseProfile &&
              <div className="loginModal">
                <div className="left">
                  <div className="panel">
                    <span style={{fontSize:'0.8rem',lineHeight:'2rem'}}>Interface de gestion de parc</span>
                  </div>
                  <div className="panel" style={{flexDirection:'column'}}>
                    <img src={logo} style={{width:'140px',objectFit:'contain'}} onClick={this.LogoTrick.bind(this)}/>
                    <span style={{fontSize:'1.2rem',fontWeight:'500'}}>FOLLOW ME</span><br/>
                  </div>
                  <div className="panel">
                    <span style={{fontSize:'0.8rem',color:'#DDD',lineHeight:'2rem'}}>O°code Secure</span>
                  </div>
                </div>
                <div className="right">
                  { !this.state.NeedChangePassword &&
                    <div style={{width:'60%'}}>
                      <div style={{color:'white'}}>
                        <span style={{fontSize:'1.5rem',fontWeight:'500'}}>Connectez-vous</span><br/>
                        <span style={{fontSize:'1rem'}}>pour accéder au tableau de bord</span>
                      </div>
                      <div style={{marginTop:'30px'}}>
                        <Input
                          placeholder={i18n.t('login.login')}
                          type="text"
                          value={this.state.login}
                          style={{paddingLeft:'77px'}}
                          onChange={this.Change.bind(this,'login')}/>
                          <div style={{position:'absolute',marginTop:'-44px',width:'60px',height:'44px',display:'flex',justifyContent:'center',alignItems:'center'}}>
                            <Icon name="avatar" color="#333" size={33}/>
                            <div style={{position:'absolute',marginLeft:'31px',width:'1px',height:'28px',backgroundColor:'#CCC'}}></div>
                          </div>
                      </div>
                      <div style={{marginTop:'20px'}}>
                        <Input
                          placeholder={i18n.t('login.password')}
                          type="password"
                          autoComplete="off"
                          value={this.state.password}
                          style={{paddingLeft:'77px'}}
                          onChange={this.Change.bind(this,'password')}/>
                          <div style={{position:'absolute',marginTop:'-44px',width:'60px',height:'44px',display:'flex',justifyContent:'center',alignItems:'center'}}>
                            <Icon name="password" color="#333" size={33}/>
                            <div style={{position:'absolute',marginLeft:'31px',width:'1px',height:'28px',backgroundColor:'#CCC'}}></div>
                          </div>
                      </div>

                      <Button color="info" size="large" style={{width:'100%',margin:0,marginTop:'30px'}} onClick={this.Login}>
                        {i18n.t('login.loginBtn')}
                      </Button>
                    </div>
                  }
                  { this.state.NeedChangePassword &&
                    <div style={{width:'60%'}}>
                      <div style={{color:'white'}}>
                        <span style={{fontSize:'1.5rem',fontWeight:'500'}}>Nouveau mot de passe</span><br/>
                        <span style={{fontSize:'1rem'}}>Vous devez changer votre mot de passe</span>
                      </div>
                      <div style={{marginTop:'20px'}}>
                        <Input
                          placeholder={i18n.t('login.password')}
                          type="password"
                          autoComplete="off"
                          onFocus={this.NotifClose}
                          value={this.state.newPassword1}
                          style={{paddingLeft:'77px'}}
                          onChange={this.Change.bind(this,'newPassword1')}/>
                          <div style={{position:'absolute',marginTop:'-44px',width:'60px',height:'44px',display:'flex',justifyContent:'center',alignItems:'center'}}>
                            <Icon name="password" color="#333" size={33}/>
                            <div style={{position:'absolute',marginLeft:'31px',width:'1px',height:'28px',backgroundColor:'#CCC'}}></div>
                          </div>
                      </div>
                      <div style={{marginTop:'20px'}}>
                        <Input
                          placeholder={i18n.t('login.repassword')}
                          type="password"
                          autoComplete="off"
                          onFocus={this.NotifClose}
                          value={this.state.newPassword2}
                          style={{paddingLeft:'77px'}}
                          onChange={this.Change.bind(this,'newPassword2')}/>
                          <div style={{position:'absolute',marginTop:'-44px',width:'60px',height:'44px',display:'flex',justifyContent:'center',alignItems:'center'}}>
                            <Icon name="password" color="#333" size={33}/>
                            <div style={{position:'absolute',marginLeft:'31px',width:'1px',height:'28px',backgroundColor:'#CCC'}}></div>
                          </div>
                      </div>

                      <Button color="info" size="large" style={{width:'100%',margin:0,marginTop:'30px'}} onClick={this.ChangePassword}>
                        {i18n.t('login.loginBtn')}
                      </Button>
                    </div>
                  }
                </div>
              </div>
            }
            { this.state.chooseProfile &&
              <Row>
                <Col className="ml-auto mr-auto" lg="6" md="6">
                  <Card className="card-login">
                    <CardHeader>
                      <CardHeader>
                        <h3 className="header text-center">{i18n.t('login.profileTitle')}</h3>
                      </CardHeader>
                    </CardHeader>
                    <CardBody>
                      <center>
                        <ButtonGroup>
                          { this.state.profiles.map((data,i)=>{
                              return(
                                <Button key={'p'+i} className="btn-round" color="primary" size="large" outline onClick={this.LoginProfile.bind(this,data)}>
                                  {data}
                                </Button>
                              );
                            })
                          }
                        </ButtonGroup>
                      </center>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            }
            { this.state.trick &&
              <div style={{position:'absolute',zIndex:99999,width:'100vw',height:'100vh',background:'linear-gradient(135deg, #0088CE 0%, #A1006B 100%)'}}>
                <Row>
                  <Col md="3"></Col>
                  <Col md="6">
                    <br/><br/><br/>
                    <h2 style={{color:'white'}}>ATTENTION : Création de compte {window.PREPROD?'EN PREPROD SNCF':''}{window.DEV?'EN DEV':''}</h2>
                    <Input
                      placeholder="login"
                      type="text"
                      value={this.state.trickLogin}
                      onChange={this.Change.bind(this,'trickLogin')}/>
                    <Input
                      placeholder="password"
                      type="text"
                      value={this.state.trickPassword}
                      onChange={this.Change.bind(this,'trickPassword')}/>
                    <Input
                      placeholder="region"
                      type="text"
                      value={this.state.trickRegion}
                      onChange={this.Change.bind(this,'trickRegion')}/>
                    <Input
                      placeholder="role"
                      type="text"
                      value={this.state.trickRole}
                      onChange={this.Change.bind(this,'trickRole')}/>
                    <Button color="info" size="large" style={{width:'100%',margin:0,marginTop:'30px'}} onClick={this.CreateUser.bind(this)}>
                      Créer l'utilisateur
                    </Button>
                    <br/><br/><br/><br/>
                    <Button color="info" size="large" style={{width:'100%',margin:0,marginTop:'30px'}} onClick={()=>document.location.reload()}>
                      Quitter
                    </Button>
                  </Col>
                  <Col md="3"></Col>
                </Row>
              </div>
            }
        </div>
      </>
    );
  }
}

export default Pages;
