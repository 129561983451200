import {getLocale,preimageSha256Condition} from './utils';
import axios from 'axios';
import {StrDef,Clone,Capitalize,SortBy,GetJSON} from './utils';
import i18n from "./i18n";
var jwtDecode = require('jwt-decode');
var ndjson = require('ndjson');
var moment = require('moment');
var stablejsonstringify = require('json-stable-stringify');
var sha3hash = require('js-sha3');
var uuid = require('uuid/v4');
var _ = require('underscore');
var jsonpipe = require('jsonpipe');
var Datastore = require('nedb');

let _api = null;
const DB_PREFIX = {code_calife:"S-CA-",
                    code_comm_depo:"S-CD-",
                    code_recette: "S-RE-",
                    compta_completed: "S-CC-",
                    current_owner: "S-C0-",
                    emplacement_id: "S-OE-",
                    imei: "S-SN-",
                    lniata: "S-LN-",
                    pdv_completed: "S-PC-",
                    reference: "S-RF-",
                    resarail: "S-RR-",
                    responsable_code_cp: "S-CP-",
                    responsable_completed: "S-RC-",
                    is_completed: "S-IC-",
                    role:"S-RO-",
                    uuid:"S-ID-",
                    deleted:"S-DE-",
                    comment:"S-CT-",
                    uic: "S-UI-",
                    pin_code: "S-PI-"};

var SNCFAPI = 'sncfapi';

export function getHashFromJson(json_data) {
  json_data = stablejsonstringify(json_data, (a, b) => (a.key > b.key ? 1 : -1))
  return sha3hash.sha3_256.create().update(json_data).hex()
}

export default class Api {
  constructor() {

    // NEDB : base de données document avec les regions, pdv etc....
    this.db = new Datastore({ filename: 'followme', autoload: true });
    this.db.ensureIndex({fieldName:'reg_id',unique:true,sparse:true},()=>{});
    this.db.ensureIndex({fieldName:'pos_id',unique:true,sparse:true},()=>{});

    var url = '/';

    if( window.location.hostname.indexOf('localhost')!=-1
        || window.location.hostname.indexOf('192.168.')!=-1 ) {
      url = 'https://dev.ocode.team';
      SNCFAPI = 'sncfapi';
      window.PREPROD = true;
    }

    if( window.location.hostname.indexOf('wwwdev')!=-1 ) {
      url = 'https://dev.ocode.team';
      SNCFAPI = 'sncfapitest';
      window.TEST = true;
    }

    this.api = axios.create({
      baseURL: url,
      timeout: 10000
    });

    this.API_BASE_URL = url;
    this.API_TIMEOUT_GET = 30000;

    if(_api != null) {
        throw 'There is already an instance of api alive';
    }

    if( sessionStorage.getItem('user') !== null ) {
      var user = JSON.parse(sessionStorage.getItem('user'));
      this.accessToken = user.accessToken;
      this.refreshToken = user.refreshToken;
      this.userId = user.userId;
    }

    _api = this;
    //this.token = '';
  }

  isTokenExpired(token) {
    var decoded = null;
    try {
      decoded = jwtDecode(token);
    }
    catch(e) {
      console.log(">>>> isTokenExpired",e);
      return true;
    }
    return decoded.exp < parseInt(moment.utc().valueOf()/1000);
  }

  async get_new_accessToken(force) {
    var res = null;
    // si la version n'est déjà pas bonne ne pas faire cette opération
    var user = JSON.parse(sessionStorage.getItem('user'));
    var refreshToken = user.refreshToken;
    if( this.isTokenExpired(refreshToken) && !Defined(force) ) {
      var event = new CustomEvent('logout', {detail:''});
      window.dispatchEvent(event);
      return '';
    }
    else {
      try {
        res = await this.api.get('authenticationapi/signin',{ headers: {'accept-version': '1.0.0',
                                                                        /*'Content-Encoding': 'gzip',*/
                                                                        'Content-Type': 'application/json',
                                                                        'Authorization': 'Bearer '+refreshToken} })
      }
      catch(e) {
        var event = new CustomEvent('logout', {detail:''});
        window.dispatchEvent(event);
      }

      if( Defined(res) && Defined(res.data) && Defined(res.data.access_token) ) {
        user = JSON.parse(sessionStorage.getItem('user'));
        user.accessToken = res.data.access_token;
        console.log( 'get_new_accessToken = ', user );
        sessionStorage.setItem('user',JSON.stringify(user));
        return res.data.access_token;
      }
      else {
        return '';
      }
    }
  }

  async GetAccessToken() {
    var user = JSON.parse(sessionStorage.getItem('user'));
    var accessToken = user.accessToken;

    if( this.isTokenExpired(accessToken) ) {
      console.log("isTokenExpiredisTokenExpiredisTokenExpired");
      return await this.get_new_accessToken();
    }
    else {
      return accessToken;
    }
  }

  async GET(query,config) {
    //var self = this;
    var conf = config;
    if( typeof(config) == 'undefined' ) {
      var accessToken = await this.GetAccessToken();
      //if( accessToken == '' ) return null;
      if( accessToken === '' ) return ({error:true});
      conf = { headers: {'Authorization': 'Bearer '+accessToken,
                          'accept-version': '1.0.0',
                          'Content-Encoding': 'gzip',
                          'Content-Type': 'application/json',
                          },
                          timeout: this.API_TIMEOUT_GET };
    }
    else {
      conf = { headers: { 'accept-version': '1.0.0',
                          'Content-Encoding': 'gzip',
                          'Content-Type': 'application/json',
                          },
                          timeout: this.API_TIMEOUT_GET };
    }
    if( Defined(config) && Defined(config.headers) && Defined(config.headers.Authorization) ) {
      conf.headers['Authorization'] = config.headers.Authorization;
    }
    // if( Defined(config) && Defined(config.baseURL) ) {
    //   conf.baseURL = config.baseURL;
    // }
    return this.api.get(query,conf)
        .then(function (response) {
          if( response.status === 200 ) {
            return response.data;
          }
          else {
            return ({error:''});
          }
        })
        .catch(async function (error) {
          console.log( error );
          //return null;
          /*var event = new Event('logout');
          document.dispatchEvent(event);*/
          return ({error:error});
        });
  }

  async GETSTREAM(query,progress,complete) {
    var accessToken = await this.GetAccessToken();

    var url = this.API_BASE_URL;
    if( url == '/' ) {
      url = 'https://'+window.location.hostname;
    }
    jsonpipe.flow(url+query, {
    	delimiter: "\n",
      success: function(data) {
        if( StrDef(progress) ) progress(data);
      },
      error: function(errorMsg) {
        console.log( "errorMsg ", errorMsg );
      },
      complete: function(statusText) {
        if( StrDef(complete) ) complete(statusText);
      },
      timeout: 3000000,
      method: "GET",
      headers: {"Authorization": 'Bearer '+accessToken},
      data: "",
      disableContentType: false,
      withCredentials: false
    });
  }

  async POST(query,data,config) {
    var self = this;
    var conf = config;
    if( typeof(config) == 'undefined' ) {
      var accessToken = await this.GetAccessToken();
      //if( accessToken == '' ) return null;
      if( accessToken === '' ) return ({error:true});
      conf = { headers: {'Authorization': 'Bearer '+accessToken,
                          'accept-version': '1.0.0',
                          /*'Content-Encoding': 'gzip',*/
                          'Content-Type': 'application/json',
                          },
                          timeout: this.API_TIMEOUT_POST };
    }
    else {
      conf = { headers: { 'accept-version': '1.0.0',
                          /*'Accept-Encoding': 'gzip',
                          'Content-Encoding': 'gzip',*/
                          'Content-Type': 'application/json',
                          },
                          timeout: this.API_TIMEOUT_POST };
      if( Defined(config) && Defined(config.headers) && Defined(config.headers.Authorization) ) {
        conf.headers['Authorization'] = config.headers.Authorization;
      }
    }
    return this.api.post(query, data, conf)
      .then(function (response) {
        if( response.status === 200 ) {
          return(response.data);
        }
        else {
          return {error:''};
        }
      })
      .catch(async function (error) {
        if (error.response) {
          if( error.response.status === 400 ) {
            if( error.response.data.code === 'NotAuthorizedException'
                && query !== 'authenticationapi/signin'
                && query !== 'authenticationapi/signin_ckeck'
                && query !== 'authenticationapi/register_device'
                && query !== 'authenticationapi/forgotpassword') {
              var newToken = await self.get_new_accessToken(true);
              if( newToken !== '' ) {
                return await self.POST(query,data,config);
              } else {
                return ({error:true});
              }
            }
            else {
              return {error:error.response.data};
            }
          }
          return( {error:error.response.data} );
        } else {

        }
        return {error:'unknown'};
      });
  }

  async PUT(query,data,config) {
    var self = this;
    var conf = config;
    if( typeof(config) == 'undefined' ) {
      var accessToken = await this.GetAccessToken();
      //if( accessToken == '' ) return null;
      if( accessToken === '' ) return ({error:true});
      conf = { headers: {'Authorization': 'Bearer '+accessToken,
                          'accept-version': '1.0.0',
                          /*'Content-Encoding': 'gzip',*/
                          'Content-Type': 'application/json',
                          },
                          timeout: this.API_TIMEOUT_POST };
    }
    else {
      conf = { headers: { 'accept-version': '1.0.0',
                          /*'Content-Encoding': 'gzip',*/
                          'Content-Type': 'application/json',
                          },
                          timeout: this.API_TIMEOUT_GET };
    }
    if( Defined(config) && Defined(config.headers) && Defined(config.headers.Authorization) ) {
      conf.headers['Authorization'] = config.headers.Authorization;
    }

    return this.api.put(query, data, conf)
      .then(function (response) {
        if( response.status === 200 ) {
          return(response.data);
        }
        else {
          return {error:''};
        }
      })
      .catch(async function (error) {
        if (error.response) {
          if( error.response.status === 400 ) {
            if( error.response.data.code === 'NotAuthorizedException' && query !== 'authenticationapi/password' && query !== 'authenticationapi/kpis' ) {
              var newToken = await self.get_new_accessToken(true);
              if( newToken !== '' ) {
                return await self.PUT(query,data,config);
              } else {
                console.log("logoutlogoutlogoutlogoutlogoutlogoutlogout");
                /*var event = new Event('logout');
                document.dispatchEvent(event);*/
                return ({error:true});
              }
            }
          }
          return( {error:error.response.data.code} );
        } else {
        }
        return {error:''};
      });
  }

  async DELETE(query,config) {
    var self = this;
    var conf = config;
    if( typeof(config) == 'undefined' ) {
      var accessToken = await this.GetAccessToken();
      if( accessToken === '' ) return null;
      conf = { headers: {'Authorization': 'Bearer '+accessToken,
                          'accept-version': '1.0.0',
                          'Content-Encoding': 'gzip',
                          'Content-Type': 'application/json',
                          },
                          timeout: this.API_TIMEOUT_POST };
    }
    else {
      conf = { headers: { 'accept-version': '1.0.0',
                          'Content-Encoding': 'gzip',
                          'Content-Type': 'application/json',
                          },
                          timeout: this.API_TIMEOUT_GET };
    }

    return this.api.delete(query,conf)
        .then(function (response) {
          if( response.status === 200 ) {
            return response.data;
          }
          return {error:response.status};
        })
        .catch(async function (error) {
          if( error.response.status === 400 ) {
            if( error.response.data.code === 'NotAuthorizedException' ) {
              var newToken = await self.get_new_accessToken(true);
              if( newToken !== '' ) {
                return await self.DELETE(query,config);
              }else {
                console.log("logoutlogoutlogoutlogoutlogoutlogoutlogout");
                /*var event = new Event('logout');
                document.dispatchEvent(event);*/
                return ({error:true});
              }
            }
          }
          return {error:error.response.status};
        });
  }

  //------------------------------------------------ API CALLS
  async init_register_device(callback,pass) {
    this.token = uuid();

    var data = await this.POST('authenticationapi/init_register_device',
      {
      app: "FollowMeBackEnd",
      device_token: this.token,
      device_platform: "browser"
      },
      {});

    if( Defined(data) && Defined(data.error) ) {
      console.log( data.error );
      return data.error;
    }
    else {
      console.log( 'data.register_token = ', data.register_token );
      this.register_device(data.register_token,callback,pass);
      return true;
    }
  }

  async register_device(registerToken,callback,pass) {
    var password = '12345678';
    if( StrDef(pass) ) password = pass;

    var res = await this.POST('authenticationapi/register_device',
      {
      country_id: 'fr',
      locale: 'fr',
      password: password
      },
      { headers: {'Authorization': 'Bearer '+registerToken} });


      console.log( 'res >>>>', res );

    if( Defined(res.access_token) ) {
      this.accessToken  = res.access_token;
      this.refreshToken = res.refresh_token;
      this.userId       = res.user_id;
      sessionStorage.setItem('user',JSON.stringify({accessToken:this.accessToken,
                                                    refreshToken:this.refreshToken,
                                                    userId:this.userId}));
      callback({});
    }
    else {
      callback({error:'register_device'});
    }
  }

  async login_set(login,name,type,callback) {  // type : EMAIL ou LOGIN
    var res = await this.PUT('authenticationapi/login',
      {
      login_gender: type,
      login: login,
      description: { name: name }
      });
    callback(res);
  }

  async signin(login,password,callback) {
    var apiObj = {
      "app": "SafeThings",
      "device_token": "token",
      "device_platform": "browser",
      "code_gender": "LOGIN",
      "code_id": login,
      "password": password
    };

    var data = await this.POST( 'authenticationapi/signin', apiObj, {} );

    if( Defined(data.access_token) ) {
      this.accessToken = data.access_token;
      this.refreshToken = data.refresh_token;
      this.userId = data.user_id;
      sessionStorage.setItem('user',JSON.stringify({accessToken:this.accessToken,
                                                    refreshToken:this.refreshToken,
                                                    userId:this.userId}));
    }

    callback( data );
  }

  //================================================== ENTITY ==================
  async entity_get(entity_id,callback) {
    var res = await this.GET('safethingsapi/entity/'+entity_id);
    callback(res);
  }

  async entity_put(data,callback) {
    for(var d in data) {
      if( data[d] === null ) data[d] = '';
    }

    var entity_id = '';
    if( StrDef(data.entity_id) ) {
      entity_id = JSON.parse(JSON.stringify(data.entity_id));
      delete(data.entity_id);
    }

    var res = await this.GET('safethingsapi/entity'+(StrDef(entity_id)?'/'+entity_id:''));
    if( Defined(res.error) ) {
      // on essaie de faire un post
      var obj = {
        entity_id: this.userId,
        gender: 11,
        short_description: "agent",
        owner_condition: preimageSha256Condition(''),
        long_description: data,
        other_conditions: null,
        local_data: null,
        extra: null,
        status: 0,
        encrypted_data: null,
        last_row_hash: null,
      }
      obj.row_hash = getHashFromJson(obj);
      obj.owner_condition_fulfillment = 'oAKAAA';  // fulfillment correspondant a preimage de password = ''
      console.log( 'ENTITY POST : ', obj );
      res = await this.POST('safethingsapi/entity',obj);
      console.log( res );
      callback(res);
    }
    else {
      var entity = res;
      var oldStatus = entity.status;

      delete(entity.create_date);
      delete(entity.modify_date);
      delete(entity.ochain_data);

      entity.long_description = data;

      var oldHash = JSON.parse(JSON.stringify(entity.row_hash));
      delete(entity.row_hash);
      if( oldStatus == 1 ) {
        entity.last_row_hash = oldHash;
      }

      if( StrDef(data.status) ) {
        entity.status = data.status;
      }

      var entity_row_version = entity.row_version;
      delete(entity.row_version);

      var newHash = getHashFromJson(entity);

      var obj = {
        long_description: data,
        row_hash: newHash,
        owner_condition_fulfillment: "oAKAAA",
        entity_row_version: entity_row_version,
      }
      if( oldStatus == 1 ) {
        obj.last_row_hash = oldHash;
      }
      else {
        obj.last_row_hash = entity.last_row_hash;
      }
      if( StrDef(data.status) ) {
        obj.status = data.status;
        if( data.status == 1 && oldStatus == 0 ) {
          delete(obj.owner_condition_fulfillment);          // PAS BIEN ATTENTION QUAND ON AURA LA BLOCK CHAIN CA VA PAS PASSER !!!!!!!!
          obj.trust_condition_fulfillment = "oAKAAA";
        }
      }

      console.log( 'get entity = ', obj, newHash, oldHash );

      var res = await this.PUT('safethingsapi/entity/'+(StrDef(entity_id)?entity_id:this.userId),obj);
      callback(res);

    }
  }

  async get_entities(status,callback) {
    var res = await this.GET('safethingsapi/entities?status='+status);
    callback(res);
  }


  //====================================================== AGENT ===============
  async register_by_email(data,callback) {
    var obj = {
              "app": "OVineBackEnd",
              "email": data.mail,
              "country_id": getLocale(2),
              "locale": getLocale(),
              "password": data.password,
              "device_token": moment().format('YYYYMMDDHHmmssSSS'),
              "device_platform": "browser"
            };
    var res = await this.POST('authenticationapi/register_by_email',obj,{});
    callback(res);
  }

  async activate_account(data,callback) {
    var res = await this.GET('authenticationapi/activate_account?email='+data.mail+'&code='+data.code,{});
    callback(res);
  }

  async forgot_password(data,callback) {
    var res = await this.GET('authenticationapi/initpasswordbyemail?app=OVineBackEnd&locale='+getLocale()+'&email='+data.mail,{});
    callback(res);
  }

  async change_password(data,callback) {
    var obj = {
    	code_gender: "LOGIN",
      code_id: data.mail,
      newpassword: data.password,
      recovery_password: data.code
    };
    var res = await this.POST('authenticationapi/forgotpassword',obj,{});
    callback(res);
  }

  async change_password_auth(p1,p2,callback) {
    var obj = {
    	password: p1,
      newpassword: p2,
    };
    var res = await this.PUT('authenticationapi/password',obj);
    callback(res);
  }

  //============================================ GROUP KEY VALUE ===============
  async kv_group_post(data,callback) {
    var entity_id = this.userId;
    if( StrDef(data.entity_id) ) entity_id = data.entity_id;
    var obj = {
      parameter_group: data.group,
    	parameter_key: data.key,
    	parameter_value: data.value
    };
    var res = await this.POST('safethingsapi/entity/'+entity_id+'/parameter',obj);
    callback(res);
  }

  async kv_group_get(data,callback) {
    var entity_id = this.userId;
    if( StrDef(data.entity_id) ) entity_id = data.entity_id;
    var options = '';
    if( StrDef(data.group) ) options += 'parameter_group='+data.group;
    if( StrDef(data.key) ) options += '&parameter_key='+data.key;
    if( StrDef(data.modify_date_before) ) options += '&modify_date_before='+data.modify_date_before;
    var res = await this.GET('safethingsapi/entity/'+entity_id+'/parameters'+(options==''?'':'/?'+options));
    callback(res);
  }

  async kv_group_put(data,callback) {
    var entity_id = this.userId;
    if( StrDef(data.entity_id) ) entity_id = data.entity_id;
    var obj = {
      parameter_group: data.group,
    	parameter_key: data.key,
    	parameter_value: data.value
    };
    var res = await this.PUT('safethingsapi/entity/'+entity_id+'/parameter',obj);
    callback(res);
  }

  async kv_group_del(data,callback) {
    var entity_id = this.userId;
    if( StrDef(data.entity_id) ) entity_id = data.entity_id;
    var options = '';
    if( StrDef(data.group) ) options += 'parameter_group='+data.group;
    if( StrDef(data.key) ) options += '&parameter_key='+data.key;
    var res = await this.DELETE('safethingsapi/entity/'+entity_id+'/parameters'+(options==''?'':'/?'+options));
    callback(res);
  }

  //======================================================= USER ===============
  async CreateUser(login,password,region,role,callback) {
    this.init_register_device((r)=>{
      this.login_set(login,login,'LOGIN',async (r)=>{
        var obj = {
          user_data:{
            region:region,
            role:role,
          }
        }
        var r = await window.API.PUT('/'+SNCFAPI+'/user/'+r.user_id,obj);
        callback(r);
      });
    },password);
  }

  async user_get(callback) {
    var res = await this.GET('/'+SNCFAPI+'/user/'+this.userId);
    if( StrDef(callback) ) callback(res);
    else return res;
  }

  async user_put(data,callback) {
    var obj = { user_data:data };
    var res = await this.PUT('/'+SNCFAPI+'/user/'+this.userId,obj);
    if( StrDef(callback) ) callback(res);
    else return res;
  }

  //======================================================= OCODE ==============
  PrefixTrim(res) {
    if( StrDef(res) ) {
      var prefix = [];
      for(var p in DB_PREFIX) {
        prefix.push(DB_PREFIX[p]);
      }
      // traitement de res pour enlever les prefix :
      var tmp = null, flat = false;
      if( !StrDef(res.length) ) {
        res = [res];
        flat = true;
      }
      for(var i=0;i<res.length;i++) {
        tmp = null;
        if( StrDef(res[i].ocode_data) ) {
          tmp = res[i].ocode_data;
        }
        // pour les pdv
        if( StrDef(res[i].pos_data) ) {
          tmp = res[i].pos_data;
        }
        // pour les fichee
        if( StrDef(res[i].fiche_e_data) ) {
          tmp = res[i].fiche_e_data;
        }
        if( tmp !== null ) {
          for(var t in tmp) {
            if( prefix.indexOf(tmp[t].substring(0,5)) != -1 ) {
              tmp[t] = tmp[t].replace(tmp[t].substring(0,5),'');
              if( t == 'code_recette' ) { // on let le code_recette sur 3 digits
                var tmpVal = String(tmp[t]);
                while(tmpVal.length < 3) {
                  tmpVal = '0'+tmpVal;
                }
                tmp[t] = tmpVal;
              }
            }
          }
        }
      }
      if( flat ) res = res[0];
    }
    return res;
  }

  PrefixAdd(ocode_data) {
    if( StrDef(ocode_data) ) {
      // traitement de res pour ajouter les prefix :
      for(var t in ocode_data) {
        if( StrDef(ocode_data[t]) && StrDef(DB_PREFIX[t]) && String(ocode_data[t]).indexOf(DB_PREFIX[t]) == -1 ) {  // s'il n'y a pas de prefix
          if( t == 'code_recette' ) { // on let le code_recette sur 3 digits
            var tmp = String(ocode_data[t]);
            while(tmp.length < 3) {
              tmp = '0'+tmp;
            }
            ocode_data[t] = tmp;
          }
          ocode_data[t] = DB_PREFIX[t]+ocode_data[t];
        }
      }
    }
    return ocode_data;
  }

  async ocode_get(callback,query,before) {
    var q = '';
    var when = moment().format('YYYY-MM-DD')+'%2023:59:59.000';
    if( StrDef(before) ) when = before;
    if( StrDef(query) ) q = '&query='+encodeURI(query);
    var res = await this.GET('/'+SNCFAPI+'/ocodes/?created_before='+when+q);
    res = this.PrefixTrim(res);

    if( StrDef(callback) ) callback(res);
    else return res;
  }

  async ocode_get_one(ocodeId,callback) {
    var res = await this.GET('/'+SNCFAPI+'/ocode/'+ocodeId);
    res = this.PrefixTrim(res);
    if( StrDef(callback) ) callback(res);
    else return res;
  }

  async ocode_get_history(ocodeId,callback) {
    var res = await this.GET('/'+SNCFAPI+'/ocode/'+ocodeId+'/history?created_before='+moment().format('YYYY-MM-DD')+'%2023:59:59.000');
    res = this.PrefixTrim(res);

    if( StrDef(callback) ) callback(res);
    else return res;
  }

  async ocode_put(data,callback) {
    if( StrDef(data.ocode_data.resarail) ) data.ocode_data.resarail = data.ocode_data.resarail.toUpperCase();
    data.ocode_data = this.PrefixAdd(data.ocode_data);
    var obj = {
      last_row_hash:data.row_hash,
      ocode_data:data.ocode_data
    };

    var res = await this.PUT('/'+SNCFAPI+'/ocode/'+data.ocode_id,obj);
    if( StrDef(res.error) && res.error == 'OcodeVersionMismatchException' ) {
      var currentRes = await this.GET('/'+SNCFAPI+'/ocode/'+data.ocode_id);
      obj = {
        last_row_hash:currentRes.row_hash,
        ocode_data:data.ocode_data
      };
      res = await this.PUT('/'+SNCFAPI+'/ocode/'+data.ocode_id,obj);
      if( StrDef(callback) ) callback(res);
      else return res;
    }
    else {
      if( StrDef(callback) ) callback(res);
      else return res;
    }
  }

  async ocode_kpis(callback) {
    var res = await this.GET('/'+SNCFAPI+'/kpis');
    if( StrDef(callback) ) callback(res);
    else return res;
  }

  //-------------------- ROBOT : sert a mettre a jour tous les ocodes !!!!!
  Robot() {
    this.allOcodes = [];
    this.RobotRec();
  }

  RobotRec() {
    var before = moment().format('YYYY-MM-DD')+'%2023:59:59.000';
    if( this.allOcodes.length > 0 ) before = this.allOcodes[this.allOcodes.length-1].create_date;
    this.ocode_get((r)=>{
      var gender = ['S-TABLET','S-PHONE','S-PRINTER','S-PED','S-COMPUTER','S-OTHER'];
      for(var i=0;i<r.length;i++) {
        if( gender.indexOf(r[i].ocode_data.gender) != -1 )
          this.allOcodes.push(r[i]);
      }
      if( r.length < 20 ) {
        this.RobotPut();
      }
      else {
        this.RobotRec();
      }
    },'',before);
  }

  async RobotPut() {
    if( this.allOcodes.length > 0 ) {
      var o = this.allOcodes.pop();
      if( !StrDef(o.ocode_data.emplacement_id) ) {
        o.ocode_data.emplacement_id = 'S-OE-UNKNOWN';
        await this.ocode_put(o);
      }
      this.RobotPut();
    }
    else {
      console.log( 'ROBOT HAS FINISHED' );
    }
  }

  //======================================================= POS ================
  async pos_put(data,callback) {
    if( !StrDef(data.pos_id) ) data.pos_id = uuid().replace(/-/g,'').toUpperCase();    // cas de la creation d'un resarail

    data.pos_data.resarail = StrDef(data.pos_data.resarail)?data.pos_data.resarail.toUpperCase():'';
    data.pos_data = this.PrefixAdd(data.pos_data);
    var d = Clone(data.pos_data);
    console.log( 'pos put : ', data.pos_id, d );
    if( StrDef(window.synchro) && StrDef(window.synchro.RESARAIL) ) {
      window.synchro.RESARAIL.tab[data.pos_id] = this.PrefixTrim({pos_id:data.pos_id,pos_data:{...d}});
    }

    var res = await this.PUT('/'+SNCFAPI+'/pos/'+data.pos_id,{pos_data:d});
    if( StrDef(callback) ) callback(res);
    else return res;
  }

  async pos_get(callback,query,before) {
    var q = '';
    var when = '2000-01-01%2023:59:59.000';
    if( StrDef(before) ) when = before;
    if( StrDef(query) ) q = '&query='+encodeURI(query);
    var res = await this.GET('/'+SNCFAPI+'/poss/?updated_after='+when+q);

    //var d = Clone(res);console.log(d);

    res = this.PrefixTrim(res);

    if( StrDef(callback) ) callback(res);
    else return res;
  }

  async pos_get_stream(query,after,progress,complete) {
    var q = '';
    var when = '2000-01-01%2023:59:59.000';
    if( StrDef(after) ) when = after;
    if( StrDef(query) ) q = '&query='+encodeURI(query);
    this.GETSTREAM('/'+SNCFAPI+'/poss_stream/?updated_after='+when+q,
                  (res)=>{  // ------ on enleve les prefix comme d'hab
                    res = this.PrefixTrim(res);
                    progress(res);
                  },
                  complete);
  }

  async pos_get_one(posId,callback) {
    var res = await this.GET('/'+SNCFAPI+'/pos/'+posId);

    var d = Clone(res);console.log(d);

    res = this.PrefixTrim(res);
    if( StrDef(callback) ) callback(res);
    else return res;
  }

  async pos_put_multi(data,callback) {
    var tmp = [];
    for(var i=0;i<data.length;i++) {
      data[i].pos_data = this.PrefixAdd(data[i].pos_data);
      tmp.push( data[i] );
    }
    var res = await this.PUT('/'+SNCFAPI+'/poss',{pos_array:tmp});
    if( StrDef(callback) ) callback(res);
    else return res;
  }

  //==================================================== FICHEE ================
  async fichee_pust(data,callback) {
    var res = null;

    var code = window.synchro.RESARAIL.r(data.fiche_e_data.resarail)+data.fiche_e_data.code_recette;
    data.fiche_e_data = this.PrefixAdd(data.fiche_e_data);
    var obj = {fiche_e_code:code,fiche_e_data:data.fiche_e_data};

    // pour la suppression :
    if( data.status == '9' ) obj.status = '9';

    if( StrDef(data.fiche_e_id) ) {
      res = await this.PUT('/'+SNCFAPI+'/fiche_e/'+data.fiche_e_id,obj);
    }
    else {
      res = await this.POST('/'+SNCFAPI+'/fiche_e',obj);
    }

    if( StrDef(callback) ) callback(res);
    else return res;
  }

  async fichee_get(callback,query,before) {
    var q = '';
    var when = '2000-01-01%2023:59:59.000';
    if( StrDef(before) ) when = before;
    if( StrDef(query) ) q = '&query='+encodeURI(query);

    var res = await this.GET('/'+SNCFAPI+'/fiches_e/?updated_after='+when+q);
    //var d = Clone(res);console.log('real data : ',d);
    res = this.PrefixTrim(res);

    if( StrDef(callback) ) callback(res);
    else return res;
  }

  async fichee_get_stream(query,after,progress,complete) {
    var q = '';
    var when = '2000-01-01%2023:59:59.000';
    if( StrDef(after) ) when = after;
    if( StrDef(query) ) q = '&query='+encodeURI(query);
    this.GETSTREAM('/'+SNCFAPI+'/fiches_e_stream?updated_after='+when+q,
                  (res)=>{  // ------ on enleve les prefix comme d'hab
                    res = this.PrefixTrim(res);
                    progress(res);
                  },
                  complete);
  }

  async fichee_get_one(ficheId,callback) {
    var res = await this.GET('/'+SNCFAPI+'/fiche_e/'+ficheId);
    var d = Clone(res);console.log(d);
    res = this.PrefixTrim(res);
    if( StrDef(callback) ) callback(res);
    else return res;
  }

  async fichee_put_multi(data,callback) {
    var tmp = [];
    for(var i=0;i<data.length;i++) {
      data[i].fiche_e_data = this.PrefixAdd(data[i].fiche_e_data);
      tmp.push( data[i] );
    }
    var res = await this.PUT('/'+SNCFAPI+'/fiche_e',{fiche_e_array:tmp});
    if( StrDef(callback) ) callback(res);
    else return res;
  }

  //==================================================== REGION ================
  async region_get(callback,before) {
    var when = '2000-01-01%2023:59:59.000';
    if( StrDef(before) ) when = before;
    var res = await this.GET('/'+SNCFAPI+'/regions/?updated_after='+when);
    res = this.PrefixTrim(res);
    if( StrDef(callback) ) callback(res);
    else return res;
  }

  async region_put(data,callback) {
    var res = await this.PUT('/'+SNCFAPI+'/region/'+data.region_id,{region_name:data.region_name.toUpperCase()});
    if( StrDef(callback) ) callback(res);
    else return res;
  }

  async region_post(data,callback) {
    var res = await this.POST('/'+SNCFAPI+'/region/',{region_name:data.region_name.toUpperCase()});
    if( StrDef(callback) ) callback(res);
    else return res;
  }

  async region_del(data,callback) {
    var res = await this.DELETE('/'+SNCFAPI+'/region/'+data.region_id);
    if( StrDef(callback) ) callback(res);
    else return res;
  }

  //==================================================== TAG ===================
  async tag_post(data,callback) {
    var res = await this.POST('/'+SNCFAPI+'/tag/',{tag_name:data.tag_name});
    if( StrDef(callback) ) callback(res);
    else return res;
  }

  async tag_put(data,callback) {
    var res = await this.PUT('/'+SNCFAPI+'/tag/'+data.tag_id.replace('S-TAG',''),{tag_name:data.tag_name});
    if( StrDef(callback) ) callback(res);
    else return res;
  }

  async tag_get_stream(query,after,progress,complete) {
    var q = '';
    var when = '2000-01-01%2023:59:59.000';
    if( StrDef(after) ) when = after;
    if( StrDef(query) ) q = '&query='+encodeURI(query);
    this.GETSTREAM('/'+SNCFAPI+'/tags_stream/?updated_after='+when+q,
                  (res)=>{  // ------ on enleve les prefix comme d'hab
                    progress(res);
                  },
                  complete);
  }

  async tag_del(data,callback) {
    var res = await this.DELETE('/'+SNCFAPI+'/tag/'+data.tag_id.replace('S-TAG',''));
    if( StrDef(callback) ) callback(res);
    else return res;
  }

  //===================================================== SYNCHRO ==============
  async Synchro(what) {
    var self = this;
    var role = sessionStorage.getItem('currentRole');
    if( role === null ) {
      setTimeout(()=>{this.Synchro()},250);
      return;
    }
    if( !StrDef(window.role) ) window.role = {};
    window.role.CURRENT = role;
    var reg = sessionStorage.getItem('region');
    window.role.REGION = (reg!==null&&reg!='ALL')?reg:'';

    var perm = {location:true,region:true,resarail:true,tag:true,fichee:true,equipment:true,status:true}; // par defaut on synchronise tout
    if( StrDef(what) ) {        // mais on peut spécifier un truc à synchroniser
      perm = {};
      perm[what] = true;
    }

    //----------- equipment
    if( perm.equipment ) {
      GetJSON('/equipment.json',(data)=>{
        window.synchro.EQUIPMENT = data;
        // on previent que s'est chargé
        window.synchro.EQUIPMENT.loaded = true;
        var event = new CustomEvent('load', { 'detail': 'equipment' });
        window.dispatchEvent(event);
      });
    }

    //----------- status
    if( perm.status ) {
      GetJSON('/status.json',(data)=>{
        window.synchro.STATUS = data;
        // on previent que s'est chargé
        window.synchro.STATUS.loaded = true;
        var event = new CustomEvent('load', { 'detail': 'status' });
        window.dispatchEvent(event);
      });
    }

    //----------- location
    if( perm.location ) {
      window.synchro.LOCATION = {};
      var locateLoad = (res)=>{
        if( !StrDef(res.error) ) {
          for(var i=0;i<res.length;i++) {
            window.synchro.LOCATION[res[i].ocode_data.uuid] = res[i].ocode_data;
          }
          if( res.length == 20 ) {
            self.ocode_get(locateLoad,'S-LOCATION|S-RO-'+role+'|S-DE-0',res[res.length-1].create_date);
          }
          else {
            window.synchro.LOCATION.loaded = true;
            var event = new CustomEvent('load', { 'detail': 'location' });
            window.dispatchEvent(event);
          }
        }
        else {
          window.synchro.LOCATION.loaded = true;
          var event = new CustomEvent('load', { 'detail': 'location' });
          window.dispatchEvent(event);
        }
      }
      this.ocode_get(locateLoad,'S-LOCATION|S-RO-'+role+'|S-DE-0');
    }

    //----------- region
    if( perm.region ) {
      // on définit la fonction qui renvoie le label region
      window.lblREG = function(reg) {
        var res = '';
        if( StrDef(window.lblRegTab[reg]) ) {
          res = window.lblRegTab[reg];
        }
        else if( reg == 'S-REG00' ) {
          res = i18n.t('region.S-REG00');
        }
        else {
          res = 'Région supprimée ('+reg+')';
        }
        return Capitalize(res);
      }
      var regionLoad = function(res) {
        if( !StrDef(window.synchro.REGION) ) {
          window.synchro.REGION = [];
        }
        if( !StrDef(res.error) ) {
          for(var i=0;i<res.length;i++) {
            if( res[i].status != 9 ) {
              self.db.update({reg_id:res[i].region_code},
                              {reg_id:res[i].region_code,
                              reg_label:res[i].region_name,
                              reg_order:res[i].region_name.normalize("NFD"),
                              reg_modify_date:res[i].modify_date
                              },
                              {upsert:true},
                              (err,newDoc)=>{});
            }
            else {
              self.db.remove({reg_id:res[i].region_code},(err,rem)=>{});
            }
          }
          if( res.length == 20 ) {
            self.region_get(regionLoad,res[res.length-1].modify_date);
          }
          else {
            self.db.find({reg_id:{$exists:true}}).sort({reg_order:1}).exec((e,docs)=>{
              for(var i=0;i<docs.length;i++) {
                window.synchro.REGION.push( {key:docs[i].reg_id,label:docs[i].reg_label} );
                window.lblRegTab[docs[i].reg_id] = docs[i].reg_label;
              }
              window.synchro.REGIONloaded = true;
              var event = new CustomEvent('load', { 'detail': 'region' });
              window.dispatchEvent(event);
            });
          }
        }
      };
      window.lblRegTab = {};
      window.synchro.REGION = [];
      // on prend la date de modif la plus récente
      this.db.find({reg_id:{$exists:true}}).sort({reg_modify_date:-1}).limit(1).exec((e,docs)=>{
        if( docs.length == 0 ) {
          self.region_get(regionLoad);
        }
        else {
          //self.region_get(regionLoad);
          self.region_get(regionLoad,docs[0].reg_modify_date);
        }
      });
    }

    // ------- les RESARAIL
    if( perm.resarail ) {
      window.synchro.RESARAIL = {};
      window.synchro.RESARAIL.tab = {};
      window.synchro.RESARAIL.n = (id) => {
        if( StrDef(window.synchro.RESARAIL.tab[id]) ) return window.synchro.RESARAIL.tab[id].pos_data.name;
        return '';
      };
      window.synchro.RESARAIL.r = (id) => {
        if( StrDef(window.synchro.RESARAIL.tab[id]) ) return window.synchro.RESARAIL.tab[id].pos_data.resarail.toUpperCase();
        return '';
      };
      window.synchro.RESARAIL.d = (id) => {
        if( StrDef(window.synchro.RESARAIL.tab[id]) ) return window.synchro.RESARAIL.tab[id];
        return null;
      };
      // on prend la date de modif la plus récente
      this.db.find({pos_id:{$exists:true}}).sort({modify_date:-1}).limit(1).exec((e,docs)=>{
        var after = null;
        if( docs.length > 0 ) after = docs[0].modify_date;

        this.tmpResarail = [];
        window.API.pos_get_stream(
          '',
          after,
          (r)=>{
            if( StrDef(r.pos_data) && StrDef(r.pos_id) ) {
              var doc = this.PrefixTrim(r);
              this.tmpResarail.push(doc);
            }
          },
          (c)=>{
            var endBulk = ()=>{
              self.db.find({pos_id:{$exists:true}}).exec((e,docs)=>{
                for(var i=0;i<docs.length;i++) {
                  window.synchro.RESARAIL.tab[docs[i].pos_id] = docs[i];
                }
                window.synchro.RESARAIL.loaded = true;
                var event = new CustomEvent('load', { 'detail': 'resarail' });
                window.dispatchEvent(event);
              });
            };

            self.db.find({pos_id:{$exists:true}}).exec((e,docs)=>{
              if( docs.length == 0 ) {  // la base est vide on insert par paquet
                var bulkEmpty = ()=>{
                  if( this.tmpResarail.length > 0 ) {
                    var end = Math.min(200,this.tmpResarail.length);
                    var doc = this.tmpResarail.splice(0,end);
                    this.db.insert( doc, (err,newDoc)=>bulkEmpty() );
                  }
                  else {
                    endBulk();
                  }
                };
                bulkEmpty();
              }
              else {  // la base n'est pas vide on fait des upsert, on est obligé
                endBulk();  // on lance la base avec l'existant et on continue les upsert par derrière pour ne pas ralentir

                var bulk = ()=>{
                  if( this.tmpResarail.length > 0 ) {
                    var doc = this.tmpResarail.pop();
                    this.db.update({pos_id:doc.pos_id},
                                    doc,
                                    {upsert:true},
                                    (err,newDoc)=>bulk() );
                  }
                  else {
                    endBulk();
                  }
                };
                bulk();
              }
            });
          }
        );
      });
    }

    // ------- les TAGS
    if( perm.tag ) {
      window.synchro.TAG = {};
      window.API.tag_get_stream('',null,(r)=>{
        if( StrDef(r.tag_code) && StrDef(r.tag_name) && r.status == 1 ) {
          window.synchro.TAG[r.tag_code] = r.tag_name;
        }
      },
      (c)=>{
        window.synchro.TAGloaded = true;
        var event = new CustomEvent('load', { 'detail': 'tag' });
        window.dispatchEvent(event);
      });
    }

    //----------- les Fiches E
    if( perm.fichee ) {
      window.synchro.FICHEE = {};
      // on prend la date de modif la plus récente
      this.db.find({fiche_e_id:{$exists:true}}).sort({modify_date:-1}).limit(1).exec((e,docs)=>{
        var after = null;
        if( docs.length > 0 ) after = docs[0].modify_date;
        window.API.fichee_get_stream(
          '',
          after,
          (r)=>{
            if( StrDef(r.fiche_e_data) && StrDef(r.fiche_e_id) ) {
              var doc = this.PrefixTrim(r);
              self.db.update({fiche_e_id:doc.fiche_e_id},
                              doc,
                              {upsert:true},
                              (err,newDoc)=>{});
            }
          },
          (c)=>{
            // pour le dashboard on calcule les fiche e par region
            window.synchro.FICHEE.countByRegionResarail = {};
            window.API.db.find({fiche_e_id:{$exists:true},status:1},{'fiche_e_data.region':1,'fiche_e_data.resarail':1,_id:0}).exec((e,docs)=>{

              for(var i=0;i<docs.length;i++) {
                if( StrDef(window.synchro.FICHEE.countByRegionResarail[docs[i].fiche_e_data.region]) ) {
                  window.synchro.FICHEE.countByRegionResarail[docs[i].fiche_e_data.region] += 1;
                }
                else {
                  window.synchro.FICHEE.countByRegionResarail[docs[i].fiche_e_data.region] = 1;
                }
                if( StrDef(window.synchro.FICHEE.countByRegionResarail[docs[i].fiche_e_data.resarail]) ) {
                  window.synchro.FICHEE.countByRegionResarail[docs[i].fiche_e_data.resarail] += 1;
                }
                else {
                  window.synchro.FICHEE.countByRegionResarail[docs[i].fiche_e_data.resarail] = 1;
                }
              }
            });

            window.synchro.FICHEE.loaded = true;
            var event = new CustomEvent('load', { 'detail': 'fichee' });
            window.dispatchEvent(event);
          }
        );
      });
    }
  }

  ResarailExists(rr,callback) {
    this.db.find({pos_id:{$exists:true},"pos_data.resarail":rr}).exec((e,docs)=>{
      if( docs.length == 0 ) callback(false);
      else callback(true);
    });
  }
}


function Defined(obj) {
 return typeof(obj) != 'undefined' && obj != null;
}
/*function StrDef(obj) {
  return typeof(obj) != 'undefined' && obj != null && obj != '';
}*/
export function sharedAPI() {
  return _api;
}
