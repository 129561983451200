import React from "react";
// react plugin used to create charts
import { Line, Bar, Doughnut } from "react-chartjs-2";
import Select from "react-select";
import ReactDatetime from "react-datetime";

import i18n from "../assets/lib/i18n";
import {StrDef,Phone,Clone,exportXLSX,DateDisplayS,ResarailDisplay} from '../assets/lib/utils';
import {sharedAPI} from "../assets/lib/api";
import Icon from '../components/Icons/Icon.jsx';
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import Fuse from 'fuse.js';
import lottie from 'lottie-web';

// reactstrap components
import {
  Badge,
  ButtonGroup,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  Modal,
  UncontrolledTooltip
} from "reactstrap";

import XLSX from 'xlsx';

var moment = require('moment');
var uuid = require('uuid/v4');

const LOTTIE_FILE = '/lottie/loading-point.json';
const NB_PER_PAGE = 15;
const POS_PUT_LIMIT = 100;

class Pdv extends React.Component {
  constructor(props) {
    super(props);

    this.RowClick = this.RowClick.bind(this);
    this.Refresh = this.Refresh.bind(this);
    this.Add = this.Add.bind(this);
    this.Save = this.Save.bind(this);
    this.FilterChange = this.FilterChange.bind(this);
    this.LoadList = this.LoadList.bind(this);
    this.Load = this.Load.bind(this);
    this.LoadMore = this.LoadMore.bind(this);
    this.ToggleModalDelete = this.ToggleModalDelete.bind(this);
    this.Import = this.Import.bind(this);
    this.Export = this.Export.bind(this);
    this.XslxHandleFile = this.XslxHandleFile.bind(this);
    this.UpdateWindowDimensions = this.UpdateWindowDimensions.bind(this);

    var reg = sessionStorage.getItem('region');
    reg = (reg!==null&&reg!='ALL')?reg:'';
    this.queryRegionDefault = reg;

    this.state = {
      list:[],
      mode:'list',
      pos_data: { resarail:'',
                  name:'',
                  deployment:'S-DEPLOY-UNKNOWN',
                  region:'',  // region par defaut pas bon !!!!!!! attention au referent !!!!!!
                  uic:'',
                  opening:'',
                  deleted:'0' },
      region:[],
      insert:false,     // mode add
      error:{},
      winW: window.innerWidth,
      winH: window.innerHeight,
      importRegion:'',
      queryRegion:this.queryRegionDefault,
      queryDeployment:'',
    };

    this.backend = sharedAPI();

    window.addEventListener('load', this.Refresh, false);

    if( StrDef(this.props.match) && StrDef(this.props.match.params.pdv) ) {
      switch (this.props.match.params.pdv) {
        case 'creation': this.state.mode = 'update'; this.state.insert = true; break;
        case 'import': this.state.mode = 'import'; break;
        case 'export': this.state.mode = 'export'; break;
        case 'prevision': this.state.mode = 'list'; this.state.prevision = true; break;
        case 'prevision-creation': this.state.mode = 'update'; this.state.insert = true; this.state.prevision = true; break;
        default: this.state.mode = 'update'; break;
      }
    }

  }

  componentWillMount() {
    if( StrDef(window.synchro.REGION) ) this.Refresh({detail:'region'});
  }

  componentDidMount() {
    this._mounted = true;
    if( StrDef(this.props.match.params.pdv) && this.props.match.params.pdv=='prevision' ) {
      window.API.pos_get(this.LoadList,this.QueryString());
    }
    else if( StrDef(this.props.match.params.pdv) && this.props.match.params.pdv=='import' ) {

    }
    else if( StrDef(this.props.match.params.pdv) && this.props.match.params.pdv=='export' ) {
      this.ExportInit();
    }
    else {
      if( StrDef(this.props.match.params.pdv) && this.props.match.params.pdv != 'creation' && this.props.match.params.pdv != 'prevision-creation' ) {
        var prev = !(this.props.match.params.pdv.length==5 && this.props.match.params.pdv.startsWith('FR'));
        this.setState({prevision:prev});
        window.API.pos_get_one(this.props.match.params.pdv,this.Load);
      }
      else {
        window.API.pos_get(this.LoadList,this.QueryString());
      }
    }
    // au cas ou on supprime les listener
    window.removeEventListener('resize', this.UpdateWindowDimensions);
    window.removeEventListener('regionTag', this.FilterChange, false);
    window.removeEventListener('deploymentTag', this.FilterChange, false);
    window.removeEventListener('event', this.FilterChange, false);

    window.addEventListener('resize', this.UpdateWindowDimensions);
    window.addEventListener('regionTag', this.FilterChange, false);
    window.addEventListener('deploymentTag', this.FilterChange, false);
    window.addEventListener('event', this.FilterChange, false);

    var event = new CustomEvent('event', {detail:{type:'mount', screen:'pdv'}});
    window.dispatchEvent(event);
  }

  componentWillUnmount() {
    this._mounted = false;

    window.removeEventListener('load', this.Refresh, false);
    window.removeEventListener('resize', this.UpdateWindowDimensions);
    window.removeEventListener('regionTag', this.FilterChange, false);
    window.removeEventListener('deploymentTag', this.FilterChange, false);
    window.removeEventListener('event', this.FilterChange, false);

    var event = new CustomEvent('event', {detail:{type:'unmount', screen:'pdv'}});
    window.dispatchEvent(event);
  }

  componentDidUpdate( prevProps, prevState ) {
    if( StrDef(this.props.match.params.pdv) ) {
      switch (this.props.match.params.pdv) {
        case 'prevision':
          if( prevState.prevision !== true ) {
            this.setState({prevision:true},()=>{
              window.API.pos_get(this.LoadList,this.QueryString());
            });
          }
          if( prevState.mode != 'list' ) {
            this.setState({mode:'list'},()=>{
              window.API.pos_get(this.LoadList,this.QueryString());
            });
          }
          break;
        case 'prevision-creation':
          if( prevState.mode != 'update' )
            this.setState({mode:'update',insert:true});
          break;
        case 'export':
          if( prevState.mode != 'export' ) {
            this.setState({mode:'export'},()=>{
              this.ExportInit();
            });
          }
          break;
        case 'import':
          if( prevState.mode != 'import' ) {
            this.setState({mode:'import'},()=>{

            });
          }
          break;
        case 'creation':
          break;
        default:  // on est en mode update
          if( prevState.mode != 'update' ) {
            this.setState({mode:'update',insert:false});
            window.API.pos_get_one(this.props.match.params.pdv,this.Load);
          }
          break;
      }
    }
  }

  UpdateWindowDimensions() {
    this.setState({ winW: window.innerWidth, winH: window.innerHeight });
  }

  Refresh(data) {
    if( data.detail == 'region' ) {
      var tab = [], label='';
      for(var i=0;i<window.synchro.REGION.length;i++) {
        label = window.lblREG(window.synchro.REGION[i].key);
        tab.push({value:window.synchro.REGION[i].key,label:label+''});
      }
      var str = JSON.stringify(window.synchro.REGION);
      str = str.replace(/},/g,'},\n');
      str = str.replace('[','[\n');
      str = str.replace(']','\n]');

      var tabRoute = ['creation','import','export','prevision','prevision-creation'];
      var tmp = Clone(this.state.pos_data);
      if( window.synchro.REGION.length > 0
          && (StrDef(this.props.match.params.pdv) && tabRoute.indexOf(this.props.match.params.pdv)!=-1 ) ) {
        // valeur par defaut de la region
        tmp.region = window.synchro.REGION[0].key;
      }
      // attention en cas de référent : on verrouille sur sa region
      this.currentRole = sessionStorage.getItem('currentRole');
      if( sessionStorage.getItem('currentRole') == 'REF' )
        tmp.region = sessionStorage.getItem('region');

      this.setState({region:tab,importRegion:str,pos_data:tmp});
    }
  }

  Change(what,t) {
    var data = Clone(this.state.pos_data);
    // petite subtillité avec opening qui est un objet moment
    if( StrDef(this.state.pos_data) && StrDef(this.state.pos_data.opening) && typeof(this.state.pos_data.opening.clone)!='undefined' ) {
      data.opening = this.state.pos_data.opening.clone();
    }

    if( what.indexOf('deploy@')!=-1 ) {
      data['deployment'] = 'S-DEPLOY-'+what.replace('deploy@','');
    }
    else if( what == 'resarail' ) {
      data[what] = t.target.value.toUpperCase();
      if( data[what].length == 5 ) {
        window.API.ResarailExists(data[what],(exists)=>{
          if( exists && this._mounted ) {
            var error = Clone(this.state.error);
            error['resarail']=true;
            this.setState({error:error});
            window.notify(i18n.t('pdv.resarailAlreadyExists'),'danger');
          }
        });
      }
    }
    else {
      data[what] = t.target.value;
    }

    this.setState({pos_data:data},()=>{
      this.ControlForm();
    });
  }

  ControlForm(force) {
    var errorCount = 0;
    var error = {};

    if( this.state.pos_data.deployment == 'S-DEPLOY-UNKNOWN' ) {
      error['deployment']=true;
      errorCount++;
    }

    if( ! ( this.state.pos_data.resarail.length==5
            && this.state.pos_data.resarail.toUpperCase().startsWith('FR')
            && /^[a-zA-Z]+$/.test(this.state.pos_data.resarail.substring(2,5)) ) ) {
      error['resarail']=true;
      errorCount++;
    }

    if( !StrDef(this.state.pos_data.name) ) {
      error['name']=true;
      errorCount++;
    }

    if( ! (this.state.pos_data.uic.length==8
            && this.state.pos_data.uic.startsWith('87')
            && /^[0-9]+$/.test(this.state.pos_data.uic)) ) {
      error['uic']=true;
      errorCount++;
    }

    if( this.state.prevision && !StrDef(force) ) {
      error = {};
      errorCount = 0;
      if( !StrDef(this.state.pos_data.name) ) {
        error['name'] = true;
        errorCount = 1;
      }
      if( ! ( this.state.pos_data.resarail.length==5
              && this.state.pos_data.resarail.toUpperCase().startsWith('FR')
              && /^[a-zA-Z]+$/.test(this.state.pos_data.resarail.substring(2,5)) ) ) {
        error['resarail']=true;
      }
    }

    if( !StrDef(force) ) this.setState({error:error});
    return errorCount;
  }

  DayValid( current ) {
    var yesterday = moment().subtract( 1, 'day' );
    return current.isAfter( yesterday );
  };

  LoadList(data) {
    var tmp = [];
    if( this.LoadMode == 'append' ) {
      tmp = Clone(this.state.list);
      this.LoadMode = '';
    }
    for(var i=0;i<data.length&&i<NB_PER_PAGE;i++) {
      tmp.push(data[i]);
    }
    this.setState({list:tmp,hasMore:data.length>NB_PER_PAGE});
  }

  Load(data) {
    if( !StrDef(data.pos_data.resarail) ) data.pos_data.resarail = '';
    if( !StrDef(data.pos_data.uic) ) data.pos_data.uic = '';
    // cas des pdv en prevision
    // this.previsionResarail = '';
    // if( !(data.pos_data.resarail.length == 5 && data.pos_data.resarail.startsWith('FR')) ) {
    //   this.previsionResarail = data.pos_data.resarail;
    //   data.pos_data.resarail = '';
    // }
    if( data.pos_data.deleted == '2' ) {
      data.prevision = true;
    }
    else {
      data.prevision = false;
    }
    if( StrDef(data.pos_data.opening) ) {
      data.pos_data.opening = moment(data.pos_data.opening).format('DD/MM/YYYY');
    }

    this.setState( data );
  }

  RowClick(index) {
    this.props.history.push('/followme/pdv/'+this.state.list[index].pos_id);
  }

  Add() {
    this.InitPosData();
    if( this.state.prevision ) {
      this.props.history.push('/followme/pdv/prevision-creation');
    }
    else {
      this.props.history.push('/followme/pdv/creation');
    }
  }

  LoadMore() {
    this.LoadMode = 'append';
    window.API.pos_get( this.LoadList,this.QueryString(),encodeURI(this.state.list[this.state.list.length-1].modify_date) );
  }

  Save() {
    window.API.ResarailExists(this.state.pos_data.resarail,(exists)=>{
      if( exists && !this.state.prevision ) {
        window.notify(i18n.t('pdv.resarailAlreadyExists'),'danger');
      }
      else {
        this.SaveBis();
      }
    });
  }

  SaveBis() {
    var data = Clone(this.state);

    var error = this.ControlForm();
    if( error > 0 ) {
      window.notify(this.state.prevision?i18n.t('pdv.errorUpdatePrevision'):i18n.t('pdv.errorUpdate'),'danger');
      return;
    }

    data.pos_data.deleted = '0';

    if( StrDef(this.state.pos_data.opening) && typeof(this.state.pos_data.opening.clone)!='undefined' ) {
      data.pos_data.opening = this.state.pos_data.opening.format('YYYY-MM-DD');
    }

    if( this.state.prevision ) {  // on crée un pdv en prevision
      data.pos_data.deleted = '2';
      if( !(data.pos_data.resarail.length == 5 && data.pos_data.resarail.startsWith('FR')) && StrDef(this.previsionResarail) ) {
        data.pos_data.resarail = this.previsionResarail;
      }
      else {
        if( StrDef(this.previsionResarail) ) {
          // cas ou il faut changer le RESARAIL ATTENTION put avec pos_id
          data.pos_data.old_resarail = this.previsionResarail;
        }
      }
      // attention si on est en prevision mais que tous les champs sont remplis on sort du mode previonnel
      var err = this.ControlForm(true);
      if( err == 0 ) {
        data.pos_data.deleted = '0';
        window.notify(i18n.t('pdv.previsionOut'),'success',10);
      }
    }
    else {
      delete(data.pos_data.opening);
    }

    window.API.pos_put(data,(r)=>{
      if( StrDef(r.error) ) {
        window.notify(i18n.t('all.recordImpossible'),'danger');
      }
      else {
        // on initialise le state
        this.InitPosData();
        if( this.props.embbeded ) {
          this.props.callback(data);
        }
        else {
          this.props.history.goBack();
        }
      }
    });
  }

  EmbbededClose() {
    if( StrDef(this.props.callback) ) this.props.callback();
  }

  InitPosData() {
    var pos_data = {  resarail:'',
                      name:'',
                      deployment:'S-DEPLOY-UNKNOWN',
                      region:sessionStorage.getItem('region')!='ALL'?sessionStorage.getItem('region'):'',
                      uic:'',
                      opening:'',
                      deleted:'0' };
    if( window.synchro.REGION.length > 0 && (StrDef(this.props.match.params.pdv) && !this.props.match.params.pdv.startsWith('FR')) ) {
      // valeur par defaut de la region
      pos_data.region = window.synchro.REGION[0].key;
    }
    // attention en cas de référent : on verrouille sur sa region
    if( sessionStorage.getItem('currentRole') == 'REF' )
      pos_data.region = sessionStorage.getItem('region');
    this.setState({pos_data:pos_data});
  }

  ToggleModalDelete() {
    if( !this.state.modalDelete ) {   // on ouvre la modal
      window.API.ocode_get((res)=>{
        if( StrDef(res) && !StrDef(res.error) && res.length == 0 ) {
          this.setState({pending:false,deletePossible:true});
        }
        else {
          this.setState({pending:false,deletePossible:false});
        }
      },'S-RR-'+this.state.pos_id);
    }
    this.setState({pending:true,modalDelete:!this.state.modalDelete});
  }

  CloseModalDelete(bool) {
    this.setState({deletePossible:false});
    this.ToggleModalDelete();
    if( bool ) {
      var data = Clone(this.state);
      data.pos_data.deleted = '1';
      // attention au pdv en prevision
      if( !(data.pos_data.resarail.length == 5 && data.pos_data.resarail.startsWith('FR')) && StrDef(this.previsionResarail) ) {
        data.pos_data.resarail = this.previsionResarail;
      }
      console.log('DELETE >>>>>>>>>>', data );
      window.API.pos_put(data,(r)=>{
        console.log( 'res del : ', r );
        if( StrDef(r.error) ) {
          window.notify(i18n.t('all.recordImpossible'),'danger');
        }
        this.props.history.goBack();
      });
    }
  }

  QueryString(){
    var query = 'S-DE-0';
    if( this.state.prevision ) query = 'S-DE-2';
    if( StrDef(this.state.queryRegion) ) query += (StrDef(query)?'|':'')+this.state.queryRegion;
    if( StrDef(this.state.queryDeployment) ) query += (StrDef(query)?'|':'')+this.state.queryDeployment;
    console.log( 'QueryString = ', query );
    return query;
  };

  FilterChange(data) {
    switch (data.type) {
      case 'regionTag':
        var query = '';
        for(var i=0;i<data.detail.length;i++) {
          if( data.detail[i].checked )
            query += (query==''?'':' ')+data.detail[i].key;
        }
        this.setState({queryRegion:query},()=>{
          window.API.pos_get(this.LoadList,this.QueryString());
        });
        break;
      case 'deploymentTag':
        var query = '';
        for(var i=0;i<data.detail.length;i++) {
          if( data.detail[i].checked )
            query += (query==''?'':' ')+data.detail[i].key;
        }
        this.setState({queryDeployment:query},()=>{
          window.API.pos_get(this.LoadList,this.QueryString());
        });
        break;
      case 'event':
        if( data.detail == 'filterCancel' ) {
          this.setState({ queryRegion:this.queryRegionDefault,
                          queryDeployment:'' },()=>{
            window.API.pos_get(this.LoadList,this.QueryString());
          });
        }
        break;
      default:
    }
  }

  // ------------------------------------------ IMPORT XSLX -------------------
  Import() {
    this.props.history.push('/followme/pdv/import');
  }

  RowParse(data) {
    try {
      var errorCount = 0, errorLabel=[];

      // deploiement
      var deploy = 'UNKNOWN';
      var tmp = StrDef(data[this.parsedIndex['deployment']])?data[this.parsedIndex['deployment']].toUpperCase():'';
      tmp = tmp.normalize().replace(/[\u0300-\u036f]/g, ""); // on enleve les accents au cas ou
      if( tmp.indexOf('AGENT') != -1 ) deploy = 'AGENT';
      if( tmp.indexOf('PART') != -1 ) deploy = 'PARTNER';

      // region
      var reg = data[this.parsedIndex['region']];
      var options = {
        shouldSort: true,
        tokenize: true,
        matchAllTokens: true,
        threshold: 0.6,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: [
          "label"
        ]
      };

      var list = '';
      try {
        list = JSON.parse(this.state.importRegion);
      }
      catch(e) {
        if( StrDef(window.synchro.REGION) ) this.Refresh({detail:'region'});
        return {valid:false,pos_data:null,errorLabel:['Liste des régions malformée']};
      }

      var fuse = new Fuse(list, options); // "list" is the item array
      var result = fuse.search(reg);

      if( result.length == 0 ) errorCount++;
      else reg = result[0].key;

      var name = '';
      try {
        name = decodeURIComponent(escape(data[this.parsedIndex['name']]));
      }
      catch(e){
        name = data[this.parsedIndex['name']];
      }

      var pos_data = {  resarail:StrDef(data[this.parsedIndex['resarail']])?data[this.parsedIndex['resarail']].toUpperCase().trim():'',
                        name:name,
                        deployment:'S-DEPLOY-'+deploy,
                        region:reg,
                        uic:String(StrDef(data[this.parsedIndex['uic']])?data[this.parsedIndex['uic']]:''),
                        deleted:this.state.prevision?'S-DE-2':'S-DE-0' };

      if( ! ( pos_data.resarail.length==5
              && pos_data.resarail.startsWith('FR')
              && /^[a-zA-Z]+$/.test(pos_data.resarail) ) ) {
        if( !this.state.prevision ) {
          errorCount++;
          errorLabel.push('RESARAIL incorrect');
        }
        else {  // on est en prevision donc on genere des resarails
          pos_data.resarail = 'FR'+uuid().replace(/-/g,'').toUpperCase();
        }
      }

      if( !StrDef(pos_data.name) ) {
        errorCount++;
        errorLabel.push('Nom manquant');
      }

      if( ! (pos_data.uic.length==8
              && pos_data.uic.startsWith('87')
              && /^[0-9]+$/.test(pos_data.uic)) ) {
        if( !this.state.prevision ) {
          errorCount++;
          errorLabel.push('UIC incorrect');
        }
        else {  // on est en prevision donc on s'en fiche

        }
      }

      if( ! ( pos_data.deployment=='S-DEPLOY-AGENT'
              || pos_data.deployment=='S-DEPLOY-PARTNER'
              || pos_data.deployment=='S-DEPLOY-UNKNOWN' ) ) {
        errorCount++;
        errorLabel.push('Mode de déploiement incorrect');
      }

      var found = false;
      for(var i=0;i<this.state.region.length;i++) {
        if( this.state.region[i].value == pos_data.region ) {
          found = true;
          break;
        }
      }
      if( !found ) errorLabel.push('Région introuvable');

      return {valid:found && errorCount==0,pos_data:pos_data,errorLabel:errorLabel};
    }
    catch(e) {
      console.log( e );
      return {valid:false,pos_data:null,errorLabel:[i18n.t('pdv.errorImport')]};
    }
  }

  XslxHandleFile(e) {
    var self = this;
    var files = e.target.files, f = files[0];
    var reader = new FileReader();

    this.importDuplicate = [];
    this.rowsBuffer = {};

    this.setState({importPending:true,importFinish:false},()=>{
      lottie.loadAnimation({
        container: document.querySelector('#anim'), // the dom element that will contain the animation
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: LOTTIE_FILE // the path to the animation json
      });
    });

    reader.onload = function(e) {
      var data = new Uint8Array(e.target.result);
      var workbook = XLSX.read(data, {type: 'array'});

      var first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
      self.rowsBuffer = {};
      self.rows = XLSX.utils.sheet_to_json(first_worksheet, {header:1});

      // detection des colones
      var first = self.rows[0], tmp='';
      self.parsedIndex = {};      // objet contenant les indices de colonnes

      var options = {
        shouldSort: true,
        tokenize: true,
        threshold: 0.6,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: ["label"]
      };

      var list = [{"key":"region","label":"REGION"},
                  {"key":"uic","label":"UIC"},
                  {"key":"name","label":"PDV"},
                  {"key":"resarail","label":"RESARAIL"},
                  {"key":"deployment","label":"DEPLOIEMENT"}];
      var fuse = null;
      var result = null;


      for(var i=0;i<first.length;i++) {
        tmp = first[i].toUpperCase();
        tmp = tmp.normalize().replace(/[\u0300-\u036f]/g, "");
        // on fait fuse en cas d'accent bizarre utf8 unicode et compagnie
        fuse = new Fuse(list, options);
        result = fuse.search(tmp);
        if( result.length > 0 ) {
          self.parsedIndex[result[0].key] = i;
        }
        if( tmp.indexOf('REGION')!= -1 ) self.parsedIndex['region'] = i;
        if( tmp.indexOf('UIC')!= -1 ) self.parsedIndex['uic'] = i;
        if( tmp.indexOf('PDV')!= -1 ) self.parsedIndex['name'] = i;
        if( tmp.indexOf('RESARAIL')!= -1 ) self.parsedIndex['resarail'] = i;
        if( tmp.indexOf('DEPLOIEMENT')!= -1 ) self.parsedIndex['deployment'] = i;
      }
      // on controle que la premiere ligne est complete
      var c = 0;
      for(var k in self.parsedIndex) c++;
      if( c != 5 ) {
        window.notify(i18n.t('pdv.errorImportHeader'),'danger');
        self.setState({importPending:false});
        return;
      }

      self.rows.shift();  // on retire la première ligne

      self.XlsxRecursivePut();
    };

    //setTimeout(()=>{
      reader.readAsArrayBuffer(f);
    //},5000);

  }

  async XlsxRecursivePut() {
    if( this.rows.length > 0 ) {
      var row = this.RowParse(this.rows.shift());
      if( row.valid ) {
        if( StrDef(this.rowsBuffer[row.pos_data.resarail]) ) {
          this.importDuplicate.push( <div key={'dup'+this.importDuplicate.length}>{JSON.stringify(row.pos_data)}</div> );
        }

        // DEBUG  DEBUG  DEBUG  DEBUG  DEBUG  DEBUG  DEBUG  DEBUG  DEBUG  DEBUG
        // DEBUG  DEBUG  DEBUG  DEBUG  DEBUG  DEBUG  DEBUG  DEBUG  DEBUG  DEBUG
        // DEBUG  DEBUG  DEBUG  DEBUG  DEBUG  DEBUG  DEBUG  DEBUG  DEBUG  DEBUG
        // var makeid = (length) => {
        //    var result           = '';
        //    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        //    var charactersLength = characters.length;
        //    for ( var i = 0; i < length; i++ ) {
        //       result += characters.charAt(Math.floor(Math.random() * charactersLength));
        //    }
        //    return result;
        // }
        // row.pos_data.resarail = 'FR'+makeid(3);
        // DEBUG  DEBUG  DEBUG  DEBUG  DEBUG  DEBUG  DEBUG  DEBUG  DEBUG  DEBUG
        // DEBUG  DEBUG  DEBUG  DEBUG  DEBUG  DEBUG  DEBUG  DEBUG  DEBUG  DEBUG
        // DEBUG  DEBUG  DEBUG  DEBUG  DEBUG  DEBUG  DEBUG  DEBUG  DEBUG  DEBUG

        this.rowsBuffer[row.pos_data.resarail] = row.pos_data;
        var nb = 0;
        for(var k in this.rowsBuffer) nb++;
        if( nb == POS_PUT_LIMIT ) {
          var tmp = [];
          for(var r in this.rowsBuffer) {
            tmp.push({pos_id:r,pos_data:this.rowsBuffer[r]});
          }
          var res = await window.API.pos_put_multi(tmp);
          var res = {};
          if( StrDef(res.error) ) {
            window.notify(i18n.t('pdv.errorImport'),'danger');
            return;
          }
          else {
            console.log( 'put 100 : ', res );
          }
          console.log( 'PUT 100 : ', tmp );
          this.rowsBuffer = {};
          // il faut attendre au moins 1000 car mounir ne peut faire q'un PUT par seconde
          setTimeout(()=>this.XlsxRecursivePut(),1000);
        }
        else {
          this.XlsxRecursivePut();
        }
      }
      else {
        var tmp = [], i=0;
        for(var k in row.pos_data) {
          if( i==0 ) tmp.push(<h5 key='ee'>Erreur à cet enregistrement</h5>);
          i++;
          tmp.push( <div key={'ier'+i}>{k+' : '+row.pos_data[k]}</div> );
        }
        tmp.push( <br key='br'/> );
        tmp.push( <h6 key='h6'>Cause{row.errorLabel.length>1?'s':''} :</h6> );
        for(var k in row.errorLabel) {
          i++;
          tmp.push( <p key={'ier'+i}>{row.errorLabel[k]}</p> );
        }
        this.setState({importAbort:true,importError:tmp,importPending:false});
        window.notify(i18n.t('pdv.errorImport'),'danger');
        return;
      }
    }
    else {
      var nb = 0;
      for(var k in this.rowsBuffer) nb++;
      if( nb > 0 ) {
        var tmp = [];
        for(var r in this.rowsBuffer) {
          tmp.push({pos_id:r,pos_data:this.rowsBuffer[r]});
        }
        var res = await window.API.pos_put_multi(tmp);
        if( StrDef(res.error) ) {
          window.notify(i18n.t('pdv.errorImport'),'danger');
          return;
        }
        else {
          console.log( 'put 100 : ', res );
        }
        console.log( 'PUT LAST 100 : ', tmp );
        this.setState({importPending:false,importFinish:true});
      }
      else {
        this.setState({importPending:false,importFinish:true});
      }
    }
  }

  // ----------------------------------------------- EXPORT --------------------
  Export() {
    this.props.history.push('/followme/pdv/export');
  }

  ExportInit() {
    if( this.exporting ) return;
    this.exporting = true;

    this.exportTab = [];
    this.exportTab.push(['REGION','UIC','PDV','RESARAIL','DEPLOIEMENT','PREVISION']);

    if(document.querySelector('#anim')!=null) document.querySelector('#anim').innerHTML = '';
    lottie.loadAnimation({
      container: document.querySelector('#anim'), // the dom element that will contain the animation
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: LOTTIE_FILE // the path to the animation json
    });

    var query = 'S-DE-0 S-DE-2';
    if( this.state.prevision ) query = 'S-DE-2';

    // timer juste pour voir le lottie
    setTimeout(()=>{
      console.log('((((((((((((((((((((((()))))))))))))))))))))))' );
      window.API.pos_get_stream(query,null,
        (r)=>{
          this.ExportProgress(r);
        },
        (c)=>{
          this.ExportComplete(c);
        })
    },1000);

  }

  ExportProgress(data) {
    if( data.pos_data.deleted=='2' ) {
      console.log( '>>>>>>>>>>', data.pos_data );
    }
    this.exportTab.push([window.lblREG(data.pos_data.region),
                          data.pos_data.uic,
                          data.pos_data.name,
                          ResarailDisplay(data.pos_data.resarail),
                          data.pos_data.deployment=='S-DEPLOY-AGENT'?'Agent':'Partenaire',
                          data.pos_data.deleted=='2'?'prévision':'']);
  }

  ExportComplete(status) {
    this.exporting = false;
		exportXLSX(this.exportTab,'PDV','pdv.xlsx');
    this.props.history.goBack();
  }

  render() {
    var contentUpdateForm = (
      <CardBody>
        { this.state.prevision &&
          <>
            <label>Date d'ouverture</label>
            <FormGroup>
              <ReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: ""
                }}
                value={this.state.pos_data.opening}
                onChange={(d)=>{
                  this.Change('opening',{target:{value:d}});
                }}
                timeFormat={false}
                isValidDate={this.DayValid.bind(this)}
              />
            </FormGroup>
          </>
        }
        <label>Déploiement</label>
        <FormGroup>
          <Select
            className="react-select primary selectBtn"
            classNamePrefix="react-select"
            value={{value:this.state.pos_data.deployment,label:i18n.t('item.'+this.state.pos_data.deployment)}}
            onChange={value => {
              this.Change('deploy@'+value.value);
            }}
            options={[
              {value:"UNKNOWN",label:i18n.t('item.S-DEPLOY-UNKNOWN')},
              {value:"AGENT",label:i18n.t('item.S-DEPLOY-AGENT')},
              {value:"PARTNER",label:i18n.t('item.S-DEPLOY-PARTNER')},
            ]}
          />
        </FormGroup>
        <label>Région</label>
        { sessionStorage.getItem('currentRole') == 'REF' &&
          <p>{window.lblREG(this.state.pos_data.region)}</p>
        }
        { sessionStorage.getItem('currentRole') == 'ADM' &&
          <FormGroup>
            <Select
              className="react-select primary selectBtn"
              classNamePrefix="react-select"
              isSearchable={false}
              value={{value:this.state.pos_data.region,label:window.lblREG(this.state.pos_data.region)}}
              onChange={value => {
                var pos_data = Clone(this.state.pos_data);
                pos_data.region = value.value;
                this.setState({pos_data:pos_data});
              }}
              options={this.state.region}
              placeholder="Choisir une région"
            />
          </FormGroup>
        }
        <label>RESARAIL</label>
        <FormGroup>
          <Input type="text" className={StrDef(this.state.error.resarail)?'error':''} value={this.state.pos_data.resarail} style={{textTransform:'uppercase'}} onChange={this.Change.bind(this,'resarail')}/>
        </FormGroup>
        <label>Libellé</label>
        <FormGroup>
          <Input type="text" className={StrDef(this.state.error.name)?'error':''} value={this.state.pos_data.name} onChange={this.Change.bind(this,'name')}/>
        </FormGroup>
        <label>UIC</label>
        <FormGroup>
          <Input type="text" className={StrDef(this.state.error.uic)?'error':''} value={this.state.pos_data.uic} onChange={this.Change.bind(this,'uic')}/>
        </FormGroup>
      </CardBody>
    );

    return (
      <>
        <div className="content">
          { this.state.mode == 'list' && this.state.winW > 991 &&             // BROWSER VERSION
            <>
              <Row>
                <Col md="6" sm="8">
                  <h2>{this.state.prevision?i18n.t('pdv.titlePrevision'):i18n.t('pdv.title')}</h2>
                </Col>
                <Col md="6" sm="4" className="text-right">
                  <div style={{display:'flex',height:'42px',justifyContent:'flex-end',alignItems:'center'}}>
                    { (this.currentRole == 'ADM' && !this.state.prevision) &&
                    <>
                      <Button color="primary" style={{paddingLeft:15,paddingRight:15,margin:0,marginTop:'25px',marginRight:10}} onClick={this.Import}>
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                          Importer
                          <Icon name='download' size={24} color={'#FFF'} style={{marginLeft:'10px',display:'flex',transform:'rotate(180deg)'}}/>
                        </div>
                      </Button>
                      <Button color="primary" style={{paddingLeft:15,paddingRight:15,margin:0,marginTop:'25px',marginRight:10}} onClick={this.Export}>
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                          Exporter
                          <Icon name='download' size={24} color={'#FFF'} style={{marginLeft:'10px',display:'flex'}}/>
                        </div>
                      </Button>
                    </>
                    }
                    <Button color="primary" style={{paddingLeft:15,paddingRight:15,margin:0,marginTop:'25px'}} onClick={this.Add}>
                      <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                        Ajouter
                        <Icon name='add' size={24} color={'#FFF'} style={{marginLeft:'10px',display:'flex'}}/>
                      </div>
                    </Button>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="12">
                  <Card>
                    <CardBody>
                      { this.state.list.length == 0 &&
                        <>
                          <b>{i18n.t('all.noResult')}</b>
                        </>
                      }
                      { this.state.list.length > 0 &&
                        <>
                          <Table responsive striped>
                            <thead className="text-primary">
                              <tr>
                                { window.role.CURRENT == 'ADM' &&
                                  <th width="220px">Région</th>
                                }
                                { this.state.prevision &&
                                  <th>Ouverture</th>
                                }
                                <th width="120px">RESARAIL</th>
                                <th>Libellé</th>
                                <th>UIC</th>
                                <th>Déploiement</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody style={{maxHeight:'calc(100vh - 345px)'}}>
                              { this.state.list.map((data,i)=>{
                                  return(
                                    <tr key={'tr'+i}>
                                      { window.role.CURRENT == 'ADM' &&
                                      <td width="220px">
                                        {window.lblREG(data.pos_data.region)}
                                      </td>
                                      }
                                      { this.state.prevision &&
                                        <td>{DateDisplayS(data.pos_data.opening)}</td>
                                      }
                                      <td width="120px">
                                        {data.pos_data.resarail}
                                      </td>
                                      <td>{data.pos_data.name}</td>
                                      <td>{data.pos_data.uic}</td>
                                      <td>{i18n.t('item.'+data.pos_data.deployment)}</td>
                                      <td className="text-right" style={{paddingRight:'20px'}}>
                                        <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-end',alignItems:'center'}}>
                                          <Icon name='edit' size={24} style={{display:'flex',marginRight:'5px'}} onClick={this.RowClick.bind(this,i)}/>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })
                              }
                            </tbody>
                          </Table>
                          <div style={{textAlign:'right'}}>
                            { this.state.list.length>5 &&
                              this.state.list.length+' lignes '
                            }
                            { this.state.hasMore &&
                              <a className='aLink' onClick={this.LoadMore}>(Afficher plus)</a>
                            }
                          </div>
                        </>
                      }
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          }
          { this.state.mode == 'list' && this.state.winW <= 991 &&             // MOBILE VERSION
            <>
              <Row>
                <Col md="6" xs="8">
                  <h2>{i18n.t('pdv.title')}</h2>
                </Col>
                <Col md="6" xs="4" className="text-right">
                  <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
                    <Button color="primary" style={{padding:6,paddingLeft:15,paddingRight:15,margin:0}} onClick={this.Add}>
                      <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                        Ajouter
                        <Icon name='add' size={24} color={'#FFF'} style={{marginLeft:'10px',display:'flex'}}/>
                      </div>
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row style={{marginTop:20}}>
                <Col md="12">
                  { this.state.list.length == 0 &&
                    <>
                      <b>{i18n.t('all.noResult')}</b>
                    </>
                  }
                  { this.state.list.length > 0 &&
                    <>
                      <Table responsive striped>
                        <tbody style={{maxHeight:'calc(100% - 200px)',lineHeight:'1em'}}>
                          { this.state.list.map((data,i)=>{
                              return(
                                <tr key={'tr'+i} onClick={this.RowClick.bind(this,i)}>
                                  <td>
                                    <div style={{display:'flex'}}>
                                      <div style={{display:'flex',flex:2,flexDirection:'column'}}>
                                        <span className="listLabel">Région</span>
                                        <span className="listData" style={{textTransform:'capitalize'}}>{window.lblREG(data.pos_data.region)}</span>
                                      </div>
                                      <div style={{display:'flex',flex:1,flexDirection:'row'}}>
                                        <div style={{display:'flex',flex:1,flexDirection:'column'}}>
                                          <span className="listLabel">RESARAIL</span>
                                          <span className="listData" style={{textTransform:data.pos_data.resarail.length==5?'capitalize':'uppercase'}}>{data.pos_data.resarail}</span>
                                        </div>
                                        <div style={{display:'flex',flex:1,flexDirection:'column',justifyContent:'flex-end',alignItems:'flex-end'}}>
                                          <span className="listLabel">DEPL</span>
                                          <span className="listData">{i18n.t('item.'+data.pos_data.deployment).charAt(0)}</span>
                                        </div>
                                      </div>
                                    </div>

                                    <div style={{display:'flex',marginTop:5}}>
                                      <div style={{display:'flex',flex:2,flexDirection:'column'}}>
                                        <span className="listLabel">Libellé</span>
                                        <span className="listData" style={{textTransform:'capitalize'}}>{data.pos_data.name}}</span>
                                      </div>
                                      <div style={{display:'flex',flex:1,flexDirection:'column'}}>
                                        <span className="listLabel">UIC</span>
                                        <span className="listData">{data.pos_data.uic}</span>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          }
                        </tbody>
                      </Table>
                      <div style={{textAlign:'right',marginBottom:30}}>
                        { this.state.list.length>5 &&
                          this.state.list.length+' lignes '
                        }
                        { this.state.hasMore &&
                          <a className='aLink' onClick={this.LoadMore}>(Afficher plus)</a>
                        }
                      </div>
                    </>
                  }
                </Col>
              </Row>
            </>
          }
          { this.state.mode == 'update' && StrDef(this.state.pos_data) &&
            <>

              { !this.props.embbeded &&
                <Row>
                  <Col md="8" xs="12">
                    <h2>{i18n.t('pdv.titlePDV'+(this.state.insert?'Insert':'')+(this.state.prevision?'Prev':''))}</h2>
                  </Col>
                  <Col md="4" className="text-right">
                    <div style={{display:'flex',flexDirection:'row-reverse'}}>
                      <Button color="primary" style={{margin:0,marginTop:'5px',marginLeft:'10px'}} onClick={this.Save}>Enregistrer</Button>
                      { !this.state.insert &&
                        <Button color="danger" className="btn-link"  style={{color:'#A0A0A0',margin:0,marginTop:'5px'}} onClick={this.ToggleModalDelete}>Supprimer</Button>
                      }
                    </div>
                  </Col>
                </Row>
              }

              { this.props.embbeded &&
                <div className="modal-header justify-content-center">
                  <h5>{i18n.t('pdv.titleEmbbeded')}</h5>
                  <Icon name="close" color="#000" size={32} style={{position:'absolute',right:'10px',top:'10px',cursor:'pointer'}} onClick={this.EmbbededClose.bind(this)}/>
                </div>
              }


              <Row style={{marginTop:10}}>
                <Col md="12">
                  { !this.props.embbeded &&
                    <Card>
                      {contentUpdateForm}
                    </Card>
                  }
                  { this.props.embbeded &&
                    <>
                      {contentUpdateForm}
                    </>
                  }
                </Col>
              </Row>

              { this.props.embbeded &&
                <div className="modal-header justify-content-center">
                  <Button color="primary" style={{margin:0,marginTop:'5px',marginLeft:'10px'}} onClick={this.Save}>Enregistrer</Button>
                </div>
              }

              <Modal isOpen={this.state.modalDelete} toggle={this.ToggleModalDelete} backdrop='static'>
                <div className="modal-header justify-content-center">
                  { !this.state.pending && this.state.deletePossible &&
                    <>
                      <h5>{i18n.t('pdv.ModalDeleteTitle')+' :'}</h5>
                      <div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'center'}}>
                        <div style={{display:'flex',flex:1,justifyContent:'flex-end'}}>RESARAIL :</div>
                        <div style={{marginLeft:10,display:'flex',flex:1,fontWeight:'600'}}>{StrDef(this.state.pos_data)?this.state.pos_data.resarail:''}</div>
                      </div>
                      <div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'center'}}>
                        <div style={{display:'flex',flex:1,justifyContent:'flex-end'}}>Libellé :</div>
                        <div style={{marginLeft:10,display:'flex',flex:1,fontWeight:'600'}}>{this.state.pos_data.name}</div>
                      </div>
                    </>
                  }
                  { !this.state.pending && !this.state.deletePossible &&
                    <>
                      <h5>{i18n.t('pdv.ModalDeleteImpossibleTitle')+' :'}</h5>
                      <div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'center'}}>
                        <div style={{display:'flex',flex:1,justifyContent:'flex-end'}}>RESARAIL :</div>
                        <div style={{marginLeft:10,display:'flex',flex:1,fontWeight:'600'}}>{this.state.pos_data.resarail}</div>
                      </div>
                      <div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'center'}}>
                        <div style={{display:'flex',flex:1,justifyContent:'flex-end'}}>Libellé :</div>
                        <div style={{marginLeft:10,display:'flex',flex:1,fontWeight:'600'}}>{this.state.pos_data.name}</div>
                      </div>
                      <br/>
                      <h5>{i18n.t('pdv.ModalDeleteImpossibleBody')}</h5>
                    </>
                  }
                  <Icon name="close" color="#000" size={32} style={{position:'absolute',right:'10px',top:'10px',cursor:'pointer'}} onClick={this.CloseModalDelete.bind(this,false)}/>
                </div>

                <div className="modal-body">
                  <div className="modalRegion">
                    <Row>
                      <Col md="12">
                        { this.state.pending &&
                          <p>{i18n.t('pdv.pending')}</p>
                        }
                      </Col>
                    </Row>
                    { !this.state.pending &&
                      <Row>
                        <Col md="12">
                          <div style={{display:'flex',justifyContent:this.state.deletePossible?'space-between':'center'}}>
                            <Button color="info" style={{margin:0,marginTop:'5px',marginLeft:'10px'}} onClick={this.CloseModalDelete.bind(this,false)}>{this.state.deletePossible?i18n.t('all.cancel'):i18n.t('all.ok')}</Button>
                            { this.state.deletePossible &&
                              <Button color="danger" style={{margin:0,marginTop:'5px'}} onClick={this.CloseModalDelete.bind(this,true)}>Supprimer</Button>
                            }
                          </div>
                        </Col>
                      </Row>
                    }

                  </div>
                </div>
              </Modal>
            </>
          }
          { this.state.mode == 'import' &&
            <>
              { this.state.importAbort && !this.state.importPending &&
                <>
                  {this.state.importError}
                  <Button color="primary" style={{margin:0,marginTop:'25px'}} onClick={()=>{this.setState({importAbort:false,importError:[]})}}>Continuer</Button>
                </>
              }
              { !this.state.importAbort && !this.state.importPending && !this.state.importFinish &&
                <>
                  <Row>
                    <Col md="12">
                      <h2>{this.state.prevision?i18n.t('pdv.titleImportPrevision'):i18n.t('pdv.titleImport')}</h2>
                    </Col>
                  </Row>

                  <Card>
                    <CardBody>
                      <p>Format de fichier attendu :</p>
                      <ul>
                        <li>Peu importe l'ordre des colonnes</li>
                        <li>La première ligne contient les entêtes. Les mots clés suivants sont recherchés pour déterminer la colonne :<br/>
                          REGION, UIC, PDV, RESARAIL, DEPLOIEMENT<br/>
                        </li>
                        <li><b>Peu importe la casse, les accents, l'ordre</b></li>
                        <li>La colonne déploiement contient : AGENT, PARTENAIRE ou UNKNOWN (peu importe la casse, les accents, l'ordre)</li>
                      </ul>
                    </CardBody>
                  </Card>

                  { false &&
                    <Card>
                      <CardBody>
                        <p>{i18n.t('pdv.labelRegion')}</p>
                        <ul>
                          <li>La casse, les accents, les tirets sont ignorés</li>
                          <li>
                            Si vous voulez utiliser deux labels différents pour la même region.<br/>
                            Il faut dupliquer la clé concernée comme suit :<br/>
                            <i>{'{'}"key":"S-REG12","label":"SUD"{'}'}<br/>
                            {'{'}"key":"S-REG12","label":"PROVENCE ALPES COTE D'AZUR"{'}'}</i><br/>
                            Dans le champ texte ci-dessus :<br/>
                          </li>
                        </ul>
                        <FormGroup>
                          <Input  type="textarea" name="text" rows={13}
                                  value={this.state.importRegion}
                                  onChange={(e)=>{
                                    this.setState({importRegion:e.target.value});
                                  }}
                                  style={{maxHeight:200,lineHeight:'1.2em'}}/>
                        </FormGroup>
                      </CardBody>
                    </Card>
                  }

                  <Card>
                    <CardBody>
                      Les fichiers XLS, XLSX et CSV sont acceptés<br/>
                      Les données du fichier écraseront la base de données<br/>
                      En cas de duplication du RESARAIL c'est la dernière ligne qui est prise en compte<br/>
                      <FormGroup>
                        <Button color="primary">Choisir un fichier</Button>
                        <Input type="file" name="xlfile" id="fileXslx" onChange={this.XslxHandleFile}/>
                      </FormGroup>
                    </CardBody>
                  </Card>

                  <Row>
                    <Col md="12">

                    </Col>
                  </Row>
                </>
              }
              { this.state.importPending &&
                <>
                  <Row>
                    <Col md="12">
                      <h2>{i18n.t('pdv.titleImport')}</h2>
                    </Col>
                  </Row>
                  <div style={{display:'flex',width:'100%',height:'calc(100vh - 200px)',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                    <div id="anim" style={{marginLeft:'auto',marginRight:'auto',width:100}}></div>
                    Import en cours, veuillez patienter...
                  </div>
                </>
              }
              { this.state.importFinish && !this.state.importPending &&
                <>
                  <h2>Fichier importé avec succès</h2>
                  { this.importDuplicate.length > 0 &&
                    <>
                    <p>Mais avec {this.importDuplicate.length} duplicata{this.importDuplicate.length>1?'s':''} : </p>
                    {this.importDuplicate}
                    </>
                  }
                  <Button color="primary" style={{margin:0,marginTop:'25px'}}
                          onClick={()=>{this.setState({importAbort:false,importFinish:false,importError:[]})}}>Continuer</Button>
                </>
              }
            </>
          }
          { this.state.mode == 'export' &&
            <>
              <Row>
                <Col md="12">
                  <h2>{this.state.prevision?i18n.t('pdv.titleExportPrevision'):i18n.t('pdv.titleExport')}</h2>
                </Col>
              </Row>

              <div style={{display:'flex',width:'100%',height:'calc(100vh - 200px)',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                <div id="anim" style={{marginLeft:'auto',marginRight:'auto',width:100}}></div>
                Export en cours, veuillez patienter...
              </div>
            </>
          }
        </div>
      </>
    );
  }
}

export default Pdv;
