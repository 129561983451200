import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "layouts/Auth/Auth.jsx";
import AdminLayout from "layouts/Admin/Admin.jsx";
import AuthRoute from "components/AuthRoute/AuthRoute.jsx";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss";
import "assets/demo/demo.css";
import "assets/scss/restyle.scss";

import Api from './assets/lib/api';
import {GetJSON} from './assets/lib/utils';

const hist = createBrowserHistory();

// on instancie la classe API qui gère les appels aux serveurs
var api = new Api();
window.API = api;

// on initialise l'objet window.synchro qui va contenir les data "semi statiques" qu'on charge peut
window.synchro = {};

// l'objet contenant les labels region
window.lblREG = {};

window.API.Synchro();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/home" render={props => <AuthLayout {...props} />} />
      <AuthRoute path="/followme" component={AdminLayout}/>
      <Redirect to="/home" />
    </Switch>
  </Router>,
  document.getElementById("root")
);
