import React from "react";
// react plugin used to create charts
import { Line, Bar, Doughnut } from "react-chartjs-2";
import Select from "react-select";

import i18n from "../assets/lib/i18n";
import {StrDef,Phone,Clone,GetMD} from '../assets/lib/utils';
import {sharedAPI} from "../assets/lib/api";
import Icon from '../components/Icons/Icon.jsx';
import packageJson from '../../package.json';

// reactstrap components
import {
  Badge,
  ButtonGroup,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  Modal,
  UncontrolledTooltip,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,

  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,


} from "reactstrap";

const ReactMarkdown = require('react-markdown');

const NB_PER_PAGE = 15;

class About extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      history:['1.4.0','1.3.0','1.2.0','1.1.0','1.0.0'],
      current:'1.4.0',
      verticalTabs:'next',
      displayHistory:false,
    };

    this.state.verticalTabs = this.state.history[0];

    this.backend = sharedAPI();
  }

  componentWillMount() {
    for(var i=0;i<this.state.history.length;i++) {
      setTimeout((ver)=>{
        GetMD('/version/'+ver+'.md',(data)=>{
          var o = {};
          o[ver.replace('.','')] = data;
          this.setState(o);
        });
      },100,this.state.history[i]);
    }
    GetMD('/version/'+this.state.current+'.md',(data)=>{
      this.setState({current:data});
    });
  }

  componentDidMount() {

  }

  componentWillUnmount() {
  }

  render() {

    return (
      <>
        <div className="content">

          <Row>
            <Col md="12" className="text-center">
              <h4>FOLLOW ME</h4>
              version : <b>{packageJson.version}</b>
              <br/><br/>
              O°code
              <br/>
              <a href="https://ocode.team/" target="_blank">www.ocode.team</a>
              <br/>
              <br/>
              <br/>
              { window.TEST &&
                <>
                  Pour installer l'application mobile, allez à cette adresse depuis votre téléphone :<br/>
                  <h4>https://{window.TEST?'wwwdev':'sncf'}.ocode.team/app/index.html</h4>
                </>
              }

              { window.TEST &&
                <div>
                  <br/><br/><br/><br/>
                  Feuilles de o°codes de test : <a href="/test/ocodes_planche_1.pdf">Feuille 1</a> et <a href="/test/ocodes_planche_2.pdf">Feuille 2</a>
                </div>
              }


            </Col>
          </Row>

          { this.state.displayHistory &&
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>

                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col lg="4" md="5" sm="4" xs="6">
                        <div className="nav-tabs-navigation verical-navs">
                          <div className="nav-tabs-wrapper">
                            <Nav
                              className="flex-column nav-stacked"
                              role="tablist"
                              tabs
                            >
                              { false &&
                              <NavItem>
                                <NavLink data-toggle="tab" href="#" role="tab"
                                  className={this.state.verticalTabs==="next"?"active":""}
                                  onClick={() =>this.setState({ verticalTabs: "next" })}>
                                  Prochaine
                                </NavLink>
                              </NavItem>
                              }

                              { this.state.history.map((data,i)=>{
                                  return(
                                    <NavItem key={'ni'+i}>
                                      <NavLink data-toggle="tab" href="#" role="tab"
                                        className={this.state.verticalTabs===data?"active":""}
                                        onClick={() =>this.setState({ verticalTabs: data })}>
                                        {data}
                                      </NavLink>
                                    </NavItem>
                                  );
                                })
                              }
                            </Nav>
                          </div>
                        </div>
                      </Col>
                      <Col lg="8" md="7" sm="8" xs="6">
                        <TabContent activeTab={this.state.verticalTabs}>
                          <TabPane tabId="next">
                            <ReactMarkdown source={this.state.current}/>
                          </TabPane>
                          { this.state.history.map((data,i)=>{
                              if( StrDef(this.state[data.replace('.','')]) ) {
                                var md = this.state[data.replace('.','')];
                                return(
                                  <TabPane key={'tp'+i} tabId={data}>
                                    <ReactMarkdown source={md}/>
                                  </TabPane>
                                );
                              }
                              return null;
                            })
                          }
                        </TabContent>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          }

        </div>
      </>
    );
  }
}

export default About;
