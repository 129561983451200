import React from "react";
// react plugin used to create charts
import { Line, Bar, Doughnut } from "react-chartjs-2";
import Switch from "react-bootstrap-switch";

import Select from "react-select";
import CreatableSelect from 'react-select/creatable';

import i18n from "../assets/lib/i18n";
import {StrDef,Phone,Clone,ObjectHash,ResarailDisplay,SortBy} from '../assets/lib/utils';
import {sharedAPI} from "../assets/lib/api";
import Icon from '../components/Icons/Icon.jsx';
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";

import TagsInput from "react-tagsinput";
import Autosuggest from 'react-autosuggest';
import Fuse from 'fuse.js';
import Pdv from './Pdv.jsx';

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  Form,
  UncontrolledTooltip,
  ButtonGroup,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Modal,
} from "reactstrap";

var uuid = require('uuid/v4');

class Item extends React.Component {
  constructor(props) {
    super(props);

    this.Refresh = this.Refresh.bind(this);
    this.Load = this.Load.bind(this);
    this.Save = this.Save.bind(this);
    this.OnResarailChange = this.OnResarailChange.bind(this);
    this.CreateResarailFinish = this.CreateResarailFinish.bind(this);
    this.LoadLocation = this.LoadLocation.bind(this);
    this.History = this.History.bind(this);
    this.ToggleModalFichee = this.ToggleModalFichee.bind(this);

    this.state = {
      error:{},
      region:[],
      ocode_data:{
        code_calife: "",
        code_comm_depo: "",
        code_recette: "",
        compta_completed: "",
        current_owner: "",
        deployment: "",
        emplacement_id: "",
        gender: "",
        image: "",
        imei: "",
        lniata: "",
        modify_scope: "",
        name: "",
        pdv_completed: "",
        reference: "",
        region: "",
        resarail: "",
        responsable_code_cp: "",
        responsable_completed: "",
        status: "",
        stock: "",
        uic: "",
        is_completed: "",
        posname:"",
        comment:"",
        pin_code:"",
        tag:'',
      },
      pending:true,
      displayPdvCompta:true,
      resarailOption:[],
      createResarailName:'',
      previsionResarail:'',
      resarailDisplayName:{},
      location:[],
      tagSuggestion:[],
      modalPrevision:false,
      ficheeOption:[],
      fichee:null,
      ficheeOptionSelect:-1,
    };

    this.locationLabel = {};

    this.oldHash = '';

    this.backend = sharedAPI();

    window.addEventListener('load', this.Refresh, false);

    window.API.ocode_get_one(this.props.match.params.ocode,this.Load);

    this.currentRole = sessionStorage.getItem('currentRole');
    window.API.ocode_get(this.LoadLocation,'S-LOCATION|S-RO-'+this.currentRole+'|S-DE-0');
  }

  componentDidMount() {

  }

  componentWillMount() {
    if( StrDef(window.synchro.REGION) ) this.Refresh({detail:'region'});
    if( StrDef(window.synchro.EQUIPMENT) ) this.Refresh({detail:'equipment'});
  }

  componentDidUpdate(prevProps) {
   if (this.props.match.params.ocode !== prevProps.match.params.ocode) {
     window.API.ocode_get_one(this.props.match.params.ocode,this.Load);
   }
 }

  componentWillUnmount() {
    window.removeEventListener('load', this.Refresh, false);
  }

  Refresh(data) {
    if( data.detail == 'region' ) {
      var tab = [], label='';
      for(var i=0;i<window.synchro.REGION.length;i++) {
        label = window.lblREG(window.synchro.REGION[i].key);
        tab.push({value:window.synchro.REGION[i].key,label:label+''});
      }
      this.setState({region:tab});
    }
    else if( data.detail == 'equipment' ) {
      var tab = [], label ='';
      for(var i=0;i<window.synchro.EQUIPMENT.length;i++) {
        label = i18n.t('gender.'+window.synchro.EQUIPMENT[i].key);
        tab.push({value:window.synchro.EQUIPMENT[i].key,label:label+''});
      }
      this.setState({equipment:tab});
    }
    else {
      this.setState({fake:1});
    }
  }

  Load(data) {

    if( StrDef(data.error) ) {
      this.setState({codeNotFound:true});
      return;
    }

    // on va chercher le nom du PDV
    if( StrDef(data.ocode_data.resarail) ) {
      window.API.pos_get_one(data.ocode_data.resarail,(r)=>{
        if( !StrDef(r.error) ) {
          var resarailDisplayName = {};
          resarailDisplayName[r.pos_id] = r.pos_data.resarail+'|#|'+r.pos_data.name;

          data.ocode_data.deployment = r.pos_data.deployment;
          data.ocode_data.uic = r.pos_data.uic;

          var state = {resarailDisplayName:resarailDisplayName};

          if( r.pos_data.deleted == '2' ) state.prevision = true;

          // on regarde les fiche E
          if( StrDef(data.ocode_data.lniata)
              && StrDef(data.ocode_data.code_recette)
              && StrDef(data.ocode_data.code_calife)
              && StrDef(data.ocode_data.code_comm_depo) ) {
            // on a saisie la partie compta donc on affiche pas le lien fiche E
          }
          else {
            this.FicheeCheck('S-RR-'+r.pos_id,false);
          }

          this.setState(state);
        }
      });
    }

    //if( !StrDef(data.ocode_data.deployment) ) data.ocode_data.deployment = 'S-DEPLOY-AGENT';

    if( !StrDef(data.ocode_data.pin_code) ) data.ocode_data.pin_code = '';
    if( !StrDef(data.ocode_data.tag) ) data.ocode_data.tag = '';

    this.oldHash = ObjectHash(data.ocode_data);

    data.pending = false;

    if( data.ocode_data.gender!='S-PHONE' && data.ocode_data.gender!='S-TABLET' ) data.displayPdvCompta = false;
    else data.displayPdvCompta = true;

    this.setState( data );
  }

  async Change(what,t) {
    var data = Clone(this.state.ocode_data);
    if( what.indexOf('deploy@')!=-1 ) {
      data['deployment'] = 'S-DEPLOY-'+what.replace('deploy@','');
      if( data['deployment'] == 'S-DEPLOY-' ) data['deployment'] = '';
    }
    else if( what == 'pdv_completed' ) {
      data[what] = t.state.value?"1":"0";
    }
    else if( what == 'responsable_completed' ) {
      data[what] = t.state.value?"1":"0";
    }
    else if( what == 'lniata' ) {
      data[what] = t.target.value.replace(/ /g,'');
    }
    else if( what == 'compta_completed' ) {
      data[what] = t.state.value?"1":"0";
    }
    else if( what == 'resarail' ) {
      data[what] = t.target.value;
    }
    else if( what == 'tag' ) {
      // controle du nouveau tag
      var tag = t.split(' ');
      for(var i=0;i<tag.length;i++) {
        if( StrDef(tag[i]) && !StrDef(window.synchro.TAG[tag[i]]) ) {
          // ceci arrive quand on retape un tag existant et qu'on appuie sur entree sans selectionner dans la liste dropdown
          var found = false;
          for(var k in window.synchro.TAG) {
            if( window.synchro.TAG[k].toUpperCase() == tag[i].toUpperCase() ) {
              if( this.state.ocode_data.tag.indexOf(k) == -1 ) {
                tag[i] = k;
              }
              else {
                tag.splice(i,1);
              }
              found = true;
            }
          }
          if( !found ) {
            var res = await window.API.tag_post({tag_name:tag[i]});
            if( StrDef(res.tag_code) ) {
              window.synchro.TAG[res.tag_code] = res.tag_name;
              tag[i] = res.tag_code;
            }
            else {  // soucis quelconque on le retire du tableau
              if( StrDef(res.error) && res.error.message=='This tag is already registered.' ) {
                window.notify(i18n.t('item.errorTag'),'danger');
              }
              else {
                window.notify(i18n.t('all.error'),'danger');
              }
              tag.splice(i,1);
            }
          }
          break;
        }
      }
      data[what] = tag.join(' ');
    }
    else if( what == 'createResarailName' ) {
      this.setState({createResarailName:t.target.value});
      return;
    }
    else {
      data[what] = t.target.value;
    }

    // on met a jour is_completed
    var error = this.ControlForm();
    var empty = 0;
    var fields = ['code_calife','code_comm_depo','code_recette','deployment','gender',
                  'imei','lniata','reference','region','resarail','responsable_code_cp','uic'];
    for(var i=0;i<fields.length;i++) {
      if( !StrDef(data[fields[i]]) ) empty++;
    }
    // cas particuliers des switch
    if( data.pdv_completed == '0' ) empty++;
    if( data.compta_completed == '0' ) empty++;
    if( data.responsable_completed == '0' ) empty++;

    data.is_completed = "0";
    if( empty == 0 && error == 0 ) data.is_completed = "2";
    if( empty == 0 && error > 0 ) data.is_completed = "1";

    // attention en mode printer et compta
    if( !this.state.displayPdvCompta ) {
      if( StrDef(data.imei) ) {
        empty = 0;
        data.is_completed = "2";
      }
    }

    // mise a jour du status
    if( data.is_completed != '0' ) {
      if( data.status=='S-STATUS-STOCK'
          || data.status=='S-STATUS-PARAMETER' ) {
        data.status = 'S-STATUS-COMPLETED';
      }
    }
    else {
      if( data.status=='S-STATUS-COMPLETED' || data.status=='S-STATUS-STOCK' ) {
        data.status = 'S-STATUS-PARAMETER';
      }
    }
    // revenir au status en stock si besoin
    var isStock = 0;
    fields = ['code_calife','code_comm_depo','code_recette',
              'lniata','resarail','responsable_code_cp','uic'];
    for(var i=0;i<fields.length;i++) {
      if( StrDef(data[fields[i]]) ) isStock++;
    }
    if( data.deployment=='S-DEPLOY-AGENT' || data.deployment=='S-DEPLOY-PARTNER' ) isStock++;
    if( data.pdv_completed == '1' ) isStock++;
    if( data.compta_completed == '1' ) isStock++;
    if( data.responsable_completed == '1' ) isStock++;
    if( isStock == 0 ) {
      data.status = 'S-STATUS-STOCK';
    }


    // cas des Printer et PED, other, pc
    if( !this.state.displayPdvCompta ) {
      if( StrDef(data.imei)
          && (data.status=='S-STATUS-STOCK' || data.status=='S-STATUS-PARAMETER') ) data.status = "S-STATUS-COMPLETED";
    }

    //remplissage auto de code depositaire
    if( data.deployment == 'S-DEPLOY-PARTNER'
        && (data.uic.length==8
            && data.uic.startsWith('87')
            && /^[0-9]+$/.test(data.uic)) ) {
      data.code_comm_depo = '801'+data.uic.substring(2,8);
    }

    if( data.deployment=='S-DEPLOY-AGENT' && data.code_comm_depo=='801'+data.uic ) {
      data.code_comm_depo = '';
    }

    this.setState({ocode_data:data},()=>{
      this.ControlForm();
    });
  }

  async Save() {
    // si pdv est rempli en partie il faut absolument deploy
    if( (StrDef(this.state.ocode_data.uic)
          || StrDef(this.state.ocode_data.resarail)
          || this.state.ocode_data.pdv_completed=='1' )
        && this.state.ocode_data.deployment != 'S-DEPLOY-AGENT'
        && this.state.ocode_data.deployment != 'S-DEPLOY-PARTNER'
        && !this.state.prevision ) {
      window.notify(i18n.t('item.warningBlocPdv'),'danger');
      return;
    }

    var h = ObjectHash(this.state.ocode_data);
    if( this.oldHash == h ) {
      this.props.history.goBack();
      return;
    }

    if( !StrDef(this.state.ocode_data.imei) ) {
      window.notify(i18n.t('item.warning'+(this.state.ocode_data.gender)),'danger');
      return;
    }
    else {
      if( this.state.displayPdvCompta ) {
        if( ! (  this.state.ocode_data.imei.length==15
                && /^[0-9]+$/.test(this.state.ocode_data.imei) ) ) {
          window.notify(i18n.t('item.warningImei'+(this.state.ocode_data.gender)),'danger');
          return;
        }
      }
    }

    var data = Clone(this.state);

    // on met a jour is_completed
    var error = this.ControlForm();
    var empty = 0;
    var fields = ['code_calife','code_comm_depo','code_recette','compta_completed','current_owner','deployment','gender',
                  'imei','lniata','pdv_completed','reference','region','resarail','responsable_code_cp','responsable_completed','uic'];
    for(var i=0;i<fields.length;i++) {
      if( !StrDef(this.state.ocode_data[fields[i]]) ) empty++;
    }
    // cas particuliers des switch
    if( this.state.ocode_data.pdv_completed == '0' ) empty++;
    if( this.state.ocode_data.compta_completed == '0' ) empty++;
    if( this.state.ocode_data.responsable_completed == '0' ) empty++;

    data.ocode_data.is_completed = "0";
    if( empty == 0 && error == 0 ) data.ocode_data.is_completed = "2";
    if( empty == 0 && error > 0 ) data.ocode_data.is_completed = "1";

    // attention en mode printer et PED
    if( !this.state.displayPdvCompta ) {
      if( StrDef(data.ocode_data.imei) ) {
        empty = 0;
        data.ocode_data.is_completed = "2";
      }
      // alors on vide les champs des blocs PDV et COMPTA
      data.ocode_data.code_calife = "";
      data.ocode_data.code_comm_depo = "";
      data.ocode_data.code_recette = "";
      data.ocode_data.compta_completed = "";
      data.ocode_data.lniata = "";
      data.ocode_data.uic = "";
      data.ocode_data.resarail = "";
      data.ocode_data.deployment = "";
      data.ocode_data.pdv_completed = "";
    }

    if( empty == 0 ) {
      window.notify(i18n.t('item.finished'),'success');
    }

    // on met a jour name et image
    data.ocode_data.name = i18n.t('gender.'+data.ocode_data.gender);
    data.ocode_data.image = "../images/"+data.ocode_data.gender+".png";

    // on met a jour le stocklabel
    var resarail = 'S-RR-UNKNOWN';
    if( StrDef(data.ocode_data.resarail) ) resarail = resarail.replace('UNKNOWN',data.ocode_data.resarail);
    data.ocode_data.deployment = StrDef(data.ocode_data.deployment)?data.ocode_data.deployment:'S-DEPLOY-UNKNOWN';
    data.ocode_data.stocklabel = 'S-STOCK'+data.ocode_data.region+data.ocode_data.gender+data.ocode_data.status+resarail+data.ocode_data.deployment;
    //console.log( 'Stock : ', data.ocode_data.stocklabel );

    // on met a jour modify_scope
    data.ocode_data.modify_scope = 'S-SCOPE-MODIFY';

    // mise a jour du status
    if( data.ocode_data.is_completed != '0' ) {
      if( data.ocode_data.status=='S-STATUS-STOCK'
          || data.ocode_data.status=='S-STATUS-PARAMETER' ) {
        data.ocode_data.status = 'S-STATUS-COMPLETED';
      }
    }
    else {
      if( data.ocode_data.status=='S-STATUS-COMPLETED' || data.ocode_data.status=='S-STATUS-STOCK' ) {
        data.ocode_data.status = 'S-STATUS-PARAMETER';
      }
    }
    // revenir au status en stock si besoin
    var isStock = 0;
    fields = ['code_calife','code_comm_depo','code_recette',
              'lniata','resarail','responsable_code_cp','uic'];
    for(var i=0;i<fields.length;i++) {
      if( StrDef(data.ocode_data[fields[i]]) ) isStock++;
    }
    if( data.ocode_data.deployment=='S-DEPLOY-AGENT' || data.ocode_data.deployment=='S-DEPLOY-PARTNER' ) isStock++;

    if( data.ocode_data.pdv_completed == '1' ) isStock++;
    if( data.ocode_data.compta_completed == '1' ) isStock++;
    if( data.ocode_data.responsable_completed == '1' ) isStock++;
    if( isStock == 0 ) {
      data.ocode_data.status = 'S-STATUS-STOCK';
    }

    // cas des Printer et PED, other, pc
    if( !this.state.displayPdvCompta ) {
      if( StrDef(data.imei)
          && (data.status=='S-STATUS-STOCK' || data.status=='S-STATUS-PARAMETER') ) {
        data.status = "S-STATUS-COMPLETED";
      }
    }

    var tmpName = StrDef(data.resarailDisplayName[data.ocode_data.resarail])?data.resarailDisplayName[data.ocode_data.resarail].split('|#|')[1]:'';
    var tmpRR = StrDef(window.synchro.RESARAIL.r(data.ocode_data.resarail))?window.synchro.RESARAIL.r(data.ocode_data.resarail):'';
    if( this.state.displayResarailPrevision ) {
      tmpRR = this.state.previsionResarail;
      tmpName = this.state.createResarailName;
    }
    var pdv = {};
    pdv.pos_id = data.ocode_data.resarail;
    pdv['pos_data'] = { resarail:tmpRR,
                        name:tmpName,
                        deployment:data.ocode_data.deployment,
                        region:data.ocode_data.region,
                        uic:data.ocode_data.uic,
                        deleted:'0' };

    // on va chercher les info du pdv pour updater au cas où
    if( StrDef(data.ocode_data.resarail) ) {
      var pdvGet = window.synchro.RESARAIL.d(data.ocode_data.resarail);
      if( pdvGet != null ) {
        pdv['pos_data'].deleted = pdvGet.pos_data.deleted;
        if( StrDef(pdvGet.pos_data.opening) )
          pdv['pos_data'].opening = pdvGet.pos_data.opening;
      }
      else {  // c'est un new pdv donc on genere un pos_id et on le met dans resarail du ocode !!!!
        pdv.pos_id = uuid().replace(/-/g,'').toUpperCase();
        pdv.pos_data.resarail = data.ocode_data.resarail;
        data.ocode_data.resarail = pdv.pos_id;
      }
    }


    // DEBUG DEBUG : FAIRE DISPARAITRE UN OBJET
    // data.ocode_data = {};
    // data.ocode_data.stocklabel = '';
    // DEBUG DEBUG : FAIRE DISPARAITRE UN OBJET

    window.API.ocode_put(data,(r)=>{
      if( StrDef(r.error) ) {
        window.notify(i18n.t('all.recordImpossible'),'danger');
      }
      else {
        // on enregistre le resarail au cas où ?
        if( this.state.displayPdvCompta && StrDef(data.ocode_data.resarail) ) { // uniquement pour tablet et phone
          if( StrDef(pdv.pos_data.name) ) {
            if( pdv.pos_data.deployment != 'S-DEPLOY-UNKNOWN'
                && StrDef(pdv.pos_data.resarail)
                && StrDef(pdv.pos_data.uic) ) {
              pdv.pos_data.deleted = '0';
            }
            else {
              pdv.pos_data.deleted = '2';
            }
            // on met a jour la base locale !!!!!!!!
            window.synchro.RESARAIL.tab[pdv.pos_id] = pdv;
            var ddd = Clone(pdv);
            window.API.pos_put(pdv,(rp)=>{});
          }

          // s'il y avait une fiche E on la supprime
          if( StrDef(this.state.fichee) ) {
            window.API.fichee_pust({  status:'9',
                                      fiche_e_id:this.state.fichee.fiche_e_id,
                                      fiche_e_data:this.state.fichee.fiche_e_data }, (r)=>{
                                        if( !StrDef(r.error) ) window.notify(i18n.t('item.ficheeUsed'),'success');
                                      });
          }
          else {
            // on regarde qu'on n'a pas saisi une fiche E existante, si oui on la supprime
            window.API.fichee_get((r)=>{
              if( !StrDef(r.error) && r.length > 0 ) {
                window.API.fichee_pust({  status:'9',
                                          fiche_e_id:r[0].fiche_e_id,
                                          fiche_e_data:r[0].fiche_e_data }, (f)=>{
                                            if( !StrDef(f.error) ) window.notify(i18n.t('item.ficheeUsed'),'success');
                                            });
              }
            },'S-RR-'+this.state.ocode_data.resarail+'|S-RE-'+this.state.ocode_data.code_recette+' S-LN-'+this.state.ocode_data.lniata+' S-CA'+this.state.ocode_data.code_calife);
          }
        }

        this.props.history.goBack();
      }

    });
  }

  RegionFindValue(region) {
    for(var i=0;i<this.state.region.length;i++) {
      if( this.state.region[i].value == region ) {
        return this.state.region[i];
      }
    }
  }

  ControlForm() {
    var errorCount = 0;
    var error = {};

    if( this.state.displayPdvCompta && ! (  this.state.ocode_data.imei.length==15
                                            && /^[0-9]+$/.test(this.state.ocode_data.imei) ) ) {
      error['imei']=true;
      errorCount++;
    }

    if( ! (this.state.ocode_data.uic.length==8
            && this.state.ocode_data.uic.startsWith('87')
            && /^[0-9]+$/.test(this.state.ocode_data.uic)) ) {
      error['uic']=true;
      errorCount++;
    }

    var rr = this.state.ocode_data.resarail;
    if( this.state.ocode_data.resarail.length != 5 ) rr = window.synchro.RESARAIL.r(this.state.ocode_data.resarail);
    rr = rr.toUpperCase();

    if( ! ( rr.length==5
            && rr.startsWith('FR')
            && /^[a-zA-Z]+$/.test(rr.substring(2,5)) ) ) {
      error['resarail']=true;
      errorCount++;
    }

    if( this.state.ocode_data.deployment == 'S-DEPLOY-AGENT' ) {
      if( !(/^[a-zA-Z]+$/.test(this.state.ocode_data.responsable_code_cp.substring(7,8))
            && /^[0-9]+$/.test(this.state.ocode_data.responsable_code_cp.substring(0,7))
            && this.state.ocode_data.responsable_code_cp.length==8) ) {
        error['responsable_code_cp']=true;
        errorCount++;
      }

      if( !(this.state.ocode_data.code_comm_depo.length==10
            && this.state.ocode_data.code_comm_depo.startsWith('01')
            && /^[0-9]+$/.test(this.state.ocode_data.code_comm_depo.substring(2,10))) ) {
        error['code_comm_depo']=true;
        errorCount++;
      }
    }
    else {
      if( !(/^[a-zA-Z]+$/.test(this.state.ocode_data.responsable_code_cp.substring(0,4))
            && /^[0-9]+$/.test(this.state.ocode_data.responsable_code_cp.substring(5,9))
            && this.state.ocode_data.responsable_code_cp.length==9) ) {
        error['responsable_code_cp']=true;
        errorCount++;
      }

      if( !(this.state.ocode_data.code_comm_depo=='801'+this.state.ocode_data.uic.substring(2,8)) ) {
        error['code_comm_depo']=true;
        errorCount++;
      }
    }

    if( !(this.state.ocode_data.code_recette.length==3 && /^[0-9]+$/.test(this.state.ocode_data.code_recette)) ) {
      error['code_recette']=true;
      errorCount++;
    }

    if( !(this.state.ocode_data.lniata.length==6
          && this.state.ocode_data.lniata.startsWith('8E')
          && /^[0-9a-zA-Z]+$/.test(this.state.ocode_data.lniata.substring(2,6)) ) ) {
      error['lniata']=true;
      errorCount++;
    }

    if( !(this.state.ocode_data.code_calife.length==11
          && this.state.ocode_data.code_calife.toUpperCase().substring(0,8)=='DTD'+rr
          && /^[0-9]+$/.test(this.state.ocode_data.code_calife.substr(8,11))) ) {
      error['code_calife']=true;
      errorCount++;
    }

    this.setState({error:error});
    return errorCount;
  }

  GetStatus(data) {
    var className = i18n.t('statusColor.'+data.ocode_data.status);

    var res = (
      <div style={{display:'flex',flexDirection:'row',alignItems:'center',height:'47px',marginLeft:'10px'}}>
        { StrDef(className) &&
          <div className={className} style={{width:20,height:20,borderRadius:10,marginRight:'10px'}}></div>
        }
      </div>
    );

    return res;
  }

  OnResarailChange(t) {
    var self = this;
    if( t.length>2 ) {
      window.API.pos_get((r)=>{
        if( !StrDef(r.error) ) {
          var option = [];
          for(var i=0;i<r.length;i++) {
            option.push({ value:r[i].pos_id,
                          label:r[i].pos_data.resarail+'|#|'+r[i].pos_data.name });
          }
          self.setState({resarailOption:option});
        }
      },t+'|S-DE-0 S-DE-2|'+this.state.ocode_data.region);
    }
  }

  CreateResarailFinish() {
    if( !StrDef(this.state.createResarailName) ) return;
    var opt = Clone(this.state.resarailOption);
    opt.push({value:this.state.createResarail,
              label:this.state.createResarail+'|#|'+this.state.createResarailName });
    opt = [];

    var resarailDisplayName = {};
    var state = Clone(this.state);
    resarailDisplayName[state.createResarail] = state.createResarail+'|#|'+state.createResarailName;

    var ocode_data = Clone(this.state.ocode_data);
    ocode_data.resarail = this.state.createResarail;

    this.setState({ resarailOption:opt,
                    resarailDisplayName:resarailDisplayName,
                    ocode_data:ocode_data,
                    createResarail:'',
                    createResarailName:'',
                    displayCreateResarailName:false},()=>{
                      //console.log(this.state);
                    });
  }

  LoadLocation(data) {
    var list = [];
    this.locationLabel = {};
    for(var i=0;i<data.length;i++) {
      list.push({value:data[i].ocode_data.uuid,label:data[i].ocode_data.name});
      if( StrDef(data[i].ocode_data.uuid) )
        this.locationLabel[data[i].ocode_data.uuid] = data[i].ocode_data.name;
    }
    this.setState({location:list});
  }

  History() {
    var h = ObjectHash(this.state.ocode_data);
    if( this.oldHash != h ) {
      window.notify(i18n.t('item.needSave'),'danger');
      return;
    }
    if( this.state.ocode_data.is_completed == '0' ) {
      //this.props.history.push('/followme/ocode/'+this.state.ocode_id+'/history');
      this.props.history.push({pathname:'/followme/ocode/'+this.state.ocode_id+'/history', state:{canGoBack:true}});
    }
    else {
      this.props.history.push('/followme/ocode/'+this.state.ocode_id+'/history');
    }
  }

  AutocompleteRenderInput ({addTag, ...props}) {
    const handleOnChange = (e, {newValue, method}) => {
      if (method === 'enter') {
        e.preventDefault()
      } else {
        props.onChange(e)
      }
    }

    const inputValue = (props.value && props.value.trim().toLowerCase()) || ''
    const inputLength = inputValue.length

    return (
      <Autosuggest
        ref={props.ref}
        suggestions={this.state.tagSuggestion}
        shouldRenderSuggestions={(value) => value && value.trim().length > 0}
        getSuggestionValue={(suggestion) => suggestion.label}
        renderSuggestion={(suggestion) => <div>{suggestion.label}</div>}
        renderSuggestionsContainer={this.RenderSuggestionsContainer.bind(this)}
        inputProps={{...props, onChange: handleOnChange}}
        onSuggestionSelected={(e, {suggestion}) => {
          addTag(suggestion.key)
        }}
        onSuggestionsClearRequested={() => {}}
        onSuggestionsFetchRequested={this.SuggestionUpdate.bind(this)}
      />
    )
  }

  RenderSuggestionsContainer({ containerProps , children, query }) {
    return (
      <>
      { containerProps.className.indexOf('open')!=-1 &&
        <div {... containerProps}
          className="dropdown-menu dropdown-menu-right show autosuggest"
          style={{position:'absolute',marginTop:2,left:'0px',boxShadow:'1px 2px 7px 1px rgba(0, 0, 0, 0.125)'}}>
          {children}
        </div>
      }
      </>
    );
  }

  SuggestionUpdate(search) {
    search = search.value;
    var options = {
      shouldSort: true,
      threshold: 0.4,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 3,
      keys: ["label"]
    };

    var list = [];
    for(var t in window.synchro.TAG) {
      list.push({key:t,label:window.synchro.TAG[t]});
    }
    var fuse = new Fuse(list, options); // "list" is the item array
    var result = fuse.search(search);
    var res = [];
    for(var i=0;i<result.length;i++) {
      if( this.state.ocode_data.tag.indexOf(result[i].key) == -1 ) {
        res.push(result[i]);
      }
    }
    this.setState({tagSuggestion:res});
  }

  RenderTag (props) {
    let {tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other} = props;
    if( !StrDef(tag) ) return null;
    return (
      <span key={key} {...other}>
        {window.synchro.TAG[tag]}
        {!disabled &&
          <a className={classNameRemove} onClick={(e) => onRemove(key)} />
        }
      </span>
    )
  }

  TagChange(t) {
    console.log( 'TagChange ', t );
  }

  ToggleModalPrevision(data) {
    if( this.state.modalPrevision ) {
      if( StrDef(data) ) {
        data = window.API.PrefixTrim(data);
        var resarailDisplayName = {};
        resarailDisplayName[data.pos_id] = data.pos_data.resarail+'|#|'+data.pos_data.name;

        var ocode_data = Clone(this.state.ocode_data);
        ocode_data.deployment = data.pos_data.deployment;
        ocode_data.uic = data.pos_data.uic;

        var state = { resarailDisplayName:resarailDisplayName,
                      ocode_data:ocode_data,
                      modalPrevision:false,
                    };
        this.setState(state);
      }
      else {
        this.setState({modalPrevision:false});
      }
    }
    else {
      this.setState({modalPrevision:true});
    }
  }

  // -------- FICHE E
  FicheeCheck(pos_id,noPopup) {
    window.API.fichee_get((r)=>{
      if( r.length > 0 ) {
        var tab = [];
        for(var i=0;i<r.length;i++) {
          tab[i] = r[i];
          tab[i].label = tab[i].fiche_e_data.code_recette;
          tab[i].checked = i==0;
        }
        tab = SortBy(tab,'fiche_e_code');
        this.setState({ficheeOption:tab},()=>{
          if( !StrDef(noPopup) ) this.ToggleModalFichee();
        })

      }
    },pos_id+'&status=1');
  }

  ToggleModalFichee() {
    if( !this.state.displayFichee ) {
      var tab = Clone(this.state.ficheeOption);
      if( this.state.ficheeOptionSelect != -1 ) {
        for(var i=0;i<tab.length;i++) {
          tab[i].checked = i==this.state.ficheeOptionSelect;
        }
      }
      else {
        for(var i=0;i<tab.length;i++) {
          tab[i].checked = i==0;
        }
      }
      this.setState({ficheeOption:tab,displayFichee:true});
    }
    else {
      this.setState({displayFichee:!this.state.displayFichee});
    }
  }

  FicheeClick(index) {
    var select = null;
    var tab = Clone(this.state.ficheeOption);
    for(var i=0;i<tab.length;i++) {
      if( index >= 0 ) {
        tab[i].checked = i==index;
      }
      if( tab[i].checked ) select = i;
    }
    this.setState({ficheeOption:tab});

    // on valide la modal
    var ocode_data = Clone(this.state.ocode_data);
    var state = null;
    if( index == -1 ) {
      ocode_data.lniata = tab[select].fiche_e_data.lniata;
      ocode_data.code_recette = tab[select].fiche_e_data.code_recette;
      ocode_data.code_calife = tab[select].fiche_e_data.code_calife;
      ocode_data.code_comm_depo = tab[select].fiche_e_data.code_comm_depo;
      ocode_data.compta_completed = '';
      state = {fichee:tab[select],ficheeOptionSelect:select,ocode_data:ocode_data};
    }
    // on detache la fiche E
    if( index == -2 ) {
      ocode_data.lniata = '';
      ocode_data.code_recette = '';
      ocode_data.code_calife = '';
      ocode_data.code_comm_depo = '';
      ocode_data.compta_completed = '';
      state = {fichee:null,ficheeOptionSelect:-1,ocode_data:ocode_data};
    }
    if( index == -1 || index == -2 ) {
      this.setState(state,()=>this.ControlForm());
      this.ToggleModalFichee();
    }
  }

  render() {
    var statusCompta = 'statR';
    var count = 0;
    count += StrDef(this.state.ocode_data.code_calife)?1:0;
    count += StrDef(this.state.ocode_data.code_comm_depo)?1:0;
    count += StrDef(this.state.ocode_data.code_recette)?1:0;
    count += (this.state.ocode_data.compta_completed=="1")?1:0;
    count += StrDef(this.state.ocode_data.lniata)?1:0;
    if( count==5 ) statusCompta = 'statG';
    if( count<5 ) statusCompta = 'statY';
    if( count==0 ) statusCompta = 'statR';

    var statusPDV = 'statR';
    count = 0;
    count += StrDef(this.state.ocode_data.uic)?1:0;
    count += StrDef(this.state.ocode_data.resarail)?1:0;
    count += (this.state.ocode_data.pdv_completed=="1")?1:0;
    if( count==3 ) statusPDV = 'statG';
    if( count<3 ) statusPDV = 'statY';
    if( count==0 ) statusPDV = 'statR';

    var statusResp = 'statR';
    count = 0;
    count += StrDef(this.state.ocode_data.responsable_code_cp)?1:0;
    count += (this.state.ocode_data.responsable_completed=="1")?1:0;
    if( count==2 ) statusResp = 'statG';
    if( count<2 ) statusResp = 'statY';
    if( count==0 ) statusResp = 'statR';

    var statusInfo = 'statR';
    count = 0;
    count += StrDef(this.state.ocode_data.imei)?1:0;
    count += StrDef(this.state.ocode_data.region)?1:0;
    count += StrDef(this.state.ocode_data.reference)?1:0;
    if( count==3 ) statusInfo = 'statG';
    if( count<3 ) statusInfo = 'statY';
    if( count==0 ) statusInfo = 'statR';

    var statusG = ( statusInfo=='statG'
                    && statusResp=='statG'
                    && statusPDV=='statG'
                    && statusCompta=='statG' ) ? 'statG' : 'statY';
    if( !this.state.displayPdvCompta ) {
      statusG = ( StrDef(this.state.ocode_data.imei) ) ? 'statG' : 'statR';
      statusInfo = ( StrDef(this.state.ocode_data.imei) ) ? 'statG' : 'statR';
    }


    var labelEmpl = this.locationLabel[this.state.ocode_data.emplacement_id];
    if( StrDef(this.state.ocode_data.emplacement_id)
        && this.state.ocode_data.emplacement_id != 'UNKNOWN'
        && !StrDef(this.locationLabel[this.state.ocode_data.emplacement_id]) ) {
      labelEmpl = 'not mine';
    }

    return (
      <>
        <div className="content" style={{paddingBottom:100}}>
          { this.state.codeNotFound &&
            <Card>
              <CardBody>
                <b>{i18n.t('all.codeNotFound')}</b>
              </CardBody>
            </Card>
          }
          { !this.state.pending &&
            <>
              <Row>
                <Col md="6">
                  <div style={{display:'flex',flexDirection:'row',justifyContent:isMobile?'space-between':''}}>
                    <h2 style={{marginBottom:0}}>Paramétrage</h2>
                    <div className={i18n.t('statusColor.'+this.state.ocode_data.status)} style={{marginTop:isMobile?'3px':'13px',marginLeft:'6px',paddingLeft:8,paddingRight:8,color:'#FFF',height:22,borderRadius:11}}>
                      {i18n.t('status.'+this.state.ocode_data.status)}
                    </div>
                  </div>
                  <div style={{display:'flex',flexDirection:'row',marginBottom:10,marginTop:isMobile?10:0}}>
                    <img src={i18n.t('image.'+this.state.ocode_data.gender)} style={{width:'48px',height:'48px',marginRight:'20px'}}/>
                    <div style={{display:'flex',flexDirection:'row',flex:1,justifyContent:'flex-start',alignItems:'center'}}>
                      {isMobile?'':'Type de matériel :'}&nbsp;&nbsp;
                      <Select
                        className="react-select primary genderSelect"
                        classNamePrefix="react-select"
                        isSearchable={false}
                        value={{value:this.state.ocode_data.gender,label:i18n.t('gender.'+this.state.ocode_data.gender)}}
                        onChange={value => {
                          var ocode_data = Clone(this.state.ocode_data);
                          ocode_data.gender = value.value;
                          var data = {};
                          if( ocode_data.gender!='S-PHONE' && ocode_data.gender!='S-TABLET' ) data.displayPdvCompta = false;
                          else data.displayPdvCompta = true;
                          data.ocode_data = ocode_data;
                          this.setState(data);
                        }}
                        options={this.state.equipment}
                        placeholder="Choisir un type"
                      />
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-end',marginBottom:10}}>
                    <Button color="default" style={{margin:0,marginTop:'5px'}} onClick={this.History}>Historique</Button>
                    <Button color="primary" style={{margin:0,marginLeft:5,marginTop:'5px'}} onClick={this.Save}>Enregistrer</Button>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={this.state.displayPdvCompta?3:6} sm="12">
                  <Card>
                    <CardHeader>
                      <div style={{display:'flex'}}>
                        <h6>{i18n.t('item.titleInfo')}</h6>
                        <div className={statusInfo} style={{marginTop:'4px',marginLeft:'6px',width:8,height:8,borderRadius:4}}></div>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <label>{i18n.t('label.'+this.state.ocode_data.gender)}</label>
                      <FormGroup>
                        <Input type="text" className={StrDef(this.state.error.imei)?'error':''} value={this.state.ocode_data.imei} onChange={this.Change.bind(this,'imei')}/>
                      </FormGroup>
                      <label>Région</label>
                      <FormGroup>
                        { this.currentRole == 'REF' &&
                          <div>
                            {window.lblREG(this.state.ocode_data.region)}
                          </div>
                        }
                        { this.currentRole == 'ADM' &&
                          <Select
                            className="react-select primary selectBtn"
                            classNamePrefix="react-select"
                            isSearchable={false}
                            value={{value:this.state.ocode_data.region,label:window.lblREG(this.state.ocode_data.region)}}
                            onChange={value => {
                              var ocode_data = Clone(this.state.ocode_data);
                              ocode_data.region = value.value;
                              this.setState({ocode_data:ocode_data});
                            }}
                            options={this.state.region}
                            placeholder="Choisir une région"
                          />
                        }
                      </FormGroup>
                      <label>Référence</label>
                      <FormGroup>
                        <Input type="text" value={this.state.ocode_data.reference} onChange={this.Change.bind(this,'reference')}/>
                      </FormGroup>
                      <label>Emplacement</label>
                      <FormGroup>
                        { (labelEmpl != 'not mine' || labelEmpl == '') &&
                          <Select
                            className="react-select primary selectBtn"
                            isClearable
                            isSearchable={false}
                            classNamePrefix="react-select"
                            value={{value:StrDef(labelEmpl)?this.state.ocode_data.emplacement_id:null,label:labelEmpl}}
                            onChange={value => {
                              var ocode_data = Clone(this.state.ocode_data);
                              if( StrDef(value) )
                                ocode_data.emplacement_id = value.value;
                              else
                                ocode_data.emplacement_id = '';
                              this.setState({ocode_data:ocode_data});
                            }}
                            options={this.state.location}
                            placeholder="Choisir un emplacement"
                          />
                        }
                        { labelEmpl == 'not mine' &&
                          i18n.t('item.locationNotMine'+this.currentRole)
                        }
                      </FormGroup>
                      <label>Commentaire</label>
                      <FormGroup>
                        <Input type="textarea" style={{minHeight:'100px'}} value={this.state.ocode_data.comment} onChange={this.Change.bind(this,'comment')}/>
                      </FormGroup>
                      <label>Etiquettes</label>
                      <FormGroup>
                        <div style={{border:'1px solid #E3E3E3',borderRadius:'4px'}}>
                          <TagsInput
                            renderTag={this.RenderTag.bind(this)}
                            renderInput={this.AutocompleteRenderInput.bind(this)}
                            value={StrDef(this.state.ocode_data.tag)?this.state.ocode_data.tag.split(' '):[]}
                            onChange={(t)=>this.Change('tag',t.join(' '))}
                            tagProps={{ className: "react-tagsinput-tag success" }}
                          />
                        </div>
                      </FormGroup>
                    </CardBody>
                  </Card>
                </Col>

                <Col md={this.state.displayPdvCompta?3:6} sm="12">
                  <Card>
                    <CardHeader>
                      <div style={{display:'flex'}}>
                        <h6>{i18n.t('item.titleResponsable')}</h6>
                        <div className={statusResp} style={{marginTop:'4px',marginLeft:'6px',width:8,height:8,borderRadius:4}}></div>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <label>Code CP</label>
                      <FormGroup>
                        <Input type="text" className={StrDef(this.state.error.responsable_code_cp)?'error':''} value={this.state.ocode_data.responsable_code_cp} onChange={this.Change.bind(this,'responsable_code_cp')}/>
                      </FormGroup>
                      { this.state.ocode_data.gender == 'S-TABLET' &&
                        <>
                          <label>Code PIN</label>
                          <FormGroup>
                            <Input type="text" className={StrDef(this.state.error.pin_code)?'error':''} value={this.state.ocode_data.pin_code} onChange={this.Change.bind(this,'pin_code')}/>
                          </FormGroup>
                        </>
                      }
                      <div style={{marginTop:'20px',display:'flex',justifyContent:'space-between'}}>
                        Complet
                        <Switch offColor="success" offText={<i className="nc-icon nc-simple-remove" />}
                                onColor="success" onText={<i className="nc-icon nc-check-2"/>}
                                value={this.state.ocode_data.responsable_completed=="1"} onChange={this.Change.bind(this,'responsable_completed')}/>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                { this.state.displayPdvCompta &&
                  <Col md="3" sm="12">
                    <Card>
                      <CardHeader>
                        <div style={{display:'flex'}}>
                          <h6>{i18n.t('item.titlePDV')}</h6>
                          <div className={statusPDV} style={{marginTop:'4px',marginLeft:'6px',width:8,height:8,borderRadius:4}}></div>
                        </div>
                      </CardHeader>
                      <CardBody>
                        <label>UIC</label>
                        <FormGroup>
                          <Input type="text" className={StrDef(this.state.error.uic)?'error':''} value={this.state.ocode_data.uic} onChange={this.Change.bind(this,'uic')}/>
                        </FormGroup>
                        <label>RESARAIL</label>
                        <FormGroup>
                          { this.state.displayCreateResarailName &&
                            <>
                              <p>{this.state.createResarail.toUpperCase()}</p>
                              <label>Libellé du PDV</label>
                              <Input  autoFocus type="text"
                                      value={this.state.createResarailName}
                                      onBlur={this.CreateResarailFinish}
                                      onChange={this.Change.bind(this,'createResarailName')}/>
                            </>
                          }
                          { !this.state.displayCreateResarailName &&
                            <CreatableSelect
                              id="selectResarail"
                              isClearable={true}
                              className="react-select primary selectBtn selectResarail"
                              classNamePrefix="react-select"
                              style={{height:'60px',textTransform:'uppercase'}}
                              placeholder=""
                              value={{value:this.state.ocode_data.resarail,label:this.state.resarailDisplayName[this.state.ocode_data.resarail]}}
                              onCreateOption={(o)=>{
                                this.setState({createResarail:o,displayCreateResarailName:true});
                              }}
                              onChange={(o)=>{
                                var prev = false;
                                var resarailDisplayName = {};
                                var tmp = Clone(this.state.ocode_data);
                                if( StrDef(o) ) {
                                  tmp.resarail = o.value;
                                  resarailDisplayName[o.value] = o.label;
                                }
                                else {
                                  tmp.resarail = '';
                                  this.setState({ficheeOption:[]});
                                }
                                if( StrDef(tmp.resarail) ) {  // on charge l'uic et mode de deploiement
                                  var rr = window.synchro.RESARAIL.d(tmp.resarail);
                                  if( rr != null ) {
                                    tmp.deployment = rr.pos_data.deployment;
                                    tmp.uic = rr.pos_data.uic;
                                    if( rr.pos_data.deleted == '2' ) {  // cas d'un resarail prevision
                                      prev = true;
                                    }
                                    // on regarde s'il y a des fiches E
                                    this.FicheeCheck('S-RR-'+rr.pos_id);
                                  }
                                }
                                else {
                                  // on vide la partie fiche E
                                  tmp.lniata = '';
                                  tmp.code_recette = '';
                                  tmp.code_calife = '';
                                  tmp.code_comm_depo = '';
                                  tmp.compta_completed = '';
                                  this.setState({fichee:null,ficheeOptionSelect:-1});
                                }
                                this.setState({prevision:prev,ocode_data:tmp,resarailDisplayName:resarailDisplayName},()=>{
                                  // on appelle Change pour effectuer tous les controles sur les divers champs
                                  // c'est un raccourci pour ne pas intégrer ce code dans change
                                  this.Change('uic',{target:{value:this.state.ocode_data.uic}});
                                });
                              }}
                              formatOptionLabel={(o)=>{
                                if( !o.__isNew__ )
                                  return (
                                    <div><b>{StrDef(o)&&StrDef(o.label)?o.label.split('|#|')[0]:''}</b>
                                    <br/>{StrDef(o)&&StrDef(o.label)?o.label.split('|#|')[1]:''}</div>
                                  );
                                return (
                                  <div>
                                    Pour créér appuyez ici
                                  </div>
                                );
                              }}
                              formatCreateLabel={(t)=>{
                                return null;
                                var found = false;
                                for(var i=0;i<this.state.resarailOption.length;i++) {
                                  if( this.state.resarailOption[i].label.split('|#|')[0].toUpperCase() == t.toUpperCase() ) {
                                    found = true;
                                    break;
                                  }
                                }
                                return (
                                  <div>
                                    Pour {found?'modifier':'créér'} appuyez ici
                                  </div>
                                );
                              }}
                              isValidNewOption={(t,s,o)=>{
                                if( t.length==5 && t.toUpperCase().startsWith('FR') && /^[a-zA-Z]+$/.test(t.substring(2,5)) ){
                                  var found = false;
                                  for(var i=0;i<this.state.resarailOption.length;i++) {
                                    if( this.state.resarailOption[i].label.split('|#|')[0].toUpperCase() == t.toUpperCase() ) {
                                      found = true;
                                      break;
                                    }
                                  }
                                  return !found;
                                }
                                return false;
                              }}
                              onInputChange={this.OnResarailChange}
                              options={this.state.resarailOption}
                            />
                          }
                          { this.state.prevision &&
                            <Button className="btn-link" color="primary" onClick={this.ToggleModalPrevision.bind(this)}>PREVISION : modifier</Button>
                          }


                        </FormGroup>
                        <label>Déploiement</label>
                        <Select
                          className="react-select primary selectBtn"
                          classNamePrefix="react-select"
                          isSearchable={false}
                          isClearable={true}
                          value={{value:this.state.ocode_data.deployment,label:StrDef(this.state.ocode_data.deployment)?i18n.t('item.'+this.state.ocode_data.deployment):''}}
                          onChange={value => {
                            if( !StrDef(value) ) value = {value:''};
                            this.Change('deploy@'+value.value);
                          }}
                          options={[
                            {value:"AGENT",label:i18n.t('item.S-DEPLOY-AGENT')},
                            {value:"PARTNER",label:i18n.t('item.S-DEPLOY-PARTNER')},
                          ]}
                          placeholder="Choisir une région"
                        />
                        <div style={{marginTop:'20px',display:'flex',justifyContent:'space-between'}}>
                          Complet
                          <Switch offColor="success" offText={<i className="nc-icon nc-simple-remove" />}
                                  onColor="success" onText={<i className="nc-icon nc-check-2"/>}
                                  value={this.state.ocode_data.pdv_completed=="1"} onChange={this.Change.bind(this,'pdv_completed')}/>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                }

                { this.state.displayPdvCompta &&
                  <Col md="3" sm="12">
                    <Card>
                      <CardHeader>
                        <div style={{display:'flex'}}>
                          <h6>{i18n.t('item.titleCompta')}</h6>
                          <div className={statusCompta} style={{marginTop:'4px',marginLeft:'6px',width:8,height:8,borderRadius:4}}></div>
                        </div>
                        { this.state.ficheeOption.length > 0 &&
                          <Button className="btn-link" color="primary" onClick={this.ToggleModalFichee.bind(this)}>Fiche E</Button>
                        }
                      </CardHeader>
                      <CardBody>
                        <label>Code LNIATA</label>
                        <FormGroup>
                          <Input disabled={StrDef(this.state.fichee)} type="text" className={StrDef(this.state.error.lniata)?'error':''} value={this.state.ocode_data.lniata} onChange={this.Change.bind(this,'lniata')}/>
                        </FormGroup>
                        <label>Code Recette</label>
                        <FormGroup>
                          <Input disabled={StrDef(this.state.fichee)} type="text" className={StrDef(this.state.error.code_recette)?'error':''} value={this.state.ocode_data.code_recette} onChange={this.Change.bind(this,'code_recette')}/>
                        </FormGroup>
                        <label>Code CALIFE</label>
                        <FormGroup>
                          <Input disabled={StrDef(this.state.fichee)} type="text" className={StrDef(this.state.error.code_calife)?'error':''} value={this.state.ocode_data.code_calife} onChange={this.Change.bind(this,'code_calife')}/>
                        </FormGroup>
                        <label>
                          { StrDef(this.state.ocode_data.deployment) &&
                            'Code '+i18n.t('item.codeCommDepo'+this.state.ocode_data.deployment)
                          }
                          { !StrDef(this.state.ocode_data.deployment) &&
                            i18n.t('item.codeCommDepoEmpty')
                          }
                        </label>
                        <FormGroup>
                          <Input disabled={StrDef(this.state.fichee)} type="text" className={StrDef(this.state.error.code_comm_depo)?'error':''} value={this.state.ocode_data.code_comm_depo} onChange={this.Change.bind(this,'code_comm_depo')}/>
                        </FormGroup>
                        <div style={{marginTop:'20px',display:'flex',justifyContent:'space-between'}}>
                          Complet
                          <Switch offColor="success" offText={<i className="nc-icon nc-simple-remove" />}
                                  onColor="success" onText={<i className="nc-icon nc-check-2"/>}
                                  value={this.state.ocode_data.compta_completed=="1"} onChange={this.Change.bind(this,'compta_completed')}/>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                }
              </Row>

              { isMobile && this.state.displayPdvCompta &&
                <Row>
                  <Col md="6">
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-end',marginBottom:10}}>
                      <Button color="default" style={{margin:0,marginTop:'5px'}} onClick={this.History}>Historique</Button>
                      <Button color="primary" style={{margin:0,marginLeft:5,marginTop:'5px'}} onClick={this.Save}>Enregistrer</Button>
                    </div>
                  </Col>
                </Row>
              }

              <Modal isOpen={this.state.modalPrevision} toggle={this.ToggleModalPrevision} backdrop='static'>
                <Pdv match={{params:{pdv:this.state.ocode_data.resarail}}} embbeded={true} callback={this.ToggleModalPrevision.bind(this)}/>
              </Modal>

              {/********************* MODAL DES FICHES E *****************************/}
              <Modal isOpen={this.state.displayFichee} toggle={this.ToggleModalFichee} backdrop='static'>
                <div className="modal-header justify-content-center">
                  <h5 className="title">{i18n.t('item.ModalFicheeTitle')}</h5>
                  <p>Codes recette disponibles</p>
                  <Icon name="close" color="#000" size={32} style={{position:'absolute',right:'20px',top:'20px',cursor:'pointer'}} onClick={this.ToggleModalFichee}/>
                </div>

                <div className="modal-body">
                  <div className="modalRegion">
                    <Row>
                      <Col md="12">
                        <div style={{display:'flex',flexDirection:'column',height:'calc(100vh - 290px)',overflow:'auto'}}>
                        { this.state.ficheeOption.map((data,i)=>{
                            return (
                              <button key={'region'+i} className={'btnTag'+(data.checked?' selected':'')} style={{width:'calc(100% - 5px)'}} onClick={this.FicheeClick.bind(this,i)}>
                                <div style={{width:'100%',display:'flex',flexDirection:'column',marginTop:5}}>
                                  { false &&
                                    <div style={{display:'flex',flexDirection:'row'}}>
                                      <div style={{display:'flex',width:120}}>
                                        <label>Point de vente : </label>
                                      </div>
                                      <div>
                                        {'('+window.synchro.RESARAIL.r(data.fiche_e_data.resarail)+') '+window.synchro.RESARAIL.n(data.fiche_e_data.resarail)}
                                      </div>
                                    </div>
                                  }
                                  { false &&
                                    <div style={{display:'flex',flexDirection:'row'}}>
                                      <div style={{display:'flex',width:120}}>
                                        <label>Code recette : </label>
                                      </div>
                                      <div>
                                        {data.label}
                                      </div>
                                    </div>
                                  }
                                  {data.label}
                                </div>
                              </button>
                            );
                          })
                        }
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="12">
                        <div style={{display:'flex',justifyContent:StrDef(this.state.fichee)?'space-between':'center'}}>
                          { StrDef(this.state.fichee) && StrDef(this.state.ficheeOption[this.state.ficheeOptionSelect]) &&
                            <Button color="danger" onClick={this.FicheeClick.bind(this,-2)}>{i18n.t('item.ModalFicheeDetacher')+' ('+this.state.ficheeOption[this.state.ficheeOptionSelect].fiche_e_data.code_recette+')'}</Button>
                          }
                          <Button color="primary" onClick={this.FicheeClick.bind(this,-1)}>
                            {i18n.t('all.validate')}
                          </Button>
                        </div>
                      </Col>
                    </Row>

                  </div>
                </div>
              </Modal>

            </>
          }
        </div>
      </>
    );
  }
}

export default Item;
