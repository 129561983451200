import React from "react";
// react plugin used to create charts
import { Line, Bar, Doughnut } from "react-chartjs-2";
import Select from "react-select";

import i18n from "../assets/lib/i18n";
import {StrDef,Phone,Clone} from '../assets/lib/utils';
import {sharedAPI} from "../assets/lib/api";
import Icon from '../components/Icons/Icon.jsx';

// reactstrap components
import {
  Badge,
  ButtonGroup,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  Modal,
  UncontrolledTooltip
} from "reactstrap";


class Location extends React.Component {
  constructor(props) {
    super(props);

    this.RowClick = this.RowClick.bind(this);
    this.Add = this.Add.bind(this);
    this.Save = this.Save.bind(this);
    this.LoadList = this.LoadList.bind(this);
    this.Load = this.Load.bind(this);
    this.ToggleModalDelete = this.ToggleModalDelete.bind(this);

    this.state = {
      list:[],
      mode:'list',

      ocode:'',
      label:'',
      insert:false,

      error:{},
    };

    this.backend = sharedAPI();

    if( StrDef(this.props.match.params.location) ) {
      this.state.mode = 'update';
      if( this.props.match.params.location == 'creation' ) this.state.insert = true;
    }
  }

  componentDidMount() {
    if( StrDef(this.props.match.params.location) ) {
      window.API.ocode_get_one(this.props.match.params.location,this.Load);
    }
    else {
      this.currentRole = sessionStorage.getItem('currentRole');
      this.list = [];
      window.API.ocode_get(this.LocationGet.bind(this),'S-LOCATION|'+this.currentRole+'|S-DE-0');
    }
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }


  LocationGet(res) {
    if( !StrDef(res.error) ) {
      for(var i=0;i<res.length;i++) {
        this.list.push(res[i]);
      }
      if( res.length == 20 ) {
        window.API.ocode_get(this.LocationGet.bind(this),'S-LOCATION|S-RO-'+this.currentRole+'|S-DE-0',res[res.length-1].create_date);
      }
      else {
        this.LoadList(this.list);
      }
    }
  }

  Change(what,t) {
    var data = Clone(this.state.ocode_data);
    data[what] = t.target.value;
    this.setState({ocode_data:data},()=>{});
  }

  ControlForm() {
    var errorCount = 0;
    var error = {};
    this.setState({error:error});
  }

  Load(data) {
    console.log( data );
    this.setState( data );
  }

  LoadList(data) {
    var tmp = [];
    for(var i=0;i<data.length;i++) {
      tmp.push({ocode:data[i].ocode_id,label:data[i].ocode_data.name});
    }
    this.setState({list:tmp});
  }

  RowClick(index) {
    this.props.history.push('/followme/location/'+this.state.list[index].ocode);
  }

  Add() {
    this.props.history.push('/followme/location/creation');
  }

  Save() {
    var data = Clone(this.state);

    //data.ocode_data.gender = 'S-MOMO';

    if( this.state.insert ) {
      console.log( 'insert new emplacmeent !!!!!!!!!!!!' );
    }
    else {
      window.API.ocode_put(data,(r)=>{
        if( StrDef(r.error) ) {
          window.notify(i18n.t('all.recordImpossible'),'danger');
        }
        this.props.history.goBack();
      });
    }
  }

  ToggleModalDelete() {
    this.setState({modalDelete:!this.state.modalDelete});
  }

  CloseModalDelete() {
    this.ToggleModalDelete();
  }

  render() {

    return (
      <>
        <div className="content">
          { this.state.mode == 'list' &&
            <>
              <Row>
                <Col md="6" sm="12">
                  <h2>Emplacements</h2>
                </Col>
                <Col md="6" sm="12" className="text-right">
                  { false &&
                    <div style={{display:'flex',height:'42px',justifyContent:'flex-end',alignItems:'center'}}>
                      <Icon name='stock' size={30} color={'#666'} onClick={this.Add}/>
                    </div>
                  }
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <Card>
                    <CardBody>
                      { this.state.list.length == 0 &&
                        <>
                          <b>{i18n.t('all.noResult')}</b>
                        </>
                      }
                      { this.state.list.length > 0 &&
                        <>
                          <Table responsive striped>
                            <thead className="text-primary">
                              <tr>
                                <th>Libellé</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody style={{maxHeight:'calc(100vh - 315px)'}}>
                              { this.state.list.map((data,i)=>{
                                  return(
                                    <tr key={'tr'+i}>
                                      <td>{data.label}</td>
                                      <td className="text-right" style={{paddingRight:'20px'}}>
                                        <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-end',alignItems:'center'}}>
                                          <Icon name='edit' size={24} style={{display:'flex',marginRight:'5px'}} onClick={this.RowClick.bind(this,i)}/>
                                          { false &&
                                            <UncontrolledTooltip delay={0} target={'edit'+i}>{i18n.t('tooltip.edit')}</UncontrolledTooltip>
                                          }
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })
                              }
                            </tbody>
                          </Table>
                        </>
                      }
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          }

          { this.state.mode == 'update' &&
            <>
              <Row>
                <Col md="6">
                  <h2>{this.state.insert?i18n.t('location.titleAdd'):i18n.t('location.titleUpdate')}</h2>
                </Col>
                <Col md="6" className="text-right">
                  <div style={{display:'flex',flexDirection:'row-reverse'}}>
                    <Button color="primary" style={{margin:0,marginTop:'5px',marginLeft:'10px'}} onClick={this.Save}>Enregistrer</Button>
                    { !this.state.insert && false &&
                      <Button color="danger" className="btn-link"  style={{color:'#A0A0A0',margin:0,marginTop:'5px'}} onClick={this.ToggleModalDelete}>Supprimer</Button>
                    }
                  </div>
                </Col>
              </Row>
              { StrDef(this.state.ocode_data) &&
                <Row style={{marginTop:10}}>
                  <Col md="12">
                    <Card>
                      <CardBody>
                        <label>Libellé</label>
                        <FormGroup>
                          <Input type="text" className={StrDef(this.state.error.name)?'error':''} value={this.state.ocode_data.name} onChange={this.Change.bind(this,'name')}/>
                        </FormGroup>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              }

              <Modal isOpen={this.state.modalDelete} toggle={this.ToggleModalDelete} backdrop='static'>
                <div className="modal-header justify-content-center">
                  <h5>{i18n.t('location.ModalDeleteTitle')+':'}</h5>
                  <span style={{fontSize:'1.2rem',fontWeight:'700'}}>{this.state.label}</span>
                  <Icon name="close" color="#000" size={32} style={{position:'absolute',right:'10px',top:'10px',cursor:'pointer'}} onClick={this.CloseModalDelete.bind(this,false)}/>
                </div>

                <div className="modal-body">
                  <div className="modalRegion">
                    <Row>
                      <Col md="12">

                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <div style={{display:'flex',justifyContent:'space-between'}}>
                          <Button color="info" style={{margin:0,marginTop:'5px',marginLeft:'10px'}} onClick={this.CloseModalDelete.bind(this,false)}>Annuler</Button>
                          <Button color="danger" style={{margin:0,marginTop:'5px'}} onClick={this.CloseModalDelete.bind(this,true)}>Supprimer</Button>
                        </div>
                      </Col>
                    </Row>

                  </div>
                </div>
              </Modal>
            </>
          }

        </div>
      </>
    );
  }
}

export default Location;
