import React from "react";
// react plugin used to create charts
import { Line, Bar, Doughnut } from "react-chartjs-2";
import Switch from "react-bootstrap-switch";
import Select from "react-select";
import i18n from "../assets/lib/i18n";
import {StrDef,Phone,Clone,DateDisplay} from '../assets/lib/utils';
import {sharedAPI} from "../assets/lib/api";
import Icon from '../components/Icons/Icon.jsx';

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  Form,
  UncontrolledTooltip,
  ButtonGroup,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";

class History extends React.Component {
  constructor(props) {
    super(props);

    this.Load = this.Load.bind(this);
    this.LoadHistory = this.LoadHistory.bind(this);
    this.Edit = this.Edit.bind(this);

    this.state = {
      error:{},
      region:[],
      ocode_data:{
        code_calife: "",
        code_comm_depo: "",
        code_recette: "",
        compta_completed: "",
        current_owner: "",
        deployment: "",
        emplacement_id: "",
        gender: "",
        image: "",
        imei: "",
        lniata: "",
        modify_scope: "",
        name: "",
        pdv_completed: "",
        reference: "",
        region: "",
        resarail: "",
        responsable_code_cp: "",
        responsable_completed: "",
        status: "",
        stock: "",
        uic: ""
      },
      pending:true,
      list:[],
    };

    this.backend = sharedAPI();

    window.API.ocode_get_one(this.props.match.params.ocode,this.Load);
    window.API.ocode_get_history(this.props.match.params.ocode,this.LoadHistory);

    window.addEventListener('load', this.Refresh, false);
  }

  componentDidMount() {

  }

  componentWillMount() {
  }

  componentDidUpdate(prevProps) {
   if (this.props.match.params.ocode !== prevProps.match.params.ocode) {
     window.API.ocode_get_one(this.props.match.params.ocode,this.Load);
     window.API.ocode_get_history(this.props.match.params.ocode,this.LoadHistory);
   }
 }

  componentWillUnmount() {
    window.removeEventListener('load', this.Refresh, false);
  }

  Load(data) {
    if( !StrDef(data.ocode_data.deployment) ) data.ocode_data.deployment = 'S-DEPLOY-AGENT';
    console.log( 'loaded : ', data );
    data.pending = false;
    this.setState( data );
  }

  LoadHistory(data) {
    var list = [];
    for(var i=0;i<data.length;i++) {
      if( data[i].ocode_data.gender != 'S-LOCATION' ) {
        list.push(data[i]);
      }
    }
    console.log( 'load history : ', list );
    this.setState({list:list});
  }

  GetStatus(data,header,label,color) {
    var className = i18n.t('statusColor.'+data.ocode_data.status);

    var res = (
      <div style={{display:'flex',flexDirection:'row'}}>
        { StrDef(className) &&
          <div className={className} style={{width:20,height:20,borderRadius:10,marginRight:'10px'}}></div>
        }
        <span style={StrDef(color)?{color:color}:null}>{StrDef(label)?label:i18n.t('status.'+data.ocode_data.status)}</span>
      </div>
    );

    if( header ) {
      res = (
        <div style={{display:'flex',flexDirection:'row',alignItems:'center',height:'47px',marginLeft:'10px'}}>
          { StrDef(className) &&
            <div className={className} style={{width:20,height:20,borderRadius:10,marginRight:'10px'}}></div>
          }
        </div>
      );
    }

    return res;
  }

  Edit() {
    if( this.state.ocode_data.is_completed == '0' ) {
      if( StrDef(this.props.location) && StrDef(this.props.location.state) && this.props.location.state.canGoBack )
        this.props.history.goBack();
      else
        this.props.history.push('/followme/ocode/'+this.state.ocode_id);
    }
    else {
      this.props.history.replace('/followme/ocode/'+this.state.ocode_id);
    }
  }


  render() {
    return (
      <>
        <div className="content">
          { !this.state.pending &&
            <>
              <Row>
                <Col md="8">
                  <div style={{display:'flex',flexDirection:'row'}}>
                    <h2 style={{marginBottom:5}}>Historique des actions</h2>
                  </div>
                  <div style={{display:'flex',flexDirection:'row',marginBottom:30}}>
                    <img src={i18n.t('image.'+this.state.ocode_data.gender)} style={{width:'48px',height:'48px',marginRight:'20px'}}/>
                    <div style={{display:'flex',flexDirection:'row'}}>
                      <div style={{display:'flex',flexDirection:'column'}}>
                        <span style={{lineHeight:'24px'}}>{i18n.t('label.type')+' : '+i18n.t('gender.'+this.state.ocode_data.gender)}</span>
                        <span style={{lineHeight:'24px'}}>{i18n.t('label.'+this.state.ocode_data.gender)+' : '+this.state.ocode_data.imei}</span>
                      </div>
                      <div style={{display:'flex',flexDirection:'column',marginLeft:'30px'}}>
                        <span style={{lineHeight:'24px'}}>{i18n.t('label.region')+' : '+window.lblREG(this.state.ocode_data.region)}</span>
                        { StrDef(this.state.ocode_data.resarail) &&
                          <span style={{lineHeight:'24px'}}>{i18n.t('label.pos')+' : '+this.state.ocode_data.resarail}</span>
                        }
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md="4" className="text-right">
                  { this.state.ocode_data.is_completed != '2' &&
                    <Button color="default" style={{margin:0,marginTop:'5px'}} onClick={this.Edit}>Paramétrage</Button>
                  }
                </Col>
              </Row>

              <Row>
                <Col md="12">
                  <Card>
                    <CardBody>
                      { this.state.list.length > 0 &&
                        <>
                          <Table responsive striped>
                            <thead className="text-primary">
                              <tr>
                                <th width="180px">Date</th>
                                <th>Statut</th>
                                <th>Par</th>
                              </tr>
                            </thead>
                            <tbody style={{maxHeight:'calc(100vh - 345px)'}}>
                              { this.state.list.map((data,i)=>{
                                  if( !StrDef(data.ocode_data) ) return;
                                  var status = this.GetStatus(data,false);
                                  if( i<this.state.list.length
                                      && i > 0
                                      && this.state.list[i-1].ocode_data.status==data.ocode_data.status
                                      && this.state.list[i-1].ocode_data.current_owner==data.ocode_data.current_owner ) {
                                    return null;
                                  }
                                  else {
                                    return(
                                      <tr key={'tr'+i}>
                                        <td width="180px">
                                          {DateDisplay(data.history_date)}
                                        </td>
                                        <td>{status}</td>
                                        <td>
                                          {data.ocode_data.current_owner}
                                        </td>
                                      </tr>
                                    );
                                  }
                                })
                              }
                            </tbody>
                          </Table>
                        </>
                      }
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          }
        </div>
      </>
    );
  }
}

export default History;
