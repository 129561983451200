import React from "react";
// react plugin used to create charts
import { Line, Bar, Doughnut } from "react-chartjs-2";

import i18n from "../assets/lib/i18n";
import {StrDef,Phone,Clone} from '../assets/lib/utils';
import {sharedAPI} from "../assets/lib/api";
import Icon from '../components/Icons/Icon.jsx';
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  Modal,
} from "reactstrap";

import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
var innerHeight = require('ios-inner-height');

const NB_PER_PAGE = 15;
const NB_ROW_API = 20;

class Stock extends React.Component {
  constructor(props) {
    super(props);

    this.RowClick = this.RowClick.bind(this);
    this.FilterChange = this.FilterChange.bind(this);
    this.LoadList = this.LoadList.bind(this);
    this.LoadMore = this.LoadMore.bind(this);
    this.Download = this.Download.bind(this);
    this.Dl = this.Dl.bind(this);
    this.ToggleModalDownload = this.ToggleModalDownload.bind(this);
    this.UpdateWindowDimensions = this.UpdateWindowDimensions.bind(this);

    this.queryEquipmentDefault = 'S-TABLET S-PHONE S-PRINTER S-PED S-COMPUTER S-OTHER';
    this.queryRegionDefault = '';

    this.state = {
      list:[],
      queryRegion:'',
      queryEquipment:this.queryEquipmentDefault,
      queryStatus:'',
      queryDeployment:'',
      queryResarail:'',
      queryTag:'',
      uncompleted:false,
      modalDownload:false,
      winW: window.innerWidth,
      winH: window.innerHeight,
    };

    // on regarde s'il y a une region en parametre
    if( StrDef(this.props.match.params.region) ) {
      this.state.queryRegion = this.props.match.params.region;
    }

    // on regarde si on est un referent et quelle region bloquee
    var role = sessionStorage.getItem('currentRole');
    this.state.currentRole = role;
    if( role == 'REF' ) {
      var userRegion = sessionStorage.getItem('region');
      if( StrDef(userRegion) ) {
        this.state.queryRegion = userRegion;
        this.queryRegionDefault = userRegion;
      }
    }

    // on regarde s'il y a un equipment en parametre
    if( StrDef(this.props.match.params.equipment) ) {
      this.state.queryEquipment = this.props.match.params.equipment;
    }
    // on regarde s'il y a un resarail en parametre
    if( StrDef(this.props.match.params.resarail) ) {
      this.state.queryResarail = this.props.match.params.resarail;
    }
    // on regarde s'il y a un deployment en parametre
    if( StrDef(this.props.match.params.deploy) ) {
      this.state.queryDeployment = this.props.match.params.deploy;
    }

    if( this.props.location.pathname.indexOf('uncompleted') != -1 ) {
      this.state.uncompleted = true;
    }

    this.backend = sharedAPI();

    if( StrDef(window.STATE) && StrDef(window.STATE['stock']) ) {
      this.state = Clone(window.STATE['stock']);
      window.STATE['stock'] = undefined;
    }

    window.API.ocode_get(this.LoadList,this.QueryString());
  }

  componentDidMount() {
    window.addEventListener('equipmentTag', this.FilterChange, false);
    window.addEventListener('statusTag', this.FilterChange, false);
    window.addEventListener('regionTag', this.FilterChange, false);
    window.addEventListener('resarailTag', this.FilterChange, false);
    window.addEventListener('deploymentTag', this.FilterChange, false);
    window.addEventListener('locationTag', this.FilterChange, false);
    window.addEventListener('tagTag', this.FilterChange, false);
    window.addEventListener('event', this.FilterChange, false);
    window.addEventListener('saveState', this.SaveState.bind(this), false);
    window.addEventListener('resize', this.UpdateWindowDimensions);


    if( StrDef(this.props.match.params.region) ) {
      var event = new CustomEvent('changeRegion', { 'detail': this.props.match.params.region });
      setTimeout( ()=>window.dispatchEvent(event), 0 );
    }
    if( StrDef(this.props.match.params.equipment) ) {
      var event2 = new CustomEvent('changeEquipment', { 'detail': this.props.match.params.equipment });
      setTimeout( ()=>window.dispatchEvent(event2), 0 );
    }
    if( StrDef(this.props.match.params.resarail) ) {
      var event3 = new CustomEvent('changeResarail', { 'detail': this.props.match.params.resarail });
      setTimeout( ()=>window.dispatchEvent(event3), 0 );
    }
    if( StrDef(this.props.match.params.deploy) ) {
      var event4 = new CustomEvent('changeDeployment', { 'detail': this.props.match.params.deploy });
      setTimeout( ()=>window.dispatchEvent(event4), 0 );
    }
  }

  componentWillUnmount() {
    window.removeEventListener('equipmentTag', this.FilterChange, false);
    window.removeEventListener('statusTag', this.FilterChange, false);
    window.removeEventListener('regionTag', this.FilterChange, false);
    window.removeEventListener('resarailTag', this.FilterChange, false);
    window.removeEventListener('deploymentTag', this.FilterChange, false);
    window.removeEventListener('locationTag', this.FilterChange, false);
    window.removeEventListener('tagTag', this.FilterChange, false);
    window.removeEventListener('event', this.FilterChange, false);
    window.removeEventListener('saveState', this.SaveState.bind(this), false);
    window.removeEventListener('resize', this.UpdateWindowDimensions);

  }

  componentDidUpdate() {
    if( this.props.location.pathname.indexOf('uncompleted') != -1 ) {
      if( !this.state.uncompleted )
        this.setState({uncompleted:true});
    }
    else {
      if( this.state.uncompleted )
        this.setState({uncompleted:false});
    }
  }

  UpdateWindowDimensions() {
    this.setState({ winW: window.innerWidth, winH: window.innerHeight });
  }

  LoadList(data) {
    var tmp = [];
    if( this.LoadMode == 'append' ) {
      tmp = Clone(this.state.list);
      this.LoadMode = '';
    }
    for(var i=0;i<data.length&&i<NB_PER_PAGE;i++) {
      tmp.push(data[i]);
    }
    console.log( tmp );
    this.setState({list:tmp,hasMore:data.length>NB_PER_PAGE});
  }

  SaveState(data) {
    if( StrDef(data) && data.detail == 'sidebarDetail' ) {
      // on sauve le state pour le recuperer par la suite
      if( !StrDef(window.STATE) ) window.STATE = {};
      window.STATE['stock'] = Clone(this.state);
    }
  }

  RowClick(index) {
    // on sauve le state pour le recuperer par la suite
    if( !StrDef(window.STATE) ) window.STATE = {};
    window.STATE['stock'] = Clone(this.state);
    // on dit a sidebarDetail de sauver son state
    var event = new CustomEvent('saveState', {detail:'sidebarDetail'});
    window.dispatchEvent(event);

    this.props.history.push('/followme/ocode/'+this.state.list[index].ocode_id);
  }

  RowInfo(index) {
    // on sauve le state pour le recuperer par la suite
    if( !StrDef(window.STATE) ) window.STATE = {};
    window.STATE['stock'] = Clone(this.state);
    // on dit a sidebarDetail de sauver son state
    var event = new CustomEvent('saveState', {detail:'sidebarDetail'});
    window.dispatchEvent(event);

    this.props.history.push('/followme/ocode/'+this.state.list[index].ocode_id+'/history');
  }

  QueryString(){
    var query = '';
    if( StrDef(this.state.queryRegion) ) query += this.state.queryRegion;
    if( StrDef(this.state.queryStatus) ) query += (StrDef(query)?'|':'')+this.state.queryStatus;
    if( StrDef(this.state.queryEquipment) ) query += (StrDef(query)?'|':'')+this.state.queryEquipment;
    else query += (StrDef(query)?'|':'')+this.queryEquipmentDefault;
    if( StrDef(this.state.queryDeployment) ) query += (StrDef(query)?'|':'')+this.state.queryDeployment;
    if( StrDef(this.state.queryLocation) ) query += (StrDef(query)?'|':'')+this.state.queryLocation;
    if( StrDef(this.state.queryResarail) ) query += (StrDef(query)?'|':'')+this.state.queryResarail;
    if( StrDef(this.state.queryTag) ) query += (StrDef(query)?'|':'')+this.state.queryTag;

    if( this.state.uncompleted ) query += (StrDef(query)?'|':'')+'S-IC-0 S-IC-1 | S-STATUS-COMPLETED S-STATUS-DELIVERY S-STATUS-BORROW S-STATUS-RECEIVE S-STATUS-RESTORE';

    console.log( 'QueryString = ', query );

    return query;
  };

  FilterChange(data) {
    switch (data.type) {
      case 'equipmentTag':
        var query = '';
        for(var i=0;i<data.detail.length;i++) {
          if( data.detail[i].checked )
            query += (query==''?'':' ')+data.detail[i].key;
        }
        this.setState({queryEquipment:query},()=>{
          window.API.ocode_get(this.LoadList,this.QueryString());
        });
        break;
      case 'regionTag':
        var query = '';
        for(var i=0;i<data.detail.length;i++) {
          if( data.detail[i].checked )
            query += (query==''?'':' ')+data.detail[i].key;
        }
        this.setState({queryRegion:query,queryResarail:''},()=>{
          window.API.ocode_get(this.LoadList,this.QueryString());
        });
        break;
      case 'statusTag':
        var query = '';
        for(var i=0;i<data.detail.length;i++) {
          if( data.detail[i].checked )
            query += (query==''?'':' ')+data.detail[i].key;
        }
        this.setState({queryStatus:query},()=>{
          window.API.ocode_get(this.LoadList,this.QueryString());
        });
        break;
      case 'deploymentTag':
        var query = '';
        for(var i=0;i<data.detail.length;i++) {
          if( data.detail[i].checked )
            query += (query==''?'':' ')+data.detail[i].key;
        }
        this.setState({queryDeployment:query},()=>{
          window.API.ocode_get(this.LoadList,this.QueryString());
        });
        break;
      case 'locationTag':
        var query = '';
        for(var i=0;i<data.detail.length;i++) {
          if( data.detail[i].checked )
            query += (query==''?'':' ')+'S-OE-'+data.detail[i].key;
        }
        this.setState({queryLocation:query},()=>{
          window.API.ocode_get(this.LoadList,this.QueryString());
        });
        break;
      case 'resarailTag':
        this.setState({queryResarail:StrDef(data.detail)?'S-RR-'+data.detail:''},()=>{
          window.API.ocode_get(this.LoadList,this.QueryString());
        });
        break;
      case 'tagTag':
        var query = '';
        for(var i=0;i<data.detail.length;i++) {
          if( data.detail[i].checked )
            query += (query==''?'':' ')+data.detail[i].key;
        }
        this.setState({queryTag:query},()=>{
          window.API.ocode_get(this.LoadList,this.QueryString());
        });
        break;
      case 'event':
        if( data.detail == 'filterCancel' ) {
          this.setState({ queryRegion:this.queryRegionDefault,
                          queryStatus:'',
                          queryEquipment:this.queryEquipmentDefault,
                          queryDeployment:'',
                          queryLocation:'',
                          queryResarail:'',
                          queryTag:''},()=>{
            window.API.ocode_get(this.LoadList,this.QueryString());
          });
        }
        break;

      default:

    }
  }

  GetStatus(data) {
    console.log("data",data.ocode_data.status);
    var className = i18n.t('statusColor.'+data.ocode_data.status);
    console.log("className",className);
    var res = '';

    if( isMobile ) {
      res = (
        <>
          <span className={className} style={{color:'white',fontSize:'0.7em',borderRadius:12,padding:2,paddingLeft:7,paddingRight:7}}>
            {i18n.t('status.'+data.ocode_data.status)}
          </span>
          <div style={{height:5}}></div>
        </>
      );
    }
    else {
      res = (
        <div style={{display:'flex',flexDirection:'row'}}>
          { StrDef(className) &&
            <div className={className} style={{width:20,height:20,borderRadius:10,marginRight:'10px'}}></div>
          }
          {i18n.t('status.'+data.ocode_data.status)}
        </div>
      );
    }

    return res;
  }

  LoadMore() {
    this.LoadMode = 'append';
    window.API.ocode_get( this.LoadList,
                          this.QueryString(),
                          encodeURI(this.state.list[this.state.list.length-1].create_date) );
  }

  Download() {
    this.Dl();
    this.ToggleModalDownload();
  }

  Dl(data) {
    if( StrDef(data) ) {
      var tmp = null;
      var obj = null;
      var ocode_data = {
            code_calife: "",
            code_comm_depo: "",
            code_recette: "",
            compta_completed: "",
            deployment: "",
            gender: "",
            imei: "",
            lniata: "",
            pdv_completed: "",
            reference: "",
            resarail: "",
            responsable_code_cp: "",
            responsable_completed: "",
            status: "",
            uic: "",
            comment:"",
            pin_code:"",
            tag:"",
          };
      for(var i=0;i<data.length;i++) {
        tmp = Clone(data[i].ocode_data);

        console.log( '>>>>>>>>', tmp );

        tmp.gender = tmp.gender.replace('S-','');
        tmp.status = tmp.status.replace('S-STATUS-','');
        tmp.deployment = tmp.deployment.replace('S-DEPLOY-','');
        tmp.region_name = window.lblREG(tmp.region);
        tmp.resarail = window.synchro.RESARAIL.r(tmp.resarail);
        // les tags
        if( StrDef(tmp.tag) ) {
          var tagTmp = [], name = '';
          var tagTab = tmp.tag.split(' ');
          for(var ti=0;ti<tagTab.length;ti++) {
            name = window.synchro.TAG[tagTab[ti]];
            if( StrDef(name) ) tagTmp.push(name);
          }
          tmp.tag = tagTmp.join('-');
        }


        //tmp.region = tmp.region.replace('S-REG','');

        delete(tmp.image);
        delete(tmp.name);
        delete(tmp.modify_scope);
        delete(tmp.stocklabel);
        delete(tmp.current_owner);
        delete(tmp.is_completed);
        delete(tmp.region);
        delete(tmp.emplacement_id);
        tmp.create_date = data[i].create_date;

        this.downloadResult.push({...ocode_data, ...tmp});
      }
      if( data.length < NB_ROW_API || data.length == 0 ) {
        this.DlFinish();
      }
      else {
        window.API.ocode_get( this.Dl,
                              this.QueryString(),
                              encodeURI(this.downloadResult[this.downloadResult.length-1].create_date) );
      }
    }
    else {
      this.downloadResult = [];
      window.API.ocode_get(this.Dl,this.QueryString());
    }
  }

  DlFinish() {
    var SEP = ';';
    var row = null, csv = '';
    for(var i=0;i<this.downloadResult.length;i++) {
      row = this.downloadResult[i];
      if(i==0) {    // -------- premiere ligne : les entetes
        for(var k in row) {
          if( k != 'create_date' )
            csv += k+SEP;
        }
        csv += '\r\n';
      }
      for(var k in row) { // --- toutes les data
        if( k != 'create_date' )
          csv += row[k]+SEP;
      }
      csv += '\r\n';
    }

    var filename = 'export.csv';
    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, filename);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            this.ToggleModalDownload();
            link.click();
            document.body.removeChild(link);
        }
    }
  }

  ToggleModalDownload() {
    this.setState({modalDownload:!this.state.modalDownload});
  }

  render() {

    var subtitle = '';
    if( StrDef(this.state.queryResarail) ) {
      if( window.synchro.RESARAIL.d(this.state.queryResarail.replace('S-RR-','')).pos_data.deleted == '2' ) {
        subtitle = '(PREVISION) ';
      }
      else {
        subtitle = '('+window.synchro.RESARAIL.r(this.state.queryResarail.replace('S-RR-',''))+') ';
      }
      subtitle += window.synchro.RESARAIL.n(this.state.queryResarail.replace('S-RR-',''));
    }

    return (
      <>
        <div className="content">
          <Row>
            <Col md="9" xs="12">
              <h2 style={{marginBottom:this.state.uncompleted?'10px':'20px'}}>{this.state.uncompleted?i18n.t('stock.titleUncompleted'):i18n.t('stock.title')}</h2>
              {this.state.uncompleted?<p>{i18n.t('stock.subtitleUncompleted')}</p>:''}
              {subtitle}
            </Col>
            <Col md="3" xs="0" className="text-right">
              <BrowserView>
                <Button color="primary" style={{paddingLeft:15,paddingRight:15,margin:0}} onClick={this.Download}>
                  <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                    Exporter
                    <Icon name='download' size={24} color={'#FFF'} style={{marginLeft:'10px',display:'flex'}}/>
                  </div>
                </Button>
              </BrowserView>
            </Col>
          </Row>

          <Row style={{marginTop:isMobile?0:20}}>
            <Col md="12">
              { this.state.winW > 991 &&              // BROWSER VERSION
                <Card>
                  <CardBody>
                    { this.state.list.length == 0 &&
                      <>
                        <b>{i18n.t('all.noResult')}</b>
                        <br/>
                        {this.state.uncompleted?i18n.t('stock.uncompletedNoResult'):i18n.t('all.noResultTip')}
                      </>
                    }
                    { this.state.list.length > 0 &&
                      <>
                        <Table responsive striped>
                          <thead className="text-primary">
                            <tr>
                              <th width="70px">Type</th>
                              <th>IMEI - s/n</th>
                              <th>Région</th>
                              <th>PDV</th>
                              <th style={{textAlign:'center'}}>Mode</th>
                              <th>Statut</th>
                              <th style={{textAlign:'right',paddingRight:'26px'}}>Actions</th>
                            </tr>
                          </thead>
                          <tbody style={{maxHeight:'calc(100vh - 345px)'}}>
                            { this.state.list.map((data,i)=>{
                                if( !StrDef(data.ocode_data) ) return;

                                var warning = false;
                                if( data.ocode_data.is_completed<2
                                    && data.ocode_data.status != 'S-STATUS-STOCK'
                                    && data.ocode_data.status != 'S-STATUS-PARAMETER' ) {
                                  warning = true;
                                }

                                var resarail = (StrDef(data.ocode_data.resarail)?data.ocode_data.resarail:'').toUpperCase();
                                resarail = (StrDef(window.synchro.RESARAIL)&&StrDef(window.synchro.RESARAIL.n(resarail)))?<span><div style={{fontSize:'0.7em'}}>{window.synchro.RESARAIL.r(resarail)}</div>{window.synchro.RESARAIL.n(resarail)}</span>:'';

                                return(
                                  <tr key={'tr'+i}>
                                    <td width="70px">
                                      <img src={i18n.t('image.'+data.ocode_data.gender)} className='itemIcon'/>
                                    </td>
                                    <td>{data.ocode_data.imei}</td>
                                    <td style={{textTransform:'capitalize'}}>{window.lblREG(data.ocode_data.region).toLowerCase()}</td>
                                    <td style={{textTransform:resarail.length==5?'capitalize':'uppercase'}}>
                                      {resarail}
                                    </td>
                                    <td style={{textAlign:'center'}}>
                                      <span id={'deploy'+i}>{i18n.t('deployment.'+data.ocode_data.deployment)}</span>
                                      <UncontrolledTooltip delay={0} target={'deploy'+i}>{i18n.t('tooltip.'+data.ocode_data.deployment)}</UncontrolledTooltip>
                                    </td>
                                    <td>
                                      <div id={'warn'+i} style={{display:'flex',flexDirection:'row'}}>
                                        {warning?<Icon name='warning' size={15} color={'#FFB612'} style={{position:'absolute',display:'flex',marginLeft:13,marginTop:-6}}/>:''}
                                        {warning?<UncontrolledTooltip delay={0} target={'warn'+i}>{i18n.t('tooltip.warning')}</UncontrolledTooltip>:''}
                                        {this.GetStatus(data)}
                                      </div>
                                    </td>
                                    <td className="text-right" style={{paddingRight:'20px'}}>
                                      <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-end',alignItems:'center'}}>
                                        <Icon id={'edit'+i} name='edit' size={24} style={{display:'flex',marginRight:'5px'}} onClick={this.RowClick.bind(this,i)}/>
                                        <UncontrolledTooltip delay={0} target={'edit'+i}>{i18n.t('tooltip.edit')}</UncontrolledTooltip>
                                        <Icon id={'history'+i} name='history' size={24} style={{display:'flex',marginTop:'3px'}}  onClick={this.RowInfo.bind(this,i)}/>
                                        <UncontrolledTooltip delay={0} target={'history'+i}>{i18n.t('tooltip.history')}</UncontrolledTooltip>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                            }
                            { this.state.list.length == 1 &&
                              <tr key="tr2">
                                <td style={{height:'40px'}}></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                            }
                          </tbody>
                        </Table>
                        <div style={{textAlign:'right'}}>
                          { this.state.list.length>5 &&
                            this.state.list.length+' lignes '
                          }
                          { this.state.hasMore &&
                            <a className='aLink' onClick={this.LoadMore}>(Afficher plus)</a>
                          }
                        </div>
                      </>
                    }
                  </CardBody>
                </Card>
              }
              { this.state.winW <= 991 &&             // MOBILE VERSION
                <>
                { this.state.list.length == 0 &&
                  <>
                    <b>{i18n.t('all.noResult')}</b>
                    <br/>
                    {this.state.uncompleted?i18n.t('stock.uncompletedNoResult'):i18n.t('all.noResultTip')}
                  </>
                }
                { this.state.list.length > 0 &&
                  <>
                    <Table responsive striped>
                      <tbody style={{maxHeight:'calc(100% - 200px)',lineHeight:'1em'}}>
                        { this.state.list.map((data,i)=>{
                            if( !StrDef(data.ocode_data) ) return;

                            var warning = false;
                            if( data.ocode_data.is_completed<2
                                && data.ocode_data.status != 'S-STATUS-STOCK'
                                && data.ocode_data.status != 'S-STATUS-PARAMETER' ) {
                              warning = true;
                            }

                            var resarail = data.ocode_data.resarail.toUpperCase();
                            resarail = (StrDef(window.synchro.RESARAIL)&&StrDef(window.synchro.RESARAIL[resarail]))?window.synchro.RESARAIL[resarail]:resarail;

                            return(
                              <tr key={'tr'+i} onClick={this.RowClick.bind(this,i)}>
                                <td width="40px">
                                  <img src={i18n.t('image.'+data.ocode_data.gender)} className='itemIcon'/>
                                </td>
                                <td>
                                  <div style={{display:'flex'}}>
                                    <div style={{display:'flex',flex:1,flexDirection:'column'}}>
                                      <span className="listLabel">{i18n.t('label.'+data.ocode_data.gender)}</span>
                                      <span className="listData">{data.ocode_data.imei}</span>
                                    </div>
                                    <div style={{display:'flex',flex:1,flexDirection:'row',justifyContent:'flex-end',alignItems:'center'}}>
                                      {this.GetStatus(data)}
                                    </div>
                                  </div>

                                  <div style={{display:'flex',marginTop:5}}>
                                    <div style={{display:'flex',flex:1,flexDirection:'column'}}>
                                      <span className="listLabel">Région</span>
                                      <span className="listData" style={{textTransform:'capitalize'}}>{window.lblREG(data.ocode_data.region).toLowerCase()}</span>
                                    </div>
                                    <div style={{display:'flex',flex:1,flexDirection:'column'}}>
                                      <span className="listLabel">PDV</span>
                                      <span className="listData" style={{textTransform:resarail.length==5?'capitalize':'uppercase'}}>{resarail}</span>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        }
                        { this.state.list.length == 1 &&
                          <tr key="tr2">
                            <td style={{height:'40px'}}></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        }
                      </tbody>
                    </Table>
                    <div style={{textAlign:'right',marginBottom:30}}>
                      { this.state.list.length>5 &&
                        this.state.list.length+' lignes '
                      }
                      { this.state.hasMore &&
                        <a className='aLink' onClick={this.LoadMore}>(Afficher plus)</a>
                      }
                    </div>
                  </>
                }
                </>
              }
            </Col>
          </Row>

          <Modal isOpen={this.state.modalDownload} toggle={this.ToggleModalDownload} backdrop='static'>
            <div className="modal-header justify-content-center">
              <h5>Chargement en cours...</h5>
              <p>Préparation du fichier CSV</p>
            </div>
          </Modal>
        </div>
      </>
    );
  }
}

export default Stock;
