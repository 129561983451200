import React from "react";
// react plugin used to create charts
import { Line, Bar, Doughnut } from "react-chartjs-2";
import Select from "react-select";

import i18n from "../assets/lib/i18n";
import {StrDef,Phone,Clone,SortBy} from '../assets/lib/utils';
import {sharedAPI} from "../assets/lib/api";
import Icon from '../components/Icons/Icon.jsx';
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import Fuse from 'fuse.js';
// reactstrap components
import {
  Badge,
  ButtonGroup,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  Modal,
  UncontrolledTooltip
} from "reactstrap";
import XLSX from 'xlsx';
var moment = require('moment');

const NB_PER_PAGE = 15;
const POS_PUT_LIMIT = 100;

class Settings extends React.Component {
  constructor(props) {
    super(props);

    this.Save = this.Save.bind(this);

    this.state = {
      password:'',
      newPassword1:'',
      newPassword2:'',
      error:{},
    };

  }

  componentWillMount() {
    window.addEventListener('load', this.Refresh, false);
  }

  componentDidMount() {

  }

  componentWillUnmount() {
    window.removeEventListener('load', this.Refresh, false);
  }

  Change(what,t) {
    var data = Clone(this.state);
    data[what] = t.target.value;

    this.setState(data,()=>{
      this.ControlForm();
    });
  }

  ControlForm() {
    var errorCount = 0;
    var error = {};

    if( ! (StrDef(this.state.password) && this.state.password.length>7
            && StrDef(this.state.newPassword1) && this.state.newPassword1.length>7
            && StrDef(this.state.newPassword2) && this.state.newPassword2.length>7) ) {
      errorCount++;
    }

    if( ! (StrDef(this.state.password) && this.state.password.length>7) ) {
      error['password']=true;
    }
    if( StrDef(this.state.newPassword1) && this.state.newPassword1.length<8 ) {
      error['newPassword1']=true;
    }
    if( StrDef(this.state.newPassword2) && this.state.newPassword2.length<8 ) {
      error['newPassword2']=true;
    }
    if( StrDef(this.state.newPassword1) && this.state.newPassword1.length>7
        && StrDef(this.state.newPassword2) && this.state.newPassword2.length>7
        && this.state.newPassword1!=this.state.newPassword2 ) {
      error['newPassword2']=true;
      error['newPassword1']=true;
    }

    this.setState({error:error});
    return errorCount;
  }

  Save() {
    window.API.change_password_auth(this.state.password,this.state.newPassword1,(r)=>{
      if( StrDef(r.error) ) {
        if( r.error == 'TooManyRequestsException' ) {
          window.notify(i18n.t('settings.errorChangePwdTooMany'),'danger');
        }
        else {
          window.notify(i18n.t('settings.errorChangePwd'),'danger');
        }
      }
      else {
        // on met a jour la date de changement de password
        window.API.user_get((r)=>{
          if( StrDef(r.user_data) ) {
            r.user_data.pass_change = moment().format('YYYY-MM-DD HH:mm:ss');
            window.API.user_put(r.user_data);
          }
        });

        this.setState({changeLogoutCountdown:5},()=>{
          this.ChangePwdSucces();
        });
      }
    });
  }

  ChangePwdSucces() {
    var cd = this.state.changeLogoutCountdown-1;
    if( cd == 0 ) {
      var event = new CustomEvent('logout', {detail:''});
      window.dispatchEvent(event);
    }
    else {
      this.setState({changePwdSucces:true,changeLogoutCountdown:cd});
      setTimeout(()=>this.ChangePwdSucces(),1000);
    }
  }

  render() {

    return (
      <>
        <div className="content">

          { !this.state.changePwdSucces &&
            <>
              <Row>
                <Col md="6" xs="12">
                  <h2>{i18n.t('settings.titleChangePwd')}</h2>
                </Col>
                <Col md="6" xs="12" className="text-right">
                  <div style={{display:'flex',flexDirection:'row-reverse'}}>
                    <Button color="primary" style={{margin:0,marginTop:'5px',marginLeft:'10px'}} onClick={this.Save}>Enregistrer</Button>
                  </div>
                </Col>
              </Row>

              <Row style={{marginTop:10}}>
                <Col md="12">
                  <Card>
                    <CardBody>
                      <label>Mot de passe actuel (8 carac. minimum)</label>
                      <FormGroup>
                        <Input  type="password" className={StrDef(this.state.error.password)?'error':''}
                                value={this.state.password}
                                onChange={this.Change.bind(this,'password')}/>
                      </FormGroup>
                      <label>Nouveau mot de passe (8 carac. minimum)</label>
                      <FormGroup>
                        <Input  type="password" className={StrDef(this.state.error.newPassword1)?'error':''}
                                value={this.state.newPassword1}
                                onChange={this.Change.bind(this,'newPassword1')}/>
                      </FormGroup>
                      <label>Resaisir le nouveau mot de passe (8 carac. minimum)</label>
                      <FormGroup>
                        <Input  type="password" className={StrDef(this.state.error.newPassword2)?'error':''}
                                value={this.state.newPassword2}
                                onChange={this.Change.bind(this,'newPassword2')}/>
                      </FormGroup>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          }

          { this.state.changePwdSucces &&
            <div>
              <h3>Mot de passe changé avec succès</h3>
              Vous allez devoir vous reconnecter dans {this.state.changeLogoutCountdown} seconde{this.state.changeLogoutCountdown>1?'s':''}
            </div>
          }

        </div>
      </>
    );
  }
}

export default Settings;
