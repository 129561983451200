import Dashboard from "views/Dashboard.jsx";
import Stock from "views/Stock.jsx";
import Item from "views/Item.jsx";
import History from "views/History.jsx";
import Location from "views/Location.jsx";
import Pdv from "views/Pdv.jsx";
import FicheE from "views/FicheE.jsx";
import Region from "views/Region.jsx";
import Tag from "views/Tag.jsx";
import Settings from "views/Settings.jsx";
import About from "views/About.jsx";

import i18n from "./assets/lib/i18n";

const routes = [
  {
    path: "/dashboard",
    name: i18n.t('sidebar.home'),
    icon: "dashboard",
    component: Dashboard,
    layout: "/followme",
    role:["ADM","REF"]
  },
  {
    path: "/stock/region/:region/equipment/:equipment/resarail/:resarail/deploy/:deploy",
    component: Stock,
    layout: "/followme",
    hidden:true,
    role:["ADM","REF"]
  },
  {
    path: "/stock/region/:region/equipment/:equipment/deploy/:deploy",
    component: Stock,
    layout: "/followme",
    hidden:true,
    role:["ADM","REF"]
  },
  {
    path: "/stock/region/:region/deploy/:deploy",
    component: Stock,
    layout: "/followme",
    hidden:true,
    role:["ADM","REF"]
  },
  {
    path: "/stock/region/:region/equipment/:equipment/resarail/:resarail",
    component: Stock,
    layout: "/followme",
    hidden:true,
    role:["ADM","REF"]
  },
  {
    path: "/stock/region/:region/resarail/:resarail",
    component: Stock,
    layout: "/followme",
    hidden:true,
    role:["ADM","REF"]
  },
  {
    path: "/stock/region/:region/equipment/:equipment",
    component: Stock,
    layout: "/followme",
    hidden:true,
    role:["ADM","REF"]
  },
  {
    path: "/stock/equipment/:equipment",
    component: Stock,
    layout: "/followme",
    hidden:true,
    role:["ADM","REF"]
  },
  {
    path: "/stock/region/:region",
    component: Stock,
    layout: "/followme",
    hidden:true,
    role:["ADM","REF"]
  },
  {
    path: "/stock/uncompleted",
    component: Stock,
    layout: "/followme",
    hidden:true,
    role:["ADM","REF"]
  },
  {
    path: "/stock",
    name: i18n.t('sidebar.stock'),
    icon: "stock",
    component: Stock,
    layout: "/followme",
    role:["ADM","REF"]
  },
  {
    path: "/ocode/:ocode/history",
    component: History,
    layout: "/followme",
    hidden:true,
    role:["ADM","REF"]
  },
  {
    path: "/ocode/:ocode",
    component: Item,
    layout: "/followme",
    hidden:true,
    role:["ADM","REF"]
  },
  {
    path: "/imei/:imei",
    component: Item,
    layout: "/followme",
    hidden:true,
    role:["ADM","REF"]
  },
  {
    path: "/location/:location",
    component: Location,
    layout: "/followme",
    hidden:true,
    role:["ADM","REF"]
  },
  {
    path: "/location",
    name: i18n.t('sidebar.location'),
    icon: "location",
    component: Location,
    layout: "/followme",
    role:["ADM","REF"]
  },
  {
    path: "/pdv/:pdv",
    component: Pdv,
    layout: "/followme",
    hidden:true,
    role:["ADM","REF"]
  },
  {
    path: "/pdv",
    name: i18n.t('sidebar.pdv'),
    icon: "pos",
    component: Pdv,
    layout: "/followme",
    role:["ADM","REF"]
  },
  {
    path: "/fichee/:fichee",
    component: FicheE,
    layout: "/followme",
    hidden:true,
    role:["ADM","REF"]
  },
  {
    path: "/fichee",
    name: i18n.t('sidebar.fichee'),
    icon: "fichee",
    component: FicheE,
    layout: "/followme",
    role:["ADM","REF"]
  },
  {
    path: "/region/:region",
    component: Region,
    layout: "/followme",
    hidden:true,
    role:["ADM"]
  },
  {
    path: "/region",
    name: i18n.t('sidebar.region'),
    icon: "region",
    component: Region,
    layout: "/followme",
    role:["ADM"]
  },
  {
    path: "/tag/:tag",
    component: Tag,
    layout: "/followme",
    hidden:true,
    role:["ADM","REF"]
  },
  {
    path: "/tag",
    name: i18n.t('sidebar.tag'),
    icon: "tag",
    component: Tag,
    layout: "/followme",
    role:["ADM","REF"]
  },
  {
    path: "/settings/pwd",
    component: Settings,
    layout: "/followme",
    hidden:true,
    role:["ADM","REF"]
  },
  {
    path: "/about",
    component: About,
    layout: "/followme",
    hidden:true,
    role:["ADM","REF"]
  },
];

export default routes;
