import React from "react";
// react plugin used to create charts
import { Line, Bar, Doughnut } from "react-chartjs-2";
import Select from "react-select";

import i18n from "../assets/lib/i18n";
import {StrDef,Phone,Clone,SortBy} from '../assets/lib/utils';
import {sharedAPI} from "../assets/lib/api";
import Icon from '../components/Icons/Icon.jsx';
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import Fuse from 'fuse.js';

// reactstrap components
import {
  Badge,
  ButtonGroup,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  Modal,
  UncontrolledTooltip
} from "reactstrap";

import XLSX from 'xlsx';

const NB_PER_PAGE = 15;
const POS_PUT_LIMIT = 100;

class Tag extends React.Component {
  constructor(props) {
    super(props);

    this.RowClick = this.RowClick.bind(this);
    this.Refresh = this.Refresh.bind(this);
    this.Add = this.Add.bind(this);
    this.Save = this.Save.bind(this);
    this.ToggleModalDelete = this.ToggleModalDelete.bind(this);
    this.UpdateWindowDimensions = this.UpdateWindowDimensions.bind(this);

    this.state = {
      list:[],
      mode:'list',
      tag_id:'',
      tag_name:'',
      insert:false,     // mode add
      error:{},
      winW: window.innerWidth,
      winH: window.innerHeight,
    };

    this.backend = sharedAPI();

    window.addEventListener('load', this.Refresh, false);

    if( StrDef(this.props.match.params.tag) ) {
      this.state.mode = 'update';
      if( this.props.match.params.tag == 'creation' ) this.state.insert = true;
    }
  }

  componentWillMount() {
    var count = 0;
    for(var t in window.synchro.TAG) count++;
    if( count > 0 ) this.Refresh({detail:'tag'});
  }

  componentDidMount() {
    window.addEventListener('resize', this.UpdateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('load', this.Refresh, false);
    window.removeEventListener('resize', this.UpdateWindowDimensions);
  }

  UpdateWindowDimensions() {
    this.setState({ winW: window.innerWidth, winH: window.innerHeight });
  }

  Refresh(data) {
    if( data.detail == 'tag' ) {
      var tab = [], label='';
      for(var k in window.synchro.TAG) {
        tab.push({value:k,label:window.synchro.TAG[k]});
      }
      tab = SortBy(tab,'label');

      var obj = {};
      obj.list = tab;

      if( StrDef(this.props.match.params.tag) && this.props.match.params.tag != 'creation' ) {
        obj.tag_id = this.props.match.params.tag;
        obj.tag_name = window.synchro.TAG[obj.tag_id];
      }

      this.setState(obj);
    }
  }

  Change(what,t) {
    var data = Clone(this.state);
    data[what] = t.target.value;

    var found = false;
    for(var k in window.synchro.TAG) {
      if( k!='loaded' && window.synchro.TAG[k].toUpperCase() == t.target.value.toUpperCase() ) {
        found = true;
      }
    }
    if( found ) {
      window.notify(i18n.t('tag.alreadyExists').replace('###',t.target.value),'danger')
    }

    this.setState(data,()=>{
      this.ControlForm();
    });
  }

  ControlForm() {
    var errorCount = 0;
    var error = {};

    if( !StrDef(this.state.tag_name) ) {
      error['name']=true;
      errorCount++;
    }

    this.setState({error:error});
    return errorCount;
  }

  RowClick(index) {
    console.log( index, this.state.list[index].value );
    this.props.history.push('/followme/tag/'+this.state.list[index].value);
  }

  Add() {
    this.props.history.push('/followme/tag/creation');
  }

  Save() {

    var error = this.ControlForm();
    if( error > 0 ) {
      window.notify(i18n.t('tag.errorUpdate'),'danger');
      return;
    }

    var found = false;
    for(var k in window.synchro.TAG) {

      if( window.synchro.TAG[k].toUpperCase() == this.state.tag_name.toUpperCase() ) {
        found = true;
      }
    }
    if( found ) {
      if( this.state.insert )
        window.notify(i18n.t('tag.alreadyExists').replace('###',this.state.tag_name),'danger')
      return;
    }

    var data = {
      tag_id:this.state.tag_id,
      tag_name:this.state.tag_name,
    };

    var callback = (r) => {
      if( StrDef(r.error) ) {
        window.notify(i18n.t('all.recordImpossible'),'danger');
      }
      else {
        window.synchro.TAG[StrDef(r.tag_code)?r.tag_code:data.tag_id] = data.tag_name;
        this.props.history.goBack();
      }
    };

    if( this.state.insert ) {
      window.API.tag_post(data,callback);
    }
    else {
      window.API.tag_put(data,callback);
    }

  }

  ToggleModalDelete() {
    if( !this.state.modalDelete ) {   // on ouvre la modal
      window.API.ocode_get((res)=>{
        if( StrDef(res) && !StrDef(res.error) && res.length == 0 ) {
          this.setState({pending:false,deletePossible:true});
        }
        else {
          this.setState({pending:false,deletePossible:false});
        }
      },this.state.tag_id);
    }
    this.setState({pending:true,modalDelete:!this.state.modalDelete});
  }

  CloseModalDelete(bool) {
    this.setState({deletePossible:false});
    this.ToggleModalDelete();
    if( bool ) {
      var data = {
        tag_id:this.state.tag_id,
      };
      window.API.tag_del(data,(r)=>{
        if( StrDef(r.error) ) {
          window.notify(i18n.t('all.recordImpossible'),'danger');
        }
        else {
          delete(window.synchro.TAG[data.tag_id]);
        }
        this.props.history.goBack();
      });
    }
    else {
      this.props.history.goBack();
    }
  }

  render() {

    return (
      <>
        <div className="content">
          { this.state.mode == 'list' &&
            <>
              <Row>
                <Col md="6" xs="6">
                  <h2>{i18n.t('tag.title')}</h2>
                </Col>
                <Col md="6" xs="6" className="text-right">
                  <div style={{display:'flex',height:'42px',justifyContent:'flex-end',alignItems:'center'}}>
                    <Button color="primary" style={{paddingLeft:15,paddingRight:15,margin:0,marginTop:'25px'}} onClick={this.Add}>
                      <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                        Ajouter
                        <Icon name='add' size={24} color={'#FFF'} style={{marginLeft:'10px',display:'flex'}}/>
                      </div>
                    </Button>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="12">
                  <Card>
                    <CardBody>
                      { this.state.list.length == 0 &&
                        <>
                          <b>{i18n.t('all.noResult')}</b>
                        </>
                      }
                      { this.state.list.length > 0 &&
                        <>
                          <Table responsive striped>
                            <thead className="text-primary">
                              <tr>
                                <th>Libellé</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody style={{maxHeight:'calc(100vh - 315px)'}}>
                              { this.state.list.map((data,i)=>{
                                  return(
                                    <tr key={'tr'+i}>
                                      <td width="220px">
                                        {data.label}
                                      </td>
                                      <td className="text-right" style={{paddingRight:'20px'}}>
                                        <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-end',alignItems:'center'}}>
                                          <Icon name='edit' size={24} style={{display:'flex',marginRight:'5px'}} onClick={this.RowClick.bind(this,i)}/>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })
                              }
                            </tbody>
                          </Table>
                        </>
                      }
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          }
          { this.state.mode == 'update' &&
            <>
              <Row>
                <Col md="8" xs="12">
                  <h2>{this.state.insert?i18n.t('tag.titleAdd'):i18n.t('tag.titleUpdate')}</h2>
                </Col>
                <Col md="4" className="text-right">
                  <div style={{display:'flex',flexDirection:'row-reverse'}}>
                    <Button color="primary" style={{margin:0,marginTop:'5px',marginLeft:'10px'}} onClick={this.Save}>Enregistrer</Button>
                    { !this.state.insert &&
                      <Button color="danger" className="btn-link"  style={{color:'#A0A0A0',margin:0,marginTop:'5px'}} onClick={this.ToggleModalDelete}>Supprimer</Button>
                    }
                  </div>
                </Col>
              </Row>

              <Row style={{marginTop:10}}>
                <Col md="12">
                  <Card>
                    <CardBody>
                      <label>Libellé</label>
                      <FormGroup>
                        <Input  type="text" className={StrDef(this.state.error.name)?'error':''}
                                value={this.state.tag_name}
                                onChange={this.Change.bind(this,'tag_name')}/>
                      </FormGroup>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Modal isOpen={this.state.modalDelete} toggle={this.ToggleModalDelete} backdrop='static'>
                <div className="modal-header justify-content-center">
                  <br/>
                  { !this.state.pending && this.state.deletePossible &&
                    <>
                      <h5>{i18n.t('tag.ModalDeleteTitle')+' :'}</h5>
                      <div style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                        {this.state.tag_name}
                      </div>
                    </>
                  }
                  { !this.state.pending && !this.state.deletePossible &&
                    <>
                      <h5>{i18n.t('tag.ModalDeleteImpossibleTitle')+' :'}</h5>
                      <div style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                        {this.state.tag_name}
                      </div>
                      <br/>
                      <h5>{i18n.t('region.ModalDeleteImpossibleBody')}</h5>
                    </>
                  }
                  <Icon name="close" color="#000" size={32} style={{position:'absolute',right:'10px',top:'10px',cursor:'pointer'}} onClick={this.CloseModalDelete.bind(this,false)}/>
                </div>

                <div className="modal-body">
                  <div className="modalRegion">
                    <Row>
                      <Col md="12">
                        { this.state.pending &&
                          <p>{i18n.t('region.pending')}</p>
                        }
                      </Col>
                    </Row>
                    { !this.state.pending &&
                      <Row>
                        <Col md="12">
                          <div style={{display:'flex',justifyContent:this.state.deletePossible?'space-between':'center'}}>
                            <Button color="info" style={{margin:0,marginTop:'5px',marginLeft:'10px'}} onClick={this.CloseModalDelete.bind(this,false)}>{this.state.deletePossible?i18n.t('all.cancel'):i18n.t('all.ok')}</Button>
                            { this.state.deletePossible &&
                              <Button color="danger" style={{margin:0,marginTop:'5px'}} onClick={this.CloseModalDelete.bind(this,true)}>Supprimer</Button>
                            }
                          </div>
                        </Col>
                      </Row>
                    }

                  </div>
                </div>
              </Modal>
            </>
          }
        </div>
      </>
    );
  }
}

export default Tag;
