import React from "react";
import { NavLink } from "react-router-dom";
import { Nav, Collapse } from "reactstrap";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

import logo from "assets/img/logo.png";
import logoFollowme from "assets/img/followme.png";
import Icon from '../Icons/Icon.jsx';
import i18n from "assets/lib/i18n";
import {HasPermission, StrDef} from "assets/lib/utils";
import SidebarDetail from "./SidebarDetail.jsx";
import packageJson from '../../../package.json';

var ps;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.getCollapseStates(props.routes);

    this.NavLinkClick = this.NavLinkClick.bind(this);
    this.GoHome = this.GoHome.bind(this);


  }
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = routes => {
    return null;
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    return false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.pathname.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = routes => {
    var gender = sessionStorage.getItem('gender');
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !this.state[prop.state];
        return (
          <li
            className={this.getCollapseInitialState(prop.views) ? "active" : ""}
            key={key}
          >
            <a
              href="#pablo"
              data-toggle="collapse"
              aria-expanded={this.state[prop.state]}
              onClick={e => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} />
                  <p>
                    {prop.name}
                    <b className="caret" />
                  </p>
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span className="sidebar-normal">
                    {prop.name}
                    <b className="caret" />
                  </span>
                </>
              )}
            </a>
            <Collapse isOpen={this.state[prop.state]}>
              <ul className="nav">{this.createLinks(prop.views)}</ul>
            </Collapse>
          </li>
        );
      }

      if( prop.hidden === true ) return null;

      if( StrDef(prop.role) ) {
        if( !HasPermission('currentRole',prop.role) ) return null;
      }

      return (
        <li className={this.activeRoute(prop.layout + prop.path)} key={key}>
          <NavLink to={prop.layout + prop.path} activeClassName="" onClick={this.NavLinkClick}>
            {prop.icon !== undefined ? (
              <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                <Icon name={prop.icon} size={30} color={'#FFF'}/>
                <p style={{marginLeft:'20px'}}>{prop.name}</p>
                <div style={{display:'flex',flex:1,justifyContent:'flex-end',alignItems:'center'}}>
                  <Icon name='forward' size={24} color={'#FFF'}/>
                </div>
              </div>
            ) : (
              <>
                <span className="sidebar-mini-icon">{prop.mini}</span>
                <span className="sidebar-normal">{prop.name}</span>
              </>
            )}
          </NavLink>
          { (this.props.location.pathname.indexOf(prop.layout+prop.path)>-1 && prop.path=='/stock') &&
            <SidebarDetail history={this.props.history} mode="stock"/>
          }
          { (this.props.location.pathname.indexOf(prop.layout+prop.path)>-1 && prop.path=='/pdv') &&
            <SidebarDetail history={this.props.history} mode="pdv"/>
          }
          { (this.props.location.pathname.indexOf(prop.layout+prop.path)>-1 && prop.path=='/fichee') &&
            <SidebarDetail history={this.props.history} mode="fichee"/>
          }
        </li>
      );
    });
  };
  // verifies if routeName is the one active (in browser input)
  activeRoute = routeName => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  componentDidMount() {
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    // if (navigator.platform.indexOf("Win") > -1) {
    //   ps = new PerfectScrollbar(this.refs.sidebar, {
    //     suppressScrollX: true,
    //     suppressScrollY: false
    //   });
    // }
  }
  componentWillUnmount() {
    // we need to destroy the false scrollbar when we navigate
    // to a page that doesn't have this component rendered
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }

  NavLinkClick() {
    document.documentElement.classList.toggle("nav-open");
  }

  GoHome() {
    this.props.history.push('/followme/dashboard');
  }

  render() {
    return (
      <div
        className="sidebar"
        data-color={this.props.bgColor}
        data-active-color={this.props.activeColor}
      >


        <div style={{display:'flex',height:'100px',flexDirection:'column',position:'relative',zIndex:4,justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={this.GoHome}>
          <img src={logo} style={{width:'100px',objectFit:'contain'}}/>
          <div style={{color:'white',fontWeight:600}}>
          FOLLOW ME
          </div>
        </div>


        <div className="sidebar-wrapper" ref="sidebar">
          <Nav>{this.createLinks(this.props.routes)}</Nav>
        </div>

      </div>
    );
  }
}

export default Sidebar;
